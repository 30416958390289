import React, {useMemo} from "react";
import SkeletonImage from "../SkeletonImage";
import { Caption, Container } from "./shared";
import styled from "styled-components";
import border from "../../assets/internal/border.svg";
import responsive, { breakpoint } from "../../utils/responsive";
import { useMediaQuery } from "react-responsive/src";
import { defaultTo } from "lodash";
import ReactHtmlParser from "react-html-parser";
import { htmlContentStyles } from "../typography";
import BookmarkLink from "../navigation/BookmarkLink";
import useFocusElement from "../../utils/useFocusElement";

export const Frame = styled.div`
  border: 5px solid transparent;
  border-image: url(${border}) 1;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  ${responsive.lg.andSmaller`
    border: none;    
  `}
`;

const Span = styled.span``;

const HtmlCaption = styled(Caption)`
  ${htmlContentStyles}
`;


export default ({
  width,
  height,
  image,
  title,
  linkProvider,
  locked,
  id,
  slug,
  children,
}) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  const Wrap = useMemo(() => (linkProvider && !locked ? BookmarkLink : Span), [
    linkProvider,
    locked
  ]);

  const ref = useFocusElement(slug);

  return (
    <Wrap
      id={slug}
      to={linkProvider ? linkProvider(slug || id) : "/"}
      bookmark={slug || id}
      style={{ margin: "0 auto", zIndex: 1 }}
    >
      <Container
        width={defaultTo(width, isMobile ? "6.5rem" : "15.1875rem")}
        frame
        style={{ padding: 0 }}
        ref={ref}
      >
        <div style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)" }}>
          <SkeletonImage
            src={image}
            alt={title}
            height={defaultTo(height, isMobile ? "9.1875rem" : "21.375rem")}
          />
          {children}
        </div>
        <HtmlCaption style={{ marginTop: "1em" }}>
          {ReactHtmlParser(title)}
        </HtmlCaption>
      </Container>
    </Wrap>
  );
};
