import React from "react";
import styled from "styled-components";
import { H3, Label, Paragraph } from "../components/typography";
import Paper from "../components/containers/Paper";
import SkeletonImage from "../components/SkeletonImage";
import { theme } from "../theme";
import { LanguageContext } from "../components/LanguageContext";
import { LinkButton } from "../components/LinkButton";
import { Location } from "@reach/router";
import { FacebookIcon } from "../components/Icon";
import responsive from "../utils/responsive";
import Gallery from "../components/containers/Gallery";
// import HelmetProvider from "../components/HelmetProvider";

const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.text.default.string()};
  margin-bottom: 3em;
  
`;

const Container = styled.div`
  margin: 5rem 0;

  h3,
  p {
    color: ${props => props.theme.text.emphasized.string()};
    text-transform: unset;
    white-space: pre-line;
  }

  ${responsive.sm.andSmaller`
    margin: 1rem 0;
  `}
`;

const PromoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 4em 0;
`;

const Action = styled.div`
  position: absolute;
  bottom: 20%;
  margin: auto;
  ${responsive.md.andSmaller`
    bottom: 50%;
    transform: translateY(50%);
    > a {
      font-size: 0.75rem;
    }
  `};
`;

const Promo = ({ image, link }) => (
  <PromoWrapper>
    <SkeletonImage src={image} alt={"watch trailer"} />
    {link && (
      <Action>
        <LanguageContext.Consumer>
          {({ contentService }) => (
            <LinkButton
              flavor={"default"}
              href={link}
              lg
              target="_blank"
              rel="noopener"
            >
              {contentService.translate("actions.watch_trailer")}
            </LinkButton>
          )}
        </LanguageContext.Consumer>
      </Action>
    )}
  </PromoWrapper>
);

const ShareContainer = styled.div`
  margin-top: 2em;
  padding: 1em 0;
  border-top: 1px solid ${props => props.theme.text.default.string()};
  * {
    fill: ${props => props.theme.text.default.string()};
  }
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  ${responsive.sm.andSmaller`
    margin-top: 0;
  `}

  label {
    margin-right: 1em;
    ${responsive.sm.andSmaller`
      font-size: 0.8em;
    `}
  }
  > div {
    > * {
      padding: 0.4em;
      ${responsive.sm.andSmaller`
          padding: 0;
        `}
      > svg {
        height: 1.5em;
        ${responsive.sm.andSmaller`
          height: 1em;
        `}
      }
    }
  }
`;

const makeFacebookUrl = path =>
  `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(path)}//`;

const Share = ({ text }) => (
  <Location>
    {({ location }) => (
      <ShareContainer>
        <LanguageContext.Consumer>
          {({ contentService }) => (
            <Label uppercase size={"l"}>
              {contentService.translate("actions.share")}
            </Label>
          )}
        </LanguageContext.Consumer>
        <div>
          <FacebookIcon href={makeFacebookUrl(location.href)} />
        </div>
      </ShareContainer>
    )}
  </Location>
);

export default ({
  date,
  syllabus,
  title,
  text1,
  text2,
  text3,
  images,
  cta_image,
  cta_link,
  text_author,
  q1,q2,q3,q4,q5,q6,q7,q8,q9,q10,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10
}) => (
  <Container>
    <Paper>
      <Header>
        <H3>{title}</H3>
        <Label uppercase>{date}</Label>
      </Header>
      <Label style={{ textAlign: "right", marginTop: "-2em", color: "rgb(199, 163, 102)" }} uppercase>{text_author}</Label>

      {text1 && <Paragraph size={"l"} style={{ fontStyle: "italic" }}>{text1}</Paragraph>}
      
      {q1 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q1}</Paragraph>}
      {a1 && <Paragraph size={"l"} >{a1}</Paragraph>}
      {q2 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q2}</Paragraph>}
      {a2 && <Paragraph size={"l"} >{a2}</Paragraph>}
      {q3 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q3}</Paragraph>}
      {a3 && <Paragraph size={"l"} >{a3}</Paragraph>}
      {q4 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q4}</Paragraph>}
      {a4 && <Paragraph size={"l"} >{a4}</Paragraph>}
      {q5 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q5}</Paragraph>}
      {a5 && <Paragraph size={"l"} >{a5}</Paragraph>}
      {q6 && <Paragraph 
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q6}</Paragraph>}
      {a6 && <Paragraph size={"l"} >{a6}</Paragraph>}
      
      <Gallery items={images.map(image => ({ image }))} />

      {q7 && <Paragraph
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q7}</Paragraph>}
      {a7 && <Paragraph size={"l"} >{a7}</Paragraph>}
      {q8 && <Paragraph
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q8}</Paragraph>}
      {a8 && <Paragraph size={"l"} >{a8}</Paragraph>}

      {q9 && <Paragraph
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q9}</Paragraph>}
      {a9 && <Paragraph size={"l"} >{a9}</Paragraph>}
      {q10 && <Paragraph
          size={"l"}
          style={{ color: "rgb(199, 163, 102)", fontWeight: "bold", marginLeft: 0 }}
      >{q10}</Paragraph>}
      {a10 && <Paragraph size={"l"} >{a10}</Paragraph>}

      {text2 && (
        <Paragraph
          size={"l"}
          style={{ color: theme.colors.primary.string(), marginTop: "2em" }}
        >
          {text2}
        </Paragraph>
      )}
      {text3 && <Paragraph size={"l"}>{text3}</Paragraph>}

      {cta_image && <Promo image={cta_image} link={cta_link} />}
      <Share text={syllabus} />
    </Paper>
  </Container>
);
