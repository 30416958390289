import React from "react";
import styled, { css } from "styled-components";
import responsive from "../utils/responsive";
import { colors } from "../theme";

const H1 = styled.h1`
  font-size: 5rem;
  margin-top: 0.875em;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};
  text-transform: uppercase;

  ${responsive.xl.andSmaller`
    font-size: 3rem;
    line-height: 3rem;
  `}

  ${responsive.xs.andSmaller`
    font-size: 1.3rem;
    line-height: 2rem;
  `}
`;

const H2 = styled.h2`
  font-size: 2.5rem;
  margin-top: 0.875em;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};
  ${responsive.xs.andSmaller`
    font-size: ${props => props.theme.textSize.ml};
  `}
  text-transform: uppercase;
  text-align: center;
`;

const H3 = styled.h3`
  font-size: 1.75rem;
  margin-top: 0.875em;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};
  text-transform: uppercase;

  ${responsive.md.andSmaller`
    font-size: ${props => props.theme.textSize.xl};
  `}

  ${responsive.xs.andSmaller`
    font-size: ${props => props.theme.textSize.m};
  `}
`;

const H4 = styled.h4`
  font-size: 1.5rem;
  margin-top: 0.875em 0;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};

  ${responsive.md.andSmaller`
    font-size: ${props => props.theme.textSize.md};
  `}

  ${responsive.sm.andSmaller`
    font-size: ${props => props.theme.textSize.s};
  `}
  text-transform: uppercase;
`;

const H5 = styled.h5`
  font-size: 1.25rem;
  margin-top: 0.875em;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};

  ${responsive.sm.andSmaller`
    font-size: ${props => props.theme.textSize.s};
  `}
  text-transform: uppercase;
`;

const H6 = styled.h6`
  font-size: 1rem;
  margin-top: 0.875em;
  margin-bottom: 0.875em;
  color: ${props => props.theme.text.default.string()};
  text-transform: uppercase;
`;

const Text = styled.span`
  font-size: ${props =>
    props.theme.textSize[props.size || "m"] || props.theme.textSize.m};
  white-space: nowrap;

  ${responsive.md.andSmaller`
    white-space: initial;
  `};

  color: ${props => props.theme.text.default.string()};
`;

const Truncate = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TruncatedText = ({ children, style }) => (
  <Truncate style={style}>
    <Text>{children}</Text>
  </Truncate>
);

const Italic = styled.span`
  text-align: center;
  font-style: italic;
  opacity: 0.8;
`;

const Paragraph = styled.p`
  font-size: ${props =>
    props.theme.textSize[props.size || "m"] || props.theme.textSize.m};
  line-height: ${props =>
    props.theme.lineHeight[props.size || "m"] || props.theme.lineHeight.m};
  margin: ${props =>
      props.theme.lineHeight[props.size || "m"] || props.theme.lineHeight.m}
    auto;
  color: ${props => props.theme.text.default.string()};
  text-align: justify;

  ${responsive.sm.andSmaller`
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  `};

  ${responsive.xs.andSmaller`
    font-size: ${props => props.theme.textSize.xs};
    line-height: ${props => props.theme.lineHeight.xs};
  `}
`;

const Label = styled.label`
  font-size: ${props =>
    props.theme.textSize[props.size || "m"] || props.theme.textSize.m};
  white-space: normal;

  color: ${props => props.theme.text.default.string()};

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${responsive.xs.andSmaller`
    font-size: ${props => props.theme.textSize.xs};
    line-height: ${props => props.theme.lineHeight.s};
  `}
`;

const B = styled.b`
  font-weight: 500;
`;

export const htmlContentStyles = `
  i {
    font-style: italic;
  }

  b {
    font-weight: 600;
  }

  u {
    text-decoration: underline;
  }
  
  a {
    color: ${colors.vibrant.string()};
    :hover,
    :active {
      color: ${colors.vibrant.darken(0.15).string()};
    }
    :focus {
      color: ${colors.vibrant.darken(0.1).string()};
    }
    transition: all 0.1 ease;
  }
`;

const EllipsisParagraph = styled(Paragraph)`
  /* styles for '...' */
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  max-height: ${props => (props.lines || 2) * 1.2}em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  /* create the ... */
  :before {
    /* points in the end */
    content: "...";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  :after {
    /* points in the end */
    content: "";
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: ${props => props.theme.background.default.string()};
  }

  ${htmlContentStyles}
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Text,
  TruncatedText,
  Paragraph,
  Italic,
  Label,
  B,
  EllipsisParagraph
};
