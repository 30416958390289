import React from "react";
import styled from "styled-components";
import responsive from "../utils/responsive";

const Ribbon = styled.div`
  z-index: 1000;
  position: fixed;
  left: -4.5rem;
  top: 50%;
  text-transform: uppercase;
  user-select: none;
  transform: rotateZ(-90deg);
  color: ${props => props.theme.text.default.alpha(0.6).string()};
  font-size: 0.875rem;
  font-weight: 900;
  :before,
  :after {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 0.5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid;
  }

  :after {
    margin: 0 -55% 0 0.5em;
  }

  ${responsive.sm.andSmaller`
    right: -5.5em;
  `};
`;

export default ({ text }) => (
  <Ribbon>{text || "CENTAR FILM SINCE 1966."}</Ribbon>
);
