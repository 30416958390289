import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    id: "prod_1",
    year: 2025,
    title: translate("prod_1_title"),
    thumbnail: urlForMedia("production/prvi-krug-u-beogradu.jpg"),
    description: translate("prod_1_desc")
  }
];
