import React from "react";
import styled from "styled-components";
import SkeletonImage from "./../SkeletonImage";
import { Award } from "../Awards";
import { LanguageContext } from "../LanguageContext";
import { get, find } from "lodash";
import { upperCase } from "lodash/string";
import responsive from "../../utils/responsive";

const Container = styled.div`
  display: flex;

  flex-direction: column;
  padding: 3.375rem 0;
  margin: 0 3.8125rem;

  border-top: 2px solid ${props => props.theme.text.default.string()};

  background-color: white;
`;

const Awards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  margin-left: 4.625rem;
  padding-right: 2.875rem;
  justify-content: space-between;
`;

const Inner = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  > * + * {
    margin-top: 1.5rem;
  }
  line-height: 24px;
  font-size: 16px;
`;

export const Caption = styled.label`
  text-transform: uppercase;
  font-weight: 600;
  ${responsive.sm.andSmaller`
    font-weight: 400;
  `}
`;

export const Value = styled.label`
  color: ${props => props.theme.text.default.string()};
  font-weight: 600;
  ${responsive.sm.andSmaller`
    font-weight: 400;
  `}
`;

const Item = styled.div`
  display: block;
  > span + span {
    padding-left: 0.5em;
  }
`;

const DistributionArchiveItem = ({
  image,
  title,
  title_original,
  year,
  duration,
  country,
  director,
  people,
  genre,
  awards,
  style
}) => (
  <LanguageContext.Consumer>
    {({ contentService: { translate } }) => (
      <Container style={style}>
        <Main>
          <SkeletonImage
            src={image}
            alt={title}
            style={{ flex: "0 1 auto" }}
            width={"13rem"}
            height={"18.25rem"}
          />
          <Description>
            <Inner>
              <Caption>{title}</Caption>
              <Item>
                <span>
                  <Value>
                    {[
                      upperCase(title_original),
                      year,
                      genre,
                      duration,
                      country
                    ].join(" | ")}
                  </Value>
                </span>
              </Item>
              <span>
                <Caption>{translate("general.director")}</Caption>
                <Value>&nbsp;|&nbsp;{director}</Value>
              </span>
              <span>
                <Caption>{translate("general.cast")}</Caption>
                <Value>
                  &nbsp;|&nbsp;
                  {get(
                    find(people, _ => _.type === "role_cast"),
                    "members"
                  )}
                </Value>
              </span>
            </Inner>
            {awards && (
              <Awards>
                {awards.map((award, i) => (
                  <Award key={i} award={award} />
                ))}
              </Awards>
            )}
          </Description>
        </Main>
      </Container>
    )}
  </LanguageContext.Consumer>
);

export default DistributionArchiveItem;
