import React from "react";
import styled from "styled-components";
import SkeletonImage from "./SkeletonImage";
import responsive from "../utils/responsive";
import border from "../assets/internal/border.svg";

import Crew from "./Crew";
import Awards from "./Awards";
import { HorizontalContainer } from "./Layout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6em 3em;
  max-width: 70rem;
  margin: auto;
  ${responsive.lg.andSmaller`
    align-items: center;
    padding: 2em;
  }
  `};

  ${responsive.sm.andLarger`
    border: 5px solid transparent;
    border-image: url(${border}) 1;
  `}

  img {
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  }

  ${responsive.sm.andSmaller`
      margin: 0;
      padding: 0 1.75rem;
  `}
`;

const Table = styled.table`
  margin: auto 1em;
  ${responsive.lg.andSmaller`
    margin: 2em 1em 0em 1em;
  `}

  ${responsive.md.andSmaller`
      margin: 0;
      margin-top: 2.5rem;
  `}

  * {
    font-size: ${props => props.theme.textSize.m};
    ${responsive.md.andSmaller`
      font-size: ${props => props.theme.textSize.s};
    `}
    ${responsive.sm.andSmaller`
      font-size: ${props => props.theme.textSize.xs};
    `}
  }
`;

const MovieDescription = ({ image, title, crew, awards, isMobile }) => (
  <Container>
    <HorizontalContainer
      style={
        isMobile
          ? { justifyContent: "center", flexWrap: "wrap" }
          : { justifyContent: "start" }
      }
    >
      <SkeletonImage
        src={image}
        alt={title}
        width={isMobile ? "9.5rem" : "17.75em"}
        height={isMobile ? "13rem" : "24.85em"}
      />
      <Table>{crew && <Crew crew={crew} separator={isMobile} />}</Table>
    </HorizontalContainer>
    {awards && <Awards awards={awards} isMobile={isMobile} />}
  </Container>
);

export default MovieDescription;
