import React, { Fragment } from "react";
import Poster from "../components/Poster";
import Paper from "../components/containers/Paper";
import MovieDescription from "../components/MovieDescription";
import Gallery from "../components/containers/Gallery";
import { CenteredEmblem } from "../components/Icon";

export default ({ slideshow, quote, poster, description, isMobile, props }) => (
  <Fragment>
    {/*<Quote {...quote} />*/}
    <CenteredEmblem />
    <Poster {...poster} style={{ marginTop: 0 }} />
    <Paper noMargin={isMobile}>
      <MovieDescription {...description} isMobile={isMobile} />
      {slideshow && slideshow.length > 0 && <Gallery items={slideshow} />}
    </Paper>
  </Fragment>
);
