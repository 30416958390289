import styled from "styled-components";
import { Paragraph } from "./typography";
import responsive from "../utils/responsive";

export default styled(Paragraph)`
  color: ${props => props.theme.text.emphasized.string()};
  line-height: ${props => props.theme.lineHeight.xl};
  width: 80%;
  margin: 0;
  ${responsive.md.andSmaller`
    width: 100%;
  `};
`;
