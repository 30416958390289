import React from "react";
import { Content, Group, Row, Title } from "./Awards";

const wrap = value => (Array.isArray(value) ? value : [value]);

const Crew = ({ crew, separator }) =>
  crew.map((group, i) => (
    <Group key={i}>
      {wrap(group).map(({ role, members }) => (
        <Row key={`${i}-${role}`}>
          <Title>{role}</Title>
          <Content>{members}</Content>
        </Row>
      ))}
      {separator && (
        <tr>
          <td colSpan={2} className="separator" />
        </tr>
      )}
    </Group>
  ));

export default Crew;
