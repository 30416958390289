import React, { Fragment } from "react";
import { DirectorItem } from "../components/items/DirectorItem";
import Quote from "../components/Quote";
import Float from "../components/Float";
import floatImage from "../assets/images/kanasta-scenario.png";
import ItemGrid from "../components/containers/ItemGrid";
import { Desktop } from "../utils/responsive";
import Paper from "../components/containers/Paper";

export default ({ quote, items, isMobile }) => (
  <Fragment>
    <span>
      <Quote {...quote} />
      <Desktop>
        <div style={{ position: "relative" }}>
          <Float src={floatImage} />
        </div>
      </Desktop>
    </span>
    <Paper noMargin={!isMobile} noSpace>
      <ItemGrid
        Component={DirectorItem}
        initialRows={4}
        items={items}
        itemWidth={isMobile ? 329 : 528}
      />
    </Paper>
  </Fragment>
);
