import React, { useState } from "react";
import styled from "styled-components";
import SkeletonImage from "./SkeletonImage";
import { H2, H3 } from "./typography";
import responsive, { breakpoint } from "../utils/responsive";
import { CenteredContainer, HorizontalContainer } from "./Layout";
import { useMediaQuery } from "react-responsive/src";
import { Separator } from "./Poster";
// import YouTube from "react-youtube";
import Button from "./Button";
import { AnimateSharedLayout, motion } from "framer-motion";

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  min-height: 31rem;
  ${responsive.sm.andSmaller`
    min-height: 10.625rem;
  `}
  border-top: 0.25rem solid ${props => props.theme.text.default.string()};
`;

const Content = styled(CenteredContainer)`
  position: absolute;
  justify-content: center;

  padding: 5em 20%;
  text-align: center;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  h3 {
    margin-bottom: 0;
    font-size: 2em;
    text-transform: uppercase;
    ${responsive.sm.andSmaller`
      font-size: 0.75rem;
    `}
  }

  h2 {
    font-size: 9rem;
    line-height: 1;

    margin: 0;
    text-transform: uppercase;
    text-shadow: #000 1px 5px 10px;

    ${responsive.md.andSmaller`
      font-size: 5rem;
    `}

    ${responsive.sm.andSmaller`
      font-size: 3rem;
    `}
  }

  a {
    margin-top: auto;
  }

  * {
    color: ${props => props.theme.link.primaryColor.string()};
  }
`;

const ButtonContainer = styled(HorizontalContainer)`
  > * + * {
    margin-left: 1em;
  }
  > * {
    flex: 1;
    height: 4rem;
    white-space: nowrap;
    min-width: 18em;
    font-weight: 400;
  }

  ${responsive.sm.andSmaller`
    flex-wrap: nowrap;
    > * {
      height: 2rem;
      min-width: 9rem;
      font-size: 0.5rem;
      width: 100%;
    }
  `};

  ${responsive.xs.andSmaller`
    > * {
      height: 1.5rem;
    }
  `} // margin-top: 4.75em;
  // flex: 0 1 3em;
`;

const FeaturedContent = styled(Content)`
  padding: 0;
  h3 {
    margin-top: 0;
  }
`;

// const TrailerContent = styled(Content)`
//   .youtube-embed-container {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//   }
// `;

// const opts = {
//   height: "100%",
//   width: "100%",
//   playerVars: {
//     autoplay: 1,
//     loop: 1
//   }
// };

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 80%;
`;

const Featured = ({ title, name, image, action, action2, readMoreLink }) => {
  const [playing, setPlaying] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });

  return (
    <Container>
      <SkeletonImage
        height={"auto"}
        src={image}
        alt={"featured picture"}
        style={{
          objectPosition: "right center",
          minHeight: isMobile ? "10.625rem" : "39rem"
        }}
      />
      <AnimateSharedLayout type="crossfade">
        {!playing && (
          <FeaturedContent layoutId={"cta"}>
            <CTA>
              <H3>{title}</H3>
              <Separator
                style={
                  isMobile
                    ? { flex: "0 1 2rem", margin: "0 auto" }
                    : { flex: "0 1 3rem", margin: "0.875em auto" }
                }
              />
              <H2>{name}</H2>
              <Separator
                style={
                  isMobile
                    ? { flex: "0 1 1rem", margin: "0 auto", opacity: 0 }
                    : { flex: "0 1 2rem", margin: "0.875em auto", opacity: 0 }
                }
              />
              <ButtonContainer>
                <Button flavor={"default"} lg onClick={() => setPlaying(true)}>
                  {action}
                </Button>
                {/*<RouterLinkButton*/}
                {/*  disabled*/}
                {/*  flavor={"default"}*/}
                {/*  to={readMoreLink}*/}
                {/*  lg*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener"*/}
                {/*>*/}
                {/*  {action2}*/}
                {/*</RouterLinkButton>*/}
                <Button
                  flavor={"default"}
                  to={readMoreLink}
                  lg
                  target="_blank"
                  rel="noopener"
                >
                  {action2}
                </Button>
              </ButtonContainer>
            </CTA>
          </FeaturedContent>
        )}

        {/*{playing && (*/}
        {/*  <TrailerContent layoutId={"teaser"}>*/}
        {/*    <YouTube*/}
        {/*      videoId={"TLAyP5kiRiM"}*/}
        {/*      id={"featured-preview"}*/}
        {/*      className={"youtube-embed"}*/}
        {/*      containerClassName={"youtube-embed-container"}*/}
        {/*      opts={opts}*/}
        {/*      onEnd={() => setPlaying(false)}*/}
        {/*    />*/}
        {/*  </TrailerContent>*/}
        {/*)}*/}
      </AnimateSharedLayout>
    </Container>
  );
};

export default Featured;
