import React from "react";
import styled from "styled-components";
import Paper from "./containers/Paper";
import ItemGrid from "./containers/ItemGrid";
import SkeletonImage from "./SkeletonImage";
import { H5 } from "./typography";
import { CenteredContainer } from "./Layout";
import responsive from "../utils/responsive";
import HtmlContent from "./HtmlContent";

const ProductionWrapper = styled.div`
  // background: ${props => props.theme.background.contrast.string()};
  background: #F5F3EA;
  border-bottom: 6px solid ${props => props.theme.text.default.string()};
  padding: 4.25rem 2rem 2.875rem 2rem;
  ${responsive.sm.andSmaller`
    padding: 2.25rem 0.5rem 0.5rem 0.5rem;
  `}
`;

const Container = styled(CenteredContainer)`
  justify-content: unset;
  width: min-content;
  div,
  img {
    width: 18rem;
    height: 10rem;
    ${responsive.sm.andSmaller`
      width: 9.125rem;
      height: 6.25rem;
    `}
  }
  i {
    object-fit: fill;
  }

  h5 {
    align-self: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1em 0 0 0;
    font-weight: 500;
    font-size: 1.1rem;
    span {
      text-transform: capitalize;
      padding-left: 0.5rem;
      font-weight: 400;
    }
    ${responsive.sm.andSmaller`
      font-weight: 500;
      font-size: 0.625rem;
    `}
  }

  overflow: hidden;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.7;
  }

  transition: all 0.1s ease;

  > span {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  }
  p {
    color: ${props => props.theme.text.emphasized.string()};
  }
`;

const FilmProductionItem = ({
  title,
  description,
  year,
  thumbnail,
  isMobile
}) => (
  <Container isMobile={isMobile}>
    <span>
      <SkeletonImage src={thumbnail} alt={title} />
    </span>
    <H5>{[title, year].join(" | ")}</H5>
    <HtmlContent content={description} style={{ width: "100%", margin: 0 }} />
  </Container>
);

const FilmProduction = ({ title, items, linkProvider, isMobile }) => (
  <Paper title={title} noMargin>
    <ProductionWrapper>
      <ItemGrid
        itemWidth={isMobile ? 70 : 240}
        items={items || []}
        Component={FilmProductionItem}
        linkProvider={linkProvider}
        maxPerRow={4}
        showAll={true}
      />
    </ProductionWrapper>
  </Paper>
);

export default FilmProduction;
