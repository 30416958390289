import styled, { css } from "styled-components";

export const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${props =>
    props.shouldWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VerticalContainer = styled(CenteredContainer)`
  align-items: unset;
  justify-content: space-between;
  padding: 0 1em;
`;
