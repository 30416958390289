import React from "react";
import styled, { css } from "styled-components";
import responsive from "../../utils/responsive";
import SectionTitle from "../SectionTitle";
import { Paragraph } from "../typography";
import { CenteredContainer } from "../Layout";

export const Paper = styled(CenteredContainer)`
  align-items: unset;
  padding: 0 ${props => (props.noMargin ? "0" : "9.125rem")} ;

  ${props =>
    props.slimMargin &&
    css`
      padding: 0 6rem;
    `}

  ${responsive.md.andSmaller`
    padding: 0 ${props => (props.noMargin ? "0" : "2.625rem")} ;
  `}
  
  ${responsive.sm.andSmaller`
    padding: 0 ${props => (props.noMargin ? "0" : "2rem")} ;
  `}

  ${props =>
    props.fillMobile &&
    css`
      ${responsive.md.andSmaller`
      padding: 0;
    `}
    `}
  
  ${props =>
    props.hasTitle &&
    css`
      padding-top: 0;
    `};        
`;

const Blank = styled.section`
  display: block;
  position: relative;
`;

export default ({
  title,
  subtitle,
  abstract,
  children,
  style,
  id,
  fillMobile,
  noUpper,
  emblem,
  noMargin,
  slimMargin,
  noSpace,
  cfLogo,
  minWidth
}) => (
  <Blank className={!title && !noSpace ? "margin" : undefined}>
    {title && (
      <SectionTitle
        minWidth={minWidth}
        title={title}
        subtitle={subtitle}
        noUpper={noUpper}
        emblem={emblem}
        cfLogo={cfLogo}
      />
    )}
    {abstract && <Paragraph>{abstract}</Paragraph>}
    <Paper
      hasTitle={title}
      style={style}
      id={id}
      fillMobile={fillMobile}
      noMargin={noMargin}
      slimMargin={slimMargin}
    >
      {children}
    </Paper>
  </Blank>
);
