export default translate => [
  {
    id: "short_1",
    title: translate("short_1_title"),
    year: 1997,
    length: 17,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Uroš Stojanović, Mirko Stojković"
      },
      {
        role: translate("role_director"),
        members: "Uroš Stojanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Sandra Stojanović"
      },
      {
        role: translate("role_editor"),
        members: "Branka Pavlović"
      },
      {
        role: translate("role_composer"),
        members: "Janja Lončar"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd."
      }
    ],
    awards: [translate("awards_6")]
  },
  {
    id: "short_2",
    title: translate("short_2_title"),
    year: 1997,
    length: 12,
    people: [
      {
        role: translate("role_cast_2"),
        members: "Gordan Pupovac, Zorica Mirković, Elizabeta Đorevska"
      },
      {
        role: translate("role_screenwriter"),
        members: "Vladimir Paskaljević"
      },
      {
        role: translate("role_director"),
        members: "Vladimir Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragomir Milinković Clauss"
      },
      {
        role: translate("role_editor"),
        members: "Mateja Rackov"
      },
      {
        role: translate("role_composer"),
        members: "Zoran Simjanović"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd"
      }
    ]
  },
  {
    id: "short_3",
    title: translate("short_3_title"),
    year: 1996,
    length: 70,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Volk"
      },
      {
        role: translate("role_director"),
        members: "Zdravko Randić"
      },
      {
        role: translate("role_photo_directors"),
        members: "Jovica Krstić, Radiša Vićentijević"
      },
      {
        role: translate("role_editor"),
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_coproducers"),
        members:
          "Radiotelevizija Srbije, Jugoslovenska kinoteka, Beograd , Prizma, Kragujevac."
      }
    ]
  },
  {
    id: "short_4",
    title: translate("short_4_title"),
    year: 1995,
    length: 92,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Volk."
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Radiša Vićentijević"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      },
      {
        role: translate("role_coproducers"),
        members:
          "Radiotelevizija Srbije, Jugoslovenska kinoteka, Beograd, Prizma, Kragujevac"
      }
    ]
  },
  {
    id: "short_5",
    title: translate("short_5_title"),
    year: 1995,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Radomir Putnik"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Radiša Vićentijević"
      }
    ]
  },
  {
    id: "short_6",
    title: translate("short_6_title"),
    year: 1995,
    length: 55,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Volk"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Jovica Krstić"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      },
      {
        role: translate("role_coproducers"),
        members:
          "Radiotelevizija Srbije, Jugoslovenska kinoteka, Beograd, Prizma, Kragujevac"
      }
    ]
  },
  {
    id: "short_7",
    title: translate("short_7_title"),
    year: 1995,
    length: 89,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vasa Kazimirović"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Jovica Krstić"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      },
      {
        role: translate("role_coproducers"),
        members:
          "Radiotelevizija Srbije, Jugoslovenska kinoteka, Beograd, Beograf, Beograd, Prizma, Kragujevac"
      }
    ]
  },
  {
    id: "short_8",
    title: translate("short_8_title"),
    year: 1994,
    length: 29,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Ilja Slani"
      },
      {
        role: translate("role_director"),
        members: "Ilja Slani"
      },
      {
        role: translate("role_photo_director"),
        members: "Bratislav Grbić"
      }
    ]
  },
  {
    id: "short_9",
    title: translate("short_9_title"),
    year: 1988,
    length: 27,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Adam Puslojić"
      },
      {
        role: translate("role_director"),
        members: "Zoran Petrović"
      },
      {
        role: translate("role_photo_directors"),
        members: "Ljubomir Sekulić, V. Đurović, A. Ćetković, T. Žujović"
      },
      {
        role: translate("role_coproducer"),
        members: "Televizija Beograd"
      }
    ]
  },
  {
    id: "short_10",
    title: translate("short_10_title"),
    year: 1988,
    length: 9,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_director"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Zoran Bogdanović"
      },
      {
        role: translate("role_editor"),
        members: "Aleksandar Ilić"
      },
      {
        role: translate("role_coproducer"),
        members: "Antidogmin, Beograd"
      }
    ],
    awards: [translate("awards_15")]
  },
  {
    id: "short_11",
    title: translate("short_11_title"),
    year: 1987,
    length: 7,
    people: [
      {
        role: translate("role_cast_2"),
        members:
          "Danilo Stojković, Branislav Jerinić, Branimir Brstina, Mima Karadžić, Vojislav Brajović."
      },
      {
        role: translate("role_screenwriters"),
        members: "Miloš Radović, Mirko Stojković"
      },
      {
        role: translate("role_director"),
        members: "Miloš Radović"
      },
      {
        role: translate("role_photo_director"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        members: "Boža Stanković"
      },
      {
        role: translate("role_composer"),
        members: "Dragan Ilić"
      }
    ],
    awards: [translate("awards_3"), translate("awards_10")]
  },
  {
    id: "short_12",
    title: translate("short_12_title"),
    year: 1986,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Stevan Koprivica."
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_editor"),
        members: "Katarina Stojanović."
      }
    ]
  },
  {
    id: "short_13",
    title: translate("short_13_title"),
    year: 1985,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Živadinović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      }
    ],
    awards: [
      translate("awards_8"),
      translate("awards_12"),
      translate("awards_23"),
      translate("awards_20"),
      translate("awards_16")
    ]
  },
  {
    id: "short_14",
    title: translate("short_14_title"),
    year: 1984,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Dejan Kosanović"
      },
      {
        role: translate("role_director"),
        members: "Dejan Kosanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Predrag Bambić"
      },
      {
        role: translate("role_editor"),
        members: "Boža Stanković"
      }
    ]
  },
  {
    id: "short_15",
    title: translate("short_15_title"),
    year: 1983,
    length: 16,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Ivan Kaljević"
      },
      {
        role: translate("role_director"),
        members: "Ivan Kaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Časlav Petrović"
      },
      {
        role: translate("role_editor"),
        members: "Andrija Dimitrijević"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd"
      }
    ]
  },
  {
    id: "short_16",
    title: translate("short_16_title"),
    year: 1983,
    length: 22,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_director"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_photo_directors"),
        members: "Predrag Bambić, Dragan Daničić i Radoslav Vladić"
      },
      {
        role: translate("role_composer"),
        members: "Zoran Hristić"
      },
      {
        role: translate("role_editor"),
        members: "Andrija Zafranović"
      }
    ]
  },
  {
    id: "short_17",
    title: translate("short_17_title"),
    year: 1983,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Aleksandar Bojić"
      },
      {
        role: translate("role_director"),
        members: "Aleksandar Bojić"
      },
      {
        role: translate("role_photo_director"),
        members: "Milan Dimić"
      },
      {
        role: translate("role_editor"),
        members: "Petar Jakonić"
      },
      {
        role: translate("role_composer"),
        members: "Nenad Bajić"
      }
    ]
  },
  {
    id: "short_18",
    title: translate("short_18_title"),
    year: 1983,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Slobodan Stojanović"
      },
      {
        role: translate("role_director"),
        members: "Milorad Jakšić - Fanđo."
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_editor"),
        members: "Boža Stanković"
      }
    ]
  },
  {
    id: "short_19",
    title: translate("short_19_title"),
    year: 1983,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Predrag Nešković"
      },
      {
        role: translate("role_director"),
        members: "Dragoljub Mančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragoljub Mančić"
      },
      {
        role: translate("role_composer"),
        members: "Arsen Dedić"
      },
      {
        role: translate("role_editor"),
        members: "Petar Aranđelović"
      }
    ]
  },
  {
    id: "short_20",
    title: translate("short_20_title"),
    year: 1983,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Slobodan Stojanović"
      },
      {
        role: translate("role_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_editor"),
        members: "Boža Stanković"
      }
    ]
  },
  {
    id: "short_21",
    title: translate("short_21_title"),
    year: 1982,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vladimir Filipović"
      },
      {
        role: translate("role_directors"),
        members: "Milorad Jakšić- Fanđo ,Slobodan Golubović - Leman"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić- Fanđo"
      }
    ]
  },
  {
    id: "short_22",
    title: translate("short_22_title"),
    year: 1982,
    length: 14,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_director"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_photo_director"),
        members: "Radoslav Vladić"
      }
    ]
  },
  {
    id: "short_23",
    title: translate("short_23_title"),
    year: 1982,
    length: 32,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Stevan Koprivica"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      },
      {
        role: translate("role_editor"),
        members: "Katarina Stojanović"
      }
    ]
  },
  {
    id: "short_24",
    title: translate("short_24_title"),
    year: 1982,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vladimir Kolar"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_editor"),
        members: "Katarina Stojanović"
      }
    ]
  },
  {
    id: "short_25",
    title: translate("short_25_title"),
    year: 1982,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vladimir Kolar"
      },
      {
        role: translate("role_director"),
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_photo_director"),
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_editor"),
        members: "Katarina Stojanović"
      }
    ]
  },
  {
    id: "short_26",
    title: translate("short_26_title"),
    year: 1981,
    length: 30,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      }
    ]
  },
  {
    id: "short_27",
    title: translate("short_27_title"),
    year: 1981,
    length: 14,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_director"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragan Daničić[a]"
      },
      {
        role: translate("role_editor"),
        members: "Mirjana Rašković"
      }
    ]
  },
  {
    id: "short_28",
    title: translate("short_28_title"),
    year: null,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      }
    ],
    awards: [translate("awards_7"), translate("awards_11")]
  },
  {
    id: "short_29",
    title: translate("short_29_title"),
    year: 1980,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_director"),
        members: "Milorad Bajić"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Emilija Marković"
      }
    ]
  },
  {
    id: "short_30",
    title: translate("short_30_title"),
    year: 1979,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Jelena Đokić"
      }
    ],
    awards: [
      translate("awards_3"),
      translate("awards_24"),
      translate("awards_14"),
      translate("awards_18"),
      translate("awards_22")
    ]
  },
  {
    id: "short_31",
    title: translate("short_31_title"),
    year: 1979,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Đorđe Radišić"
      },
      {
        role: translate("role_directors"),
        members: "Milorad Jakšić - Fanđo, Zoran Amar"
      },
      {
        role: translate("role_editor"),
        members: "Boža Stanković"
      }
    ]
  },
  {
    id: "short_32",
    title: translate("short_32_title"),
    year: 1979,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_director"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_editor"),
        members: "Mirjana Mitrović"
      }
    ]
  },
  {
    id: "short_33",
    title: translate("short_33_title"),
    year: 1979,
    length: 14,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vane Marinović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      },
      {
        role: translate("role_editor"),
        members: "Mirjana Mitrović"
      }
    ]
  },
  {
    id: "short_34",
    title: translate("short_34_title"),
    year: 1979,
    length: 18,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Kosta Konstatinović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_editor"),
        members: "Mirjana Mitrović"
      }
    ]
  },
  {
    id: "short_35",
    title: translate("short_35_title"),
    year: 1978,
    length: 22,
    people: [
      {
        role: translate("role_cast_2"),
        members:
          "Neda Osmokrović, Dušan Janićijević, Neda Arnerić, Mića Tomić, Kosta Balaban i Gordana Pavlov"
      },
      {
        role: translate("role_screenwriter"),
        members: "Milenko Jovanović"
      },
      {
        role: translate("role_director"),
        members: "Milenko Jovanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragan Munižaba"
      },
      {
        role: translate("role_editor"),
        members: "Mirjana Prica"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd"
      }
    ]
  },
  {
    id: "short_36",
    title: translate("short_36_title"),
    year: 1978,
    length: 9,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Ljiljana Milošević"
      },
      {
        role: translate("role_director"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_photo_directors"),
        members: "Predrag Popović, Đorđe Nikolić i Aleksandar Jovanović"
      },
      {
        role: translate("role_editor"),
        members: "Branka Čeperac"
      },
      {
        role: translate("role_music"),
        members: "„Bijelo dugme“"
      }
    ]
  },
  {
    id: "short_37",
    title: translate("short_37_title"),
    year: 1977,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_director"),
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_photo_director"),
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_editor"),
        members: "Marija Milivojević"
      }
    ]
  },
  {
    id: "short_38",
    title: translate("short_38_title"),
    year: 1977,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Petar Radosavljević"
      }
    ],
    awards: [
      translate("awards_4"),
      translate("awards_19"),
      translate("awards_21")
    ]
  },
  {
    id: "short_39",
    title: translate("short_39_title"),
    year: 1977,
    length: 16,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Mišo Lazarević, Dejan Kosanović"
      },
      {
        role: translate("role_director"),
        members: "Dejan Kosanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_editor"),
        members: "Marko Babac"
      }
    ]
  },
  {
    id: "short_40",
    title: translate("short_40_title"),
    year: 1977,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Petar Radosavljević"
      }
    ]
  },
  {
    id: "short_41",
    title: translate("short_41_title"),
    year: 1977,
    length: 4,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_director"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Jan Koščalik"
      },
      {
        role: translate("role_editor"),
        members: "Marija Milivojević"
      }
    ],
    awards: [translate("awards_3")]
  },
  {
    id: "short_42",
    title: translate("short_42_title"),
    year: 1977,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_director"),
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_photo_director"),
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_editor"),
        members: "Marija Milivojević"
      }
    ]
  },
  {
    id: "short_43",
    title: translate("short_43_title"),
    year: 1977,
    length: 10,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Ratko Đurović, Nikola Jovićević"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_editor"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_composer"),
        members: "Rafailo Blam"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ]
  },
  {
    id: "short_44",
    title: translate("short_44_title"),
    year: 1977,
    length: 12,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Milosav Lalić, Milovan Pajković"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ]
  },
  {
    id: "short_45",
    title: translate("short_45_title"),
    year: 1976,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Dimitrijević"
      },
      {
        role: translate("role_director"),
        members: "Milorad Dimitrijević"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Natalija Cvijić"
      }
    ]
  },
  {
    id: "short_46",
    title: translate("short_46_title"),
    year: 1976,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      }
    ],
    awards: [
      translate("awards_5"),
      translate("awards_25"),
      translate("awards_17"),
      translate("awards_22")
    ]
  },
  {
    id: "short_47",
    title: translate("short_47_title"),
    year: 1976,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_director"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_photo_director"),
        members: "Tomislav Pinter"
      }
    ],
    description: translate("short_47_description")
  },
  {
    id: "short_48",
    title: translate("short_48_title"),
    year: 1976,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić – Fanđo"
      }
    ],
    description: translate("short_48_description")
  },
  {
    id: "short_49",
    title: translate("short_49_title"),
    year: 1976,
    length: 50,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      }
    ],
    description: translate("short_49_description")
  },
  {
    id: "short_50",
    title: translate("short_50_title"),
    year: 1976,
    length: 19,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_50_description")
  },
  {
    id: "short_51",
    title: translate("short_51_title"),
    year: 1976,
    length: 17,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_51_description")
  },
  {
    id: "short_52",
    title: translate("short_52_title"),
    year: 1976,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Dušan Perković"
      },
      {
        role: translate("role_director"),
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      }
    ],
    description: translate("short_52_description")
  },
  {
    id: "short_53",
    title: translate("short_53_title"),
    year: 1976,
    length: 8,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_director"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_photo_director"),
        members: "Ciga Jovanović"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmske novosti, Beograd"
      }
    ],
    description: translate("short_53_description")
  },
  {
    id: "short_54",
    title: translate("short_54_title"),
    year: 1976,
    length: 7,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_director"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_photo_director"),
        members: "Mihajlo Jovanović"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmske novosti, Beograd."
      }
    ],
    description: translate("short_54_description")
  },
  {
    id: "short_55",
    title: translate("short_55_title"),
    year: 1976,
    length: 7,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Ratko Đurović, Nikola Jovićević"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio, Titograd"
      }
    ],
    description: translate("short_55_description")
  },
  {
    id: "short_56",
    title: translate("short_56_title"),
    year: 1976,
    length: 6,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Borivoje Ljubinković"
      },
      {
        role: translate("role_photo_director"),
        members: "Branko Kosić"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd."
      }
    ],
    description: translate("short_56_description")
  },
  {
    id: "short_57",
    title: translate("short_57_title"),
    year: 1976,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      }
    ],
    awards: [translate("awards_2"), translate("awards_1")],
    description: translate("short_57_description")
  },
  {
    id: "short_58",
    title: translate("short_58_title"),
    year: 1975,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zdravko Velimirović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_58_description")
  },
  {
    id: "short_59",
    title: translate("short_59_title"),
    year: 1975,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Aleksandar Komnenović"
      },
      {
        role: translate("role_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_59_description")
  },
  {
    id: "short_60",
    title: translate("short_60_title"),
    year: 1975,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Aleksandar Komnenović"
      },
      {
        role: translate("role_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_60_description")
  },
  {
    id: "short_61",
    title: translate("short_61_title"),
    year: 1975,
    length: 3,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_61_description")
  },
  {
    id: "short_62",
    title: translate("short_62_title"),
    year: 1975,
    length: 4,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_62_description")
  },
  {
    id: "short_63",
    title: translate("short_63_title"),
    year: 1975,
    length: 5,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_63_description")
  },
  {
    id: "short_64",
    title: translate("short_64_title"),
    year: 1975,
    length: 5,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_64_description")
  },
  {
    id: "short_65",
    title: translate("short_65_title"),
    year: 1975,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Dušan Povh"
      },
      {
        role: translate("role_director"),
        members: "Dušan Povh"
      },
      {
        role: translate("role_photo_director"),
        members: "Veka Kokalj"
      },
      {
        role: translate("role_composer"),
        members: "Oliver Telban"
      },
      {
        role: translate("role_coproducer"),
        members: "Viba film, Ljubljana"
      }
    ],
    description: translate("short_65_description")
  },
  {
    id: "short_66",
    title: translate("short_66_title"),
    year: 1975,
    length: 9,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Branko Milošević"
      },
      {
        role: translate("role_director"),
        members: "Branko Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Slavo Tadić"
      },
      {
        role: translate("role_coproducer"),
        members: "Neoplanta, Novi Sad"
      }
    ],
    description: translate("short_66_description")
  },
  {
    id: "short_67",
    title: translate("short_67_title"),
    year: 1975,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_coproducer"),
        members: "Generalna direkcija za film LAR — Tripoli"
      }
    ],
    description: translate("short_67_description")
  },
  {
    id: "short_68",
    title: translate("short_68_title"),
    year: 1975,
    length: 8,
    people: [
      {
        role: translate("role_cast"),
        members: "Jovan Nikačević"
      },
      {
        role: translate("role_screenwriter"),
        members: "Milorad Laković"
      },
      {
        role: translate("role_director"),
        members: "Milorad Laković"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragoljub Stevanović"
      },
      {
        role: translate("role_coproducer"),
        members: "Fakultet dramskih umetnosti, Beograd"
      }
    ],
    description: translate("short_68_description")
  },
  {
    id: "short_69",
    title: translate("short_69_title"),
    year: 1975,
    length: 10,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Marko Špadijer, Sreten Asanović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ]
  },
  {
    id: "short_70",
    title: translate("short_70_title"),
    year: 1975,
    length: 10,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Nikola Jovićević, Nikola Stanković"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Radović"
      }
    ]
  },
  {
    id: "short_71",
    title: translate("short_71_title"),
    year: 1975,
    length: 7,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Slavoljub Đukić, Mića Milošević"
      },
      {
        role: translate("role_director"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Đorđe Nikolić"
      }
    ]
  },
  {
    id: "short_72",
    title: translate("short_72_title"),
    year: 1975,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_director"),
        members: "Miodrag Paskuči"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      }
    ]
  },
  {
    id: "short_73",
    title: translate("short_73_title"),
    year: 1975,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zoran Predić"
      },
      {
        role: translate("role_director"),
        members: "Zoran Predić"
      },
      {
        role: translate("role_photo_director"),
        members: "Sekula Banović"
      }
    ]
  },
  {
    id: "short_74",
    title: translate("short_74_title"),
    year: 1975,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        members: "Natalija Cvijić"
      },
      {
        role: translate("role_coproducer"),
        members: "Generalna direkcija za film LAR-Tripoli"
      }
    ],
    awards: [
      translate("awards_9"),
      translate("awards_13"),
      translate("awards_19")
    ],
    description: translate("short_74_description")
  },
  {
    id: "short_75",
    title: translate("short_75_title"),
    year: 1974,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vladimir Kolar"
      },
      {
        role: translate("role_director"),
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_75_description")
  },
  {
    id: "short_76",
    title: translate("short_76_title"),
    year: 1974,
    length: 60,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Slavoljub Đukić, Mića Milošević"
      },
      {
        role: translate("role_directors"),
        members: "Slavoljub Đukić, Mića Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Đorđe Nikolić"
      }
    ],
    awards: [translate("awards_3")],
    description: translate("short_76_description")
  },
  {
    id: "short_77",
    title: translate("short_77_title"),
    year: 1974,
    length: 7,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Stanko Crnobrnja"
      },
      {
        role: translate("role_director"),
        members: "Stanko Crnobrnja"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragan Munižaba"
      }
    ],
    description: translate("short_77_description")
  },
  {
    id: "short_78",
    title: translate("short_78_title"),
    year: 1974,
    length: 7,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Bane Bastać"
      },
      {
        role: translate("role_director"),
        members: "Bane Bastać"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      }
    ],
    description: translate("short_78_description")
  },
  {
    id: "short_79",
    title: translate("short_79_title"),
    year: 1974,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Radmila Petrović"
      },
      {
        role: translate("role_director"),
        members: "Mića Uzelac"
      },
      {
        role: translate("role_photo_director"),
        members: "Ljubomir Ivković"
      }
    ],
    description: translate("short_79_description")
  },
  {
    id: "short_80",
    title: translate("short_80_title"),
    year: 1974,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_director"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Milan Spasić"
      },
      {
        role: translate("role_editor"),
        members: "Mihajlo Ilić"
      },
      {
        role: translate("role_coproducer"),
        members: "TV Beograd"
      }
    ],
    description: translate("short_80_description")
  },
  {
    id: "short_81",
    title: translate("short_81_title"),
    year: 1974,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milan Šećerović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Radović"
      }
    ],
    description: translate("short_81_description")
  },
  {
    id: "short_82",
    title: translate("short_82_title"),
    year: 1974,
    people: [
      {
        role: translate("role_actors"),
        members:
          "Bata Živojinović, Branislav Jerinić, Pavle Vujisić, Ljiljana Gazdić, Minja Vojvodić, Duško Janićijević"
      },
      {
        role: translate("role_screenwriter"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_director"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Milan Spasić"
      }
    ],
    description: translate("short_82_description"),
    awards: [translate("awards_3")]
  },
  {
    id: "short_83",
    title: translate("short_83_title"),
    year: 1974,
    length: 8,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_director"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_photo_directors"),
        members: "Blagoje Lupa, Dušan Marović"
      }
    ],
    description: translate("short_83_description")
  },
  {
    id: "short_84",
    title: translate("short_84_title"),
    year: 1974,
    length: 9,
    people: [
      {
        role: translate("role_cast"),
        members: "Milan Srdoč"
      },
      {
        role: translate("role_screenwriter"),
        members: "Dragan Đorđević"
      },
      {
        role: translate("role_director"),
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_84_description")
  },
  {
    id: "short_85",
    title: translate("short_85_title"),
    year: 1974,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_85_description")
  },
  {
    id: "short_86",
    title: translate("short_86_title"),
    year: 1974,
    length: 12,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Božidar Rančić, Radovan Milovanović"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_86_description")
  },
  {
    id: "short_87",
    title: translate("short_87_title"),
    year: 1973,
    length: 5,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Slobodan Ratković"
      },
      {
        role: translate("role_director"),
        members: "Slobodan Ratković"
      },
      {
        role: translate("role_photo_director"),
        members: "Dragan Jovanović"
      },
      {
        role: translate("role_coproducer"),
        members: "Akademija za pozorište, film, radio, Televizija, Beograd"
      }
    ],
    description: translate("short_87_description")
  },
  {
    id: "short_88",
    title: translate("short_88_title"),
    year: 1973,
    length: 8,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Ljiljana Milošević"
      },
      {
        role: translate("role_director"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Đorđe Nikolić"
      }
    ],
    description: translate("short_88_description")
  },
  {
    id: "short_89",
    title: translate("short_89_title"),
    year: 1973,
    length: 8,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Rajko Đukić"
      },
      {
        role: translate("role_director"),
        members: "Rajko Đukić"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Lepetić"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Košutnjak, Beograd"
      }
    ],
    description: translate("short_89_description")
  },
  {
    id: "short_90",
    title: translate("short_90_title"),
    year: 1973,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Svetozar Đonović"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Rasim Crnovršanin"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ],
    description: translate("short_90_description")
  },
  {
    id: "short_91",
    title: translate("short_91_title"),
    year: 1973,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zoran Lakić"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Rasim Crnovršanin"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Košutnjak, Beograd"
      }
    ],
    description: translate("short_91_description")
  },
  {
    id: "short_92",
    title: translate("short_92_title"),
    year: 1973,
    length: 14,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Anton Zadrima"
      },
      {
        role: translate("role_directors"),
        members: "Branislav Bastać, Nikola Jovićević"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Košutnjak, Beograd"
      }
    ],
    description: translate("short_92_description")
  },
  {
    id: "short_93",
    title: translate("short_93_title"),
    year: 1973,
    length: 7,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_director"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Đorđe Nikolić"
      }
    ],
    description: translate("short_93_description")
  },
  {
    id: "short_94",
    title: translate("short_94_title"),
    year: 1973,
    length: 11,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Jovan Aćin, Dejan Karaklajić"
      },
      {
        role: translate("role_directors"),
        members: "Jovan Aćin, Dejan Karaklajić"
      },
      {
        role: translate("role_photo_directors"),
        members: "Jovan Aćin, Dejan Karaklajić"
      }
    ],
    description: translate("short_94_description")
  },
  {
    id: "short_95",
    title: translate("short_95_title"),
    year: 1973,
    length: 29,
    people: [
      {
        role: translate("role_cast_2"),
        members:
          "Bata Živojinović, Branislav Jerinić, Pavle Vujisić, Ljiljana Gazdić, Duško Janićijević, Minja Vojvodić"
      },
      {
        role: translate("role_screenwriter"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_director"),
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        members: "Milan Spasić"
      },
      {
        role: translate("role_editor"),
        members: "Mihajlo Ilić"
      },
      {
        role: translate("role_coproducer"),
        members: "Radiotelevizija Beograd- II program"
      }
    ],
    description: translate("short_95_description")
  },
  {
    id: "short_96",
    title: translate("short_96_title"),
    year: 1973,
    length: 17,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "S. Školjanikov"
      },
      {
        role: translate("role_director"),
        members: "S. Školjanikov"
      },
      {
        role: translate("role_photo_director"),
        members: "S. Školjanikov"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ],
    description: translate("short_96_description")
  },
  {
    id: "short_97",
    title: translate("short_97_title"),
    year: 1973,
    length: 30,
    people: [
      {
        role: translate("role_cast_2"),
        members:
          "Drago Čumić- Čuma, Branka Petrić, Božidar Stošić, Cvijeta Mesić"
      },
      {
        role: translate("role_screenwriter"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_director"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      },
      {
        role: translate("role_composer"),
        members: "Bojan Adamič"
      }
    ],
    description: translate("short_97_description")
  },
  {
    id: "short_98",
    title: translate("short_98_title"),
    year: 1973,
    length: 5,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Džoja Ratković, Jovan Aćin"
      },
      {
        role: translate("role_directors"),
        members: "Džoja Ratković, Jovan Aćin"
      },
      {
        role: translate("role_photo_director"),
        members: "Mirko Vučićević"
      }
    ],
    description: translate("short_98_description")
  },
  {
    id: "short_99",
    title: translate("short_99_title"),
    year: 1973,
    length: 9,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Nikola Majdak, David Albahari"
      },
      {
        role: translate("role_directors"),
        members: "Nikola Majdak, David Albahari"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      }
    ],
    description: translate("short_99_description")
  },
  {
    id: "short_100",
    title: translate("short_100_title"),
    year: 1973,
    length: 7,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Vuk Drašković, Puriša Đorđević"
      },
      {
        role: translate("role_director"),
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      }
    ],
    description: translate("short_100_description")
  },
  {
    id: "short_101",
    title: translate("short_101_title"),
    year: 1973,
    length: 6,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "dr Draško Ređep, Stevan Stanić"
      },
      {
        role: translate("role_director"),
        members: "Branko Milošević"
      },
      {
        role: translate("role_photo_director"),
        members: "Branko Ivatović"
      }
    ],
    description: translate("short_101_description")
  },
  {
    id: "short_102",
    title: translate("short_102_title"),
    year: 1973,
    length: 13,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Tatjana Živanović"
      },
      {
        role: translate("role_director"),
        members: "Tatjana Živanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Huan Karlos Fero"
      }
    ],
    description: translate("short_102_description")
  },
  {
    id: "short_103",
    title: translate("short_103_title"),
    year: 1972,
    length: 9,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_director"),
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_photo_director"),
        members: "Živorad Milić"
      }
    ],
    description: translate("short_103_description")
  },
  {
    id: "short_104",
    title: translate("short_104_title"),
    year: 1971,
    length: 14,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_director"),
        members: "Milorad Jakšić - Fanđo"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić - Fanđo"
      }
    ],
    description: translate("short_104_description")
  },
  {
    id: "short_105",
    title: translate("short_105_title"),
    year: 1971,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Čedo Vulević"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovičević"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      },
      {
        role: translate("role_coproducer"),
        members: "Filmski studio Titograd"
      }
    ],
    description: translate("short_105_description")
  },
  {
    id: "short_106",
    title: translate("short_106_title"),
    year: 1971,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Augustin Škoro"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Stanković"
      }
    ],
    description: translate("short_106_description")
  },
  {
    id: "short_107",
    title: translate("short_107_title"),
    year: 1971,
    length: 80,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Zdravko Velimirović"
      },
      {
        role: translate("role_director"),
        members: "Zdravko Velimirović"
      },
      {
        role: translate("role_photo_director"),
        members: "Branko Mihajlovski"
      }
    ],
    awards: [translate("awards_27")],
    description: translate("short_107_description")
  },
  {
    id: "short_108",
    title: translate("short_108_title"),
    year: 1971,
    length: 8,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Mića Milošević, Lila Milošević"
      },
      {
        role: translate("role_director"),
        members: "Mića Milošević"
      },
      {
        role: translate("role_photo_directors"),
        members: "Dragan Mitrović, Đorđe Nikolić, Rasim Crnovršanin"
      },
      {
        role: translate("role_coproducers"),
        members: "Filmske novosti, Beograd, Radiotelevizija Beograd"
      }
    ],
    description: translate("short_108_description")
  },
  {
    id: "short_109",
    title: translate("short_109_title"),
    year: 1971,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Svetozar Lozo"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Stanković"
      }
    ],
    description: translate("short_109_description")
  },
  {
    id: "short_110",
    title: translate("short_110_title"),
    year: 1971,
    length: 12,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Jovan Rančić, Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Jovan Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ]
  },
  {
    id: "short_111",
    title: translate("short_111_title"),
    year: 1971,
    length: 16,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Tori Janković"
      },
      {
        role: translate("role_director"),
        members: "Tori Janković"
      },
      {
        role: translate("role_photo_director"),
        members: "Milorad Jakšić-Fanđo"
      }
    ]
  },
  {
    id: "short_112",
    title: translate("short_112_title"),
    year: 1971,
    length: 15,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Mara Harisijadis"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Časlav Momčilović"
      }
    ]
  },
  {
    id: "short_113",
    title: translate("short_113_title"),
    year: 1971,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_director"),
        members: "Božidar Rančić"
      },
      {
        role: translate("role_photo_director"),
        members: "Stevo Radović"
      }
    ]
  },
  {
    id: "short_114",
    title: translate("short_114_title"),
    year: 1971,
    length: 25,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Borivoje Dikić"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Stanković"
      }
    ]
  },
  {
    id: "short_115",
    title: translate("short_115_title"),
    year: 1971,
    length: 11,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Anton Zadrima"
      },
      {
        role: translate("role_director"),
        members: "Nikola Jovičević"
      },
      {
        role: translate("role_photo_director"),
        members: "Petar Lalović"
      },
      {
        role: translate("role_composer"),
        members: "Cvjetko Ivanović"
      }
    ],
    description: translate("short_115_description")
  },
  {
    id: "short_116",
    title: translate("short_116_title"),
    year: 1970,
    length: 24,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Miodrag Radivojević, Borivoje Dikić"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Stanković"
      }
    ],
    description: translate("short_116_description")
  },
  {
    id: "short_117",
    title: translate("short_117_title"),
    year: 1970,
    length: 16,
    people: [
      {
        role: translate("role_screenwriters"),
        members: "Nikola Stojanović (Slobodan Novaković)?, Nikola Stanković"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Bogoljub Petrović"
      }
    ],
    description: translate("short_117_description")
  },
  {
    id: "short_118",
    title: translate("short_118_title"),
    year: 1969,
    length: 12,
    people: [
      {
        role: translate("role_cast_2"),
        members: "Vladimir Popović, Mirjana Božić"
      },
      {
        role: translate("role_screenwriter"),
        members: "Svetislav Štetin"
      },
      {
        role: translate("role_director"),
        members: "Svetislav Štetin"
      },
      {
        role: translate("role_photo_director"),
        members: "Ilija Čeperac"
      }
    ],
    description: translate("short_118_description")
  },
  {
    id: "short_119",
    title: translate("short_119_title"),
    year: 1968,
    length: 5,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Nikola Stanković."
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Sekulović"
      }
    ],
    description: translate("short_119_description")
  },
  {
    id: "short_120",
    title: translate("short_120_title"),
    year: 1968,
    length: 5,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Sekulović"
      }
    ],
    description: translate("short_120_description")
  },
  {
    id: "short_121",
    title: translate("short_121_title"),
    year: 1968,
    length: 7,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_director"),
        members: "Nikola Stanković"
      },
      {
        role: translate("role_photo_director"),
        members: "Aleksandar Sekulović"
      }
    ],
    description: translate("short_121_description")
  },
  {
    id: "short_122",
    title: translate("short_122_title"),
    year: 1968,
    length: 10,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vera Stojnić - Lebović"
      },
      {
        role: translate("role_director"),
        members: "Gordana Boškov"
      },
      {
        role: translate("role_camera"),
        members: "Stevo Radović"
      }
    ],
    description: translate("short_122_description")
  },
  {
    id: "short_123",
    title: translate("short_123_title"),
    year: 1968,
    length: 12,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Vera Crvenčanin"
      },
      {
        role: translate("role_director"),
        members: "Vera Crvenčanin"
      },
      {
        role: translate("role_photo_director"),
        members: "Nikola Majdak"
      }
    ],
    description: translate("short_123_description")
  },
  {
    id: "short_124",
    title: translate("short_124_title"),
    year: 1967,
    length: 20,
    people: [
      {
        role: translate("role_screenwriter"),
        members: "Svetislav Štetin"
      },
      {
        role: translate("role_director"),
        members: "Svetislav Štetin"
      },
      {
        role: translate("role_photo_directors"),
        members: "Ilija Čeperac, Borislav Milanović"
      }
    ],
    description: translate("short_124_description")
  }
];
