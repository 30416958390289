import styled, { css } from "styled-components";
import { Link } from "@reach/router";
import responsive from "../../utils/responsive";

export const PlainLink = styled.a`
  color: ${props => props.theme.link[props.color || "primaryColor"].string()};
  :hover {
    ${props =>
      props.zoom &&
      css`
        transform: scale(1.02);
      `}
    text-decoration: none;
    filter: brightness(80%);
    cursor: pointer;
  }
  transition: all 0.1s ease;

  ${responsive.md.andSmaller`
    font-size: 0.75rem;
  `};
`;

export default PlainLink.withComponent(Link);
