import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FacebookLink, InstagramLink, YouTubeLink } from "./SocialButton";
import LangButton from "../navigation/LangButton";
import { LanguageContext, languageOptions } from "../LanguageContext";
import config from "../../config";

const ShareContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  > * {
    pointer-events: all;
  }

  font-size: 0.6em;
  opacity: 0.8;
  width: 100%;
  align-items: center;
`;

const variants = {
  closed: {
    rotateZ: 0,
    translateX: 0,
    flexDirection: "column",
    transition: {
      delay: 0.3
    }
  },
  open: {
    rotateZ: 90,
    // translateX: "200%",
    transition: {},
    width: "100%",
    justifyContent: "space-between"
  }
};

export default ({ style }) => {
  const cycleLang = current => {
    const idx = languageOptions.findIndex(_ => _.lang === current);
    return languageOptions[(idx + 1) % languageOptions.length].lang;
  };

  return (
    <ShareContainer variants={variants} style={style}>
      <LanguageContext.Consumer>
        {({ language, changeLanguage }) => (
          <LangButton
            lang={cycleLang(language)}
            onClick={() => changeLanguage(cycleLang(language))}
          />
        )}
      </LanguageContext.Consumer>
      <InstagramLink to={config.instagramProfile} />
      <YouTubeLink to={config.youtubeChannel} style={{ fontSize: "0.65rem" }} />
      <FacebookLink to={config.facebookPage} />
    </ShareContainer>
  );
};
