import React from "react";
import styled, { css } from "styled-components";
import SkeletonImage from "./SkeletonImage";
import { H2 } from "./typography";
import responsive, { breakpoint } from "../utils/responsive";
import { RouterLinkButton } from "./LinkButton";
import { CenteredContainer } from "./Layout";
import { useMediaQuery } from "react-responsive/src";
import HtmlContent from "./HtmlContent";

const Container = styled.div`
  ::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(100% / 1.935);
  }
  ::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }

  width: 100%;
  position: relative;
  margin: 0;
  ${responsive.md.andSmaller`
    font-size: 0.7em;
  `};
`;

const Content = styled(CenteredContainer)`
  position: absolute;

  ${props =>
    props.expand &&
    css`
      position: relative;
    `}

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5em 20%;
  height: 100%;

  ${responsive.md.andSmaller`
    padding-left: 4em;
    padding-right: 4em;
  `};

  p {
    color: ${props => props.theme.text.subtle.string()};
    text-align: justify;
    overflow: hidden;
    text-overflow: clip;

    flex: 0 1 auto;
    margin-bottom: 0;
    overflow: auto;
  }

  a {
    margin-top: 3em;
    font-weight: 400;

    ${responsive.sm.andSmaller`
      margin-top: auto;
      margin-bottom: 1.5rem;
    `}
  }

  ${responsive.xs.andSmaller`
    position: relative;
  `}

  @media (min-width: 1600px) {
    h2 {
      font-size: 4rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
  img {
    padding: 2em;
    height: 7.7em;
    ${responsive.xs.andSmaller`
      padding: 1em;
      height: 5em;
    `}
  }
  a {
    ${responsive.xs.andSmaller`
      margin-top: 1em;
    `}
  }
`;

export const Separator = styled.span`
  width: 1px;
  background: ${props => props.theme.text.default.string()};
  margin: 0em 0;
  flex: 0 1 6em;
  ${responsive.sm.andSmaller`
    flex: 0 1 2em;
  `};
`;

const Tint = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, #000 -12.16%, rgba(2, 2, 2, 0.36) 97.86%);
`;

const firstParagraph = text => {
  return text.split("\n\n")[0] + "..";
};

const Poster = ({
  image,
  title,
  text,
  action,
  style,
  titleImage,
  expand,
  clip
}) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  return (
    <Container style={style}>
      <Tint>
        <SkeletonImage
          src={image}
          alt={"poster"}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            mixBlendMode: "multiply"
          }}
        />
      </Tint>
      <Content expand={expand}>
        {titleImage ? (
          <img src={titleImage} alt={"caption graphics"} />
        ) : (
          <H2 style={{ marginTop: 0 }}>{title}</H2>
        )}
        {!isMobile && <Separator />}
        <HtmlContent content={clip ? firstParagraph(text) : text} />
        {action && (
          <RouterLinkButton
            flavor="default"
            lg={!isMobile}
            md={isMobile}
            to={action.to}
          >
            {action.text}
          </RouterLinkButton>
        )}
      </Content>
    </Container>
  );
};

export default Poster;
