import frontpage from "../../assets/images/intro-poster.jpg";
import { DISTRIBUTION_CATEGORY, MOVIE_CATEGORY } from "../../enums";
import shortMovies from "./shortMovies";
import publishing from "./publishing";
import long_movies from "./long_movies";
import compact from "lodash/compact";
import groupBy from "lodash/groupBy";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import about_page from "./about_page";
import news_press from "./news_press";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import first from "lodash/first";
import { urlForMedia } from "../../utils/path";
import distribution from "./distribution";
import production from "./production";
import { makeYearRange } from "../../utils/helpers";
import about_page_gallery from "./about_page_gallery";
import capitalize from "lodash/capitalize";

const transform = (val, fn) => (val ? fn(val) : val);

export default translate => ({
  homepage: {
    cover: {
      coverImage: frontpage,
      video: urlForMedia("intro.m4v"),
      poster: frontpage
    },
    featured: {
      section: translate("homepage.featured.section"),
      title: translate("homepage.featured.title"),
      name: translate("homepage.featured.name"),
      image: urlForMedia("homepage/featured-pk3.jpg"),
      // action: translate("homepage.featured.action"),
      // action2: translate("homepage.featured.action2"),
      // link: "https://www.youtube.com/watch?v=gDtyxfKyBx0&ab_channel=CentarFilm",
      readMoreLink: "/production/kreka-lovac-na-snove"
    },
    distribution: {
      title: translate("distribution.title"),
      items: distribution(translate),
      linkProvider: id => `/distribution/${id}`
    },
    stats: [
      { value: 259, description: translate("homepage.stats_movies") },
      { value: 377, description: translate("homepage.stats_festivals") },
      { value: 264, description: translate("homepage.stats_awards") },
      { value: 654, description: translate("homepage.stats_titles") }
    ],
    poster: {
      image: urlForMedia("homepage/kratki_about.jpg"),
      title: translate("homepage.poster.title"),
      text: translate("homepage.poster.text"),
      action: { text: translate("actions.find_out_more"), to: "/about" }
    },
    slideshowLinkProvider: id => `/production/${id}`,
    slideshow: long_movies(translate)
      .filter(_ => !isEmpty(_.slideshow))
      .map(item => ({
        ...item,
        image: get(item.slideshow, "[0].image"),
        thumbnail: get(item.slideshow, "[0].image")
      })),
    news: {
      linkProvider: id => `/news/${id}`,
      item: first(news_press(translate))
    }
  },
  about: {
    cover: {
      coverImage: urlForMedia("page-cover/about.jpg"),
      arrow: false,
      title: translate("about.title")
    },
    meta: {
      title: translate("about.title"),
      image: urlForMedia("page-cover/about.jpg")
    },
    sectionTitle: translate("about.sectionTitle"),
    sections: about_page(translate),
    slideshow: about_page_gallery(translate)
  },
  contact: {
    cover: {
      coverImage: urlForMedia("page-cover/contact.jpg"),
      arrow: false,
      title: translate("contact.title")
    },
    meta: {
      title: translate("contact.title"),
      image: urlForMedia("page-cover/contact.jpg")
    }
  },
  directors: {
    cover: {
      coverImage: urlForMedia("page-cover/directors.jpg"),
      title: translate("directors.title")
    },
    meta: {
      title: translate("directors.title"),
      image: urlForMedia("page-cover/directors.jpg")
    },
    quote: {
      text: translate("directors.quote.text"),
      author: translate("directors.quote.author")
    },
    items: orderBy(
      values(
        groupBy(
          long_movies(translate).filter(_ => _.director),
          "director"
        )
      ).map(movie => ({
        name: first(get(movie, "[0].director").split(",")),
        image:
          get(movie, "[0].slideshow[1].image") || get(movie, "[0].image_cover"),
        noImage: isEmpty(
          get(movie, "[0].slideshow[1].image") || get(movie, "[0].image_cover")
        )
      })),
      ["noImage", "name"]
    )
  },
  distribution: {
    cover: {
      coverImage: urlForMedia("page-cover/distribution.jpg"),
      title: translate("distribution.title")
    },
    meta: {
      title: translate("distribution.title"),
      image: urlForMedia("page-cover/distribution.jpg")
    },
    production: {
      title: translate("general.production"),
      linkProvider: id => `/distribution/${id}`,
      items: distribution(translate).filter(
        _ => _.category !== DISTRIBUTION_CATEGORY.ARCHIVE
      )
    },
    archive: {
      title: translate("general.archive"),
      items: orderBy(
        distribution(translate).filter(
          _ => _.category === DISTRIBUTION_CATEGORY.ARCHIVE
        ),
        "year",
        "desc"
      )
    }
  },
  production: {
    linkProvider: id => `/production/${id}`,
    cover: {
      coverImage: urlForMedia("page-cover/movies.jpg"),
      title: translate("movies.title")
    },
    meta: {
      title: translate("movies.title"),
      image: urlForMedia("page-cover/movies.jpg")
    },
    featured: {
      title: translate("movies.featured.title"),
      // abstract: translate("movies.featured.abstract"),
      items: long_movies(translate).filter(
        _ => _.category === MOVIE_CATEGORY.FEATURED
      )
    },
    documentary: {
      title: translate("movies.documentary.title"),
      // abstract: translate("movies.documentary.abstract"),
      items: long_movies(translate).filter(
        _ => _.category === MOVIE_CATEGORY.DOCUMENTARY
      )
    },
    archive: {
      title: translate("movies.archive.title"),
      // abstract: translate("movies.archive.abstract"),
      items: shortMovies(translate)
    },
    production: {
      title: translate("movies.production.title"),
      items: production(translate)
    }
  },
  movie: ({ id }) => {
    const item =
      distribution(translate).find(
        item => item.slug === id || item.id === id
      ) ||
      long_movies(translate).find(item => item.slug === id || item.id === id);

    return {
      cover: {
        coverImage: item.image_cover,
        arrow: false,
        title: item.title,
        sponsoredImage: item.sponsored_image,
        description: compact([
          item.title_original,
          item.season,
          makeYearRange(item.year, item.yearEnd),
          item.genre,
          item.episode,
          item.duration,
          item.country
        ]).join(" | "),
        flavor: "movie"
      },
      meta: {
        title: transform(item.title, val =>
          val
            .toLocaleLowerCase()
            .split(" ")
            .map(word => capitalize(word))
            .join(" ")
        ),
        description: item.description,
        image: item.image_cover
      },
      description: {
        image: item.image,
        title: item.title,
        awards: item.awards,
        crew: item.people
      },
      quote: {
        text: translate("movie.quote.text"),
        author: translate("movie.quote.author")
      },
      slideshow: item.slideshow,
      poster: {
        image: item.image_description,
        title: item.title,
        text: item.description,
        action: item.trailer_link
          ? { text: translate("actions.watch_trailer"), to: item.trailer_link }
          : undefined
      }
    };
  },
  publishing: {
    cover: {
      coverImage: urlForMedia("page-cover/publishing.jpg"),
      title: translate("publishing.title"),
      arrow: false
    },
    meta: {
      title: translate("publishing.title"),
      image: urlForMedia("page-cover/publishing.jpg")
    },
    quote: {
      text: translate("publishing.quote.text"),
      author: "Ranko Munitić"
    },
    items: publishing(translate)
  },
  news: {
    cover: {
      arrow: false,
      coverImage: urlForMedia("page-cover/news.jpg"),
      title: translate("news.title")
    },
    meta: {
      title: translate("news.title"),
      image: urlForMedia("page-cover/news.jpg")
    },
    linkProvider: id => `/news/${id}`,
    items: news_press(translate),
    slideshowLinkProvider: id => `/production/${id}`,
    slideshow: distribution(translate)
      .filter(_ => !isEmpty(_.slideshow))
      .map(item => ({ ...item, thumbnail: get(item.slideshow, "[0].image") }))
  },
  newsArticle: ({ id }) => {
    const news = news_press(translate).find(item => item.id === id);
    return {
      cover: {
        coverImage: require("../../assets/images/" + news.header_image),
        title: news.syllabus_title,
        description: news.syllabus
      },
      meta: {
        title: news.syllabus_title,
        description: news.syllabus
      },
      ...news
    };
  }
});
