import React from "react";
import styled from "styled-components";
import { Label, Paragraph } from "../typography";
import { defaultTo } from "lodash";
import responsive from "../../utils/responsive";

const Items = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  width: 100%;
  line-height: 1.5;
  align-items: start;
  flex: 1;

  ${responsive.sm.andSmaller` 
    flex-direction: column;
    font-size: 0.7em;
    line-height: 1.3;
    height: 100%;
  `}

  p {
    margin: 1.25rem 0;
    color: ${props => props.theme.text.emphasized.string()};
  }
  
  width: 100%;
  max-width: 65rem;
  padding: ${props => (props.isMobile ? "0 1rem" : "1rem 0.85rem")};
  z-index: 1
  
  flex: 1 0 auto;

  justify-content: center;
  display: flex;

`;

const People = styled.div`
  display: block;
`;

const Span = styled.span`
  label:last-child {
    color: ${props => props.theme.text.emphasized.string()};
  }
`;

const ItemLabel = styled(Label)`
  display: block;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 1em;
  flex: 1 0 30%;

  ${responsive.md.andSmaller`
    width: auto;
  `}

  ${responsive.sm.andSmaller`
    margin: 1rem 0;
  `}
`;

const PeopleItem = ({ role, members, isLast }) => (
  <Span>
    <Label style={{ textTransform: "uppercase" }}>{`${role} `}</Label>
    <Label>{isLast ? members : `${members}\u00A0|\u00A0`}</Label>
  </Span>
);

export default ({ year, title, description, people }) => (
  <Items>
    <ItemLabel>{`${title} | ${defaultTo(year, "")}`}</ItemLabel>
    <div style={{ flex: "0 1 100%" }}>
      <People>
        {people.map((item, i) => (
          <PeopleItem key={i} {...item} isLast={people.length - 1 === i} />
        ))}
      </People>
      <Paragraph>{description}</Paragraph>
    </div>
  </Items>
);
