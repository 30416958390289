import long_movies from "./long_movies";
import distribution from "./distribution";
import { DISTRIBUTION_CATEGORY } from "../../enums";

const long = translate =>
  long_movies(translate).map(item => ({
    id: item.slug || item.id,
    label: item.title,
    image: item.thumbnail || item.image,
    linkProvider: id => `/production/${id}`
  }));

const prodDist = translate =>
  distribution(translate)
    .filter(_ => _.category !== DISTRIBUTION_CATEGORY.ARCHIVE)
    .map(item => ({
      id: item.slug || item.id,
      label: item.title,
      image: item.thumbnail,
      linkProvider: id => `/distribution/${id}`
    }));

const archDist = translate =>
  distribution(translate)
    .filter(_ => _.category === DISTRIBUTION_CATEGORY.ARCHIVE)
    .map(item => ({
      id: item.slug || item.id,
      label: item.title,
      image: item.thumbnail,
      linkProvider: id => `/distribution`
    }));

export default translate => [
  ...long(translate),
  ...prodDist(translate),
  ...archDist(translate)
];
