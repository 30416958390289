import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    id: "pub_1",
    year: 2007,
    title: translate("pub_1_title"),
    image: urlForMedia("publishing/Krug.jpeg"),
    imageBack: urlForMedia("publishing/backside/Krug.jpeg"),
    author: "Ranko Munitić",
    description: translate("pub_1_desc"),
    publishers: translate("pub_1_publ")
  },
  {
    id: "pub_2",
    year: 2005,
    title: translate("pub_2_title"),
    image: urlForMedia("publishing/Adio.jpeg"),
    imageBack: urlForMedia("publishing/backside/Adio.jpeg"),
    author: "Ranko Munitić",
    description: translate("pub_2_desc"),
    publishers: translate("pub_2_publ")
  },
  {
    id: "pub_3",
    year: 2003,
    title: translate("pub_3_title"),
    image: urlForMedia("publishing/Izdavaštvo_Kino_klub_Beograd.jpeg"),
    imageBack: urlForMedia("publishing/backside/kino_klub.jpeg"),
    author: "Ranko Munitić",
    description: translate("pub_3_desc"),
    publishers: translate("pub_3_publ")
  },
  {
    id: "pub_4",
    year: 2002,
    title: translate("pub_4_title"),
    image: urlForMedia("publishing/Spicek.jpeg"),
    imageBack: urlForMedia("publishing/backside/Spicek.jpeg"),
    author: "Milan D. Špiček",
    description: translate("pub_4_desc"),
    publishers: translate("pub_4_publ")
  },
  {
    id: "pub_5",
    year: 2001,
    title: translate("pub_5_title"),
    image: urlForMedia("publishing/Markovic.jpeg"),
    imageBack: urlForMedia("publishing/backside/Markovic.jpeg"),
    author: "Ranko Munitić",
    description: translate("pub_5_desc"),
    publishers: translate("pub_5_publ")
  },
  {
    id: "pub_6",
    year: 2001,
    title: translate("pub_6_title"),
    image: urlForMedia("publishing/Kusturica.jpeg"),
    imageBack: urlForMedia("publishing/backside/Kusturica.jpeg"),
    author: "Ranko Munitić",
    description: translate("pub_6_desc"),
    publishers: translate("pub_6_publ")
  },
  {
    id: "pub_7",
    year: 2000,
    title: translate("pub_7_title"),
    image: urlForMedia("publishing/Karanovic.jpeg"),
    imageBack: urlForMedia("publishing/backside/Karanovic.jpeg"),
    author: "Ranko Munitić, Srđan Karanović",
    description: translate("pub_7_desc"),
    publishers: translate("pub_7_publ")
  },
  {
    id: "pub_8",
    year: 1999,
    title: translate("pub_8_title"),
    image: urlForMedia("publishing/Korac.jpeg"),
    imageBack: urlForMedia("publishing/backside/Korac.jpeg"),
    author: "Rade Šoškić",
    description: translate("pub_8_desc"),
    publishers: translate("pub_8_publ")
  },
  {
    id: "pub_9",
    year: 1998,
    title: translate("pub_9_title"),
    image: urlForMedia("publishing/Kamera.jpeg"),
    imageBack: urlForMedia("publishing/backside/Kamera.jpeg"),
    author: "Stevan Jovičić",
    description: translate("pub_9_desc"),
    publishers: translate("pub_9_publ")
  },
  {
    id: "pub_10",
    year: 1997,
    title: translate("pub_10_title"),
    image: urlForMedia("publishing/Paskaljevic.jpeg"),
    imageBack: urlForMedia("publishing/backside/Paskaljevic.jpeg"),
    author: "Ron Holloway",
    description: translate("pub_10_desc"),
    publishers: translate("pub_10_publ")
  },
  {
    id: "pub_11",
    year: 1997,
    title: translate("pub_11_title"),
    image: urlForMedia("publishing/Pavlovic.jpeg"),
    imageBack: urlForMedia("publishing/backside/Pavlovic.jpeg"),
    author:
      "Ranko Munitić, Borislav Radović, Nebojša Pajkić, Dinko Tucaković, Radoslav Lazić, Živojin Pavlović",
    description: translate("pub_11_desc"),
    publishers: translate("pub_11_publ")
  },
  {
    id: "pub_12",
    year: 1997,
    title: translate("pub_12_title"),
    image: urlForMedia("publishing/crna.jpg"),
    imageBack: urlForMedia("publishing/backside/crna.jpg"),
    author: "Vasa Kazimirović",
    description: translate("pub_12_desc"),
    publishers: translate("pub_12_publ")
  },
  {
    id: "pub_13",
    year: 1995,
    title: translate("pub_13_title"),
    image: urlForMedia("publishing/general.jpg"),
    imageBack: urlForMedia("publishing/backside/general.jpg"),
    author: "Vasa Kazimirović",
    description: translate("pub_13_desc"),
    publishers: translate("pub_13_publ")
  },
  {
    id: "pub_14",
    year: 1995,
    title: translate("pub_14_title"),
    image: urlForMedia("publishing/komplet.jpg"),
    imageBack: urlForMedia("publishing/backside/komplet.jpg"),
    author: "Vasa Kazimirović",
    description: translate("pub_14_desc"),
    publishers: translate("pub_14_publ")
  },
  {
    id: "pub_15",
    year: 1976,
    title: translate("pub_15_title"),
    image: urlForMedia("publishing/Sazrevanje.jpg"),
    imageBack: urlForMedia("publishing/backside/Sazrevanje.jpeg"),
    author: "Petar Volk",
    description: translate("pub_15_desc"),
    publishers: translate("pub_15_publ")
  }
];
