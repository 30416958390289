import React from "react";
import Icon from "./Icon";
import badgeOpen from "../assets/awards/open.svg";
import badgeClose from "../assets/awards/close.svg";
import styled from "styled-components";
import responsive from "../utils/responsive";
import { AWARD, AWARD_ICONS, AWARD_LABELS } from "../enums";
import capitalize from "lodash/capitalize";
import { upperCase } from "lodash/string";
import { defaultTo } from "lodash/util";
import trim from "lodash/trim";
import compact from "lodash/compact";

export const Group = styled.tbody`
  .separator {
    height: 0.3em;
    border-top: 1px solid ${props => props.theme.border.color.default.string()};
  }
`;

export const Title = styled.td`
  text-transform: uppercase;
  color: ${props => props.theme.text.emphasized.string()};
  text-align: right;
  padding: 0.3em;
  vertical-align: top;
  font-weight: 600;
  ${responsive.sm.andSmaller`
    color: ${props => props.theme.background.contrast.string()};
    white-space: wrap;
    text-align: left;
    font-size: 0.625rem !important;
  `}
`;

export const Content = styled.td`
  text-transform: uppercase;
  color: ${props => props.theme.text.default.string()};
  padding: 0.3em;
  padding-left: 2em;
  font-weight: 600;

  ${responsive.sm.andSmaller`
    color: #A18D60;
    padding-left: 0.3em;
    text-align: right;
    font-size: 0.6875rem !important;
  `}
`;

export const Row = styled.tr`
  * {
    font-size: ${props => props.theme.textSize.s};
    ${responsive.xs.andSmaller`
        font-size: ${props => props.theme.textSize.xs};
    `};
  }
`;

const AwardLabel = styled.label`
  white-space: normal;
  font-size: 0.75rem;
  ${responsive.xs.andSmaller`
    font-size: 0.5rem;
  `}
  text-align: center;
`;

const AwardLabelEmph = styled(AwardLabel)`
  font-size: 0.75rem;
  text-align: center;
  color: ${props => props.theme.text.default.string()};
`;

const AwardContainer = styled.span`
  display: flex;
  margin: 0 0.5em;
  align-items: center;
  font-size: 0.75rem;
  margin-top: 1rem;
  max-width: 20em;
  ${responsive.xs.andSmaller`
    flex: 0 1 100%;
    font-size: 0.4rem;
  `}
`;

const AwardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  ${responsive.xs.andSmaller`
  `}
`;

const Info = ({ title, Component }) => (
  <AwardInfo>
    {compact(defaultTo(title, "").split(";")).map((line, i) => (
      <Component key={i}>{trim(line)}</Component>
    ))}
  </AwardInfo>
);

const AwardLesser = ({ title, isMobile }) => (
  <AwardContainer>
    <Icon style={{ boxShadow: "none", height: isMobile ? "2.8rem" : "3.5rem" }}>
      {badgeOpen}
    </Icon>
    <Info title={title} Component={AwardLabel} />
    <Icon style={{ boxShadow: "none", height: isMobile ? "2.8rem" : "3.5rem" }}>
      {badgeClose}
    </Icon>
  </AwardContainer>
);

const iconHeight = name => {
  if ([AWARD.CANNES, AWARD.CANNES2, AWARD.CANNES3].includes(name)) {
    return "3rem";
  } else if (name === AWARD.QUINZAINE) {
    return "4rem";
  } else {
    return "2rem";
  }
};

const iconHeightMobile = name => {
  if ([AWARD.CANNES, AWARD.CANNES2, AWARD.CANNES3].includes(name)) {
    return "2.2rem";
  } else if (name === AWARD.QUINZAINE) {
    return "3rem";
  } else {
    return "1.9rem";
  }
};

const AwardGreater = ({ title, count, outcome, name, isMobile }) => (
  <AwardContainer>
    <Icon
      style={{
        boxShadow: "none",
        height: isMobile ? iconHeightMobile(name) : iconHeight(name)
      }}
    >
      {AWARD_ICONS[name]}
    </Icon>
    {![AWARD.QUINZAINE, AWARD.CANNES2].includes(name) &&
      (title ? (
        <Info title={title} Component={AwardLabelEmph} />
      ) : (
        <AwardInfo>
          <AwardLabelEmph>
            {`${defaultTo(count, "")} ${upperCase(AWARD_LABELS[name])}`}
          </AwardLabelEmph>
          <AwardLabelEmph>{capitalize(outcome)}</AwardLabelEmph>
        </AwardInfo>
      ))}
  </AwardContainer>
);

export const Award = ({ award, isMobile }) => {
  const Component =
    award && award.name !== AWARD.OTHER && AWARD_ICONS[award.name]
      ? AwardGreater
      : AwardLesser;
  return <Component {...award} isMobile={isMobile} />;
};

const Awards = ({ awards, isMobile }) =>
  awards && awards.length ? (
    <Group style={{ marginTop: isMobile ? "1em" : "2em", width: "100%" }}>
      <Row>
        <Content
          style={{
            textTransform: "none",
            color: "unset",
            fontWeight: "unset",
            display: "flex",
            justifyContent: isMobile ? "space-around" : "start",
            fontSize: "0.3em",
            flexWrap: "wrap",
            flex: "0 1 auto"
          }}
        >
          {awards.map((award, i) => (
            <Award key={i} award={award} isMobile={isMobile} />
          ))}
        </Content>
      </Row>
    </Group>
  ) : null;

export default Awards;
