import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import responsive from "../../utils/responsive";
import { theme } from "../../theme";

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#2E2E2E"
    strokeLinecap="round"
    {...props}
  />
);

const Button = styled.button`
  position: absolute;
  right: 2rem;
  top: 2rem;
  ${responsive.sm.andSmaller`
    right: 0.5rem;
    top: 1.1rem;
    transform: scale(0.9);
  `}
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  pointer-events: all;
  background: transparent;
  > svg {
    outline: none;
    user-select: none;
  }
`;

export const MenuToggle = ({ toggle }) => (
  <Button onClick={toggle}>
    <svg width="30" height="30" viewBox="0 0 30 30">
      <Path
        variants={{
          closed: {
            d: "M 2 2.5 L 30 2.5",
            stroke: theme.colors.primary.string()
          },
          open: {
            d: "M 3 16.5 L 17 2.5",
            stroke: theme.colors.primary.string()
          }
        }}
      />
      <Path
        d="M 2 9.423 L 30 9.423"
        variants={{
          closed: { opacity: 1, stroke: theme.colors.primary.string() },
          open: { opacity: 0, stroke: theme.colors.primary.string() }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: {
            d: "M 2 16.346 L 30 16.346",
            stroke: theme.colors.primary.string()
          },
          open: {
            d: "M 3 2.5 L 17 16.346",
            stroke: theme.colors.primary.string()
          }
        }}
      />
    </svg>
  </Button>
);
