import React, { Fragment } from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import Logo, { CFLogo } from "./Logo";
import styled from "styled-components";
import { theme } from "../../theme";
import responsive, { breakpoint } from "../../utils/responsive";
import Navigation from "./Navigation";
import SocialShare from "../social/SocialShare";
import { useMediaQuery } from "react-responsive/src";

const nav = {
  open: ({ isMobile }) => ({
    pointerEvents: "all",
    background: theme.colors.yetAnotherRed.string(),
    width: isMobile ? "13rem" : "21.125rem",
    opacity: 1,
    transition: {
      stiffness: 30,
      velocity: 5
    }
  }),
  closed: {
    pointerEvents: "none",
    width: "4rem",
    opacity: 1,
    background: theme.colors.yetAnotherRed.alpha(0).string(),
    transition: {
      delay: 0.4,
      stiffness: 400,
      damping: 40
    }
  }
};

const share = {
  open: ({ isMobile }) => ({
    height: "6em",
    top: "auto",
    marginLeft: isMobile ? "4.5rem" : "7rem",
    pointerEvents: "all",
    opacity: 1,
    translateY: 0,
    zIndex: isMobile ? 1 : undefined
  }),
  closed: ({ isMobile }) => ({
    height: "100%",
    marginLeft: "-0.45rem",
    top: "4em",
    opacity: isMobile ? 0 : 1,
    pointerEvents: isMobile ? "none" : "all",
    alignItems: "start",
    zIndex: -1,
    display: isMobile ? "none" : undefined,
    transition: {
      delay: 0.3,
      marginLeft: {
        delay: 0.4
      }
    }
  })
};

const toggle = {
  open: {
    backgroundColor: theme.colors.yetAnotherRed.string(),
    right: 0,
    left: "auto",
    borderTop: `4px solid ${theme.colors.primary.string()}`
  },
  closed: {
    backgroundColor: theme.colors.yetAnotherRed.alpha(0).string(),
    borderTop: `0 solid ${theme.colors.primary.string()}`,
    transition: {
      stiffness: 30,
      delay: 0.4
    }
  }
};

const Header = styled(motion.div)`
  display: flex;
  background: ${theme.colors.primary.string()};
  align-items: center;
  padding-left: 4.75rem;
  height: 4rem;
  flex: 1 0 auto;
  max-height: 4rem;
  right: 0;
  
  ${responsive.md.andSmaller`
    height: 3.5rem;
    max-height: 3.5rem;
  `};
  
  ${responsive.sm.andSmaller`
    padding-left: 2rem;
    margin-bottom: 2rem;
  `}
  border-top: 4px solid ${props => props.theme.text.default.string()};
`;

const Nav = styled(motion.nav)`
  position: fixed;
  top: 0;
  right: 0;

  width: 21.125rem;
  max-width: 70%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  background: ${theme.colors.yetAnotherRed.string()};
  height: 100%;
`;

const Buttons = styled(motion.div)`
  display: flex;
  position: absolute;
  bottom: 1rem;
  align-items: center;
  margin-left: 7rem;
  margin-right: auto;
  margin-top: 1rem;
  > * {
    margin-bottom: 1em;
  }
  ${responsive.sm.andSmaller`
    margin-top: auto;
    // margin-bottom: 1em;
  `}
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  width: 100%;
  z-index: 0;
  background: ${theme.colors.yetAnotherRed.string()};
  height: 100vh;
`;

export const Navbar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  return (
    <Fragment>
      {!isOpen && <CFLogo style={{ position: "fixed" }} />}
      <Nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        ref={containerRef}
        variants={nav}
        custom={{ isMobile }}
      >
        <Header variants={toggle} custom={{ isMobile }}>
          <MenuToggle toggle={() => toggleOpen()} />
          <Logo onClick={toggleOpen} />
        </Header>

        <Navigation onClick={toggleOpen} />
        <Buttons variants={share} custom={{ isMobile }}>
          {isOpen && isMobile && <Backdrop />}
          <SocialShare isOpen={isOpen} />
        </Buttons>
      </Nav>
    </Fragment>
  );
};
