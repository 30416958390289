import React from 'react';
import last from "lodash/last";
import Link from "./NavLink";

const BookmarkLink = ({bookmark, ...props}) => {
  const onClick = () => {
    const hash = last(props.to.split('/'));
    window.history.replaceState(undefined, undefined, `#${hash}`);
  }

  return <Link {...props} onClick={onClick} />
}

export default BookmarkLink;
