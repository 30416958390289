import React, { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import Skeleton from "./Skeleton";
import placeholder from "../assets/placeholder.jpg";

const Image = styled.img`
  display: block;
  opacity: 1;
  width: ${props => props.width};
  height: ${props => props.height};
  object-fit: cover;
  object-position: center center;

  ${props =>
    !props.visible &&
    css`
      display: none !important;
      opacity: 0;
    `};

  transition: opacity 0.5s ease;

  ${props =>
    props.grayscale &&
    css`
      filter: grayscale(100%);
    `};
`;

export default ({
  height = "100%",
  width = "100%",
  src = placeholder,
  alt,
  style,
  fullscreen,
  color,
  grayscale,
  animate,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Fragment>
      <Image
        src={src}
        alt={alt}
        style={style}
        onLoad={() => setLoaded(true)}
        visible={loaded}
        height={height}
        width={width}
        grayscale={grayscale}
        {...rest}
      />
      {!loaded && (
        <Skeleton
          width={width}
          height={height}
          fullscreen={fullscreen}
          color={color}
          animate={animate}
          style={style}
          {...rest}
        />
      )}
    </Fragment>
  );
};
