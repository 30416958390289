import React, { Fragment } from "react";
import styled from "styled-components";
import SkeletonImage from "../components/SkeletonImage";
import { H4 } from "../components/typography";
import responsive from "../utils/responsive";
import { useMediaQuery } from "react-responsive/src";
import Paper from "../components/containers/Paper";
import { theme } from "../theme";
import Gallery from "../components/containers/Gallery";
import HtmlContent from "../components/HtmlContent";

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${responsive.sm.andSmaller`
    grid-template-columns: 1fr;
  `}

  img {
    min-height: 10.875rem;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 4.5em;

  ${responsive.lg.andSmaller`
    padding: 2em 2em;
  `}

  > h4 {
    font-size: 1.4rem;
    ${responsive.xs.andSmaller`
      font-size: 1rem;
  `}
  }
`;

const TextCard = ({ title, body }) => (
  <Card>
    <H4 style={{ color: theme.colors.vibrant.string() }}>{title}</H4>
    <HtmlContent
      size={"ml"}
      style={{ color: theme.colors.dark.string(), textAlign: "justify" }}
      content={body}
    />
  </Card>
);

const Padding = styled.div`
  padding-bottom: 6em;
  ${responsive.sm.andSmaller`
    padding-bottom: 0;
  `}
  > :first-child {
    margin: 7rem 0 3rem 0;
    ${responsive.sm.andSmaller`
      margin: 0 0;
    `}
  }
`;

export default ({ sectionTitle, sections = [], slideshow, isDesktop }) => {
  const isMobile = useMediaQuery({ query: "(max-device-width: 576px)" });

  return (
    <Padding>
      <Paper title={sectionTitle} emblem={isDesktop} cfLogo={true} />
      <Content>
        {sections.map(({ image, text, title }, id) =>
          id % 2 === 0 || isMobile ? (
            <Fragment key={id}>
              <SkeletonImage src={image} />
              <TextCard title={title} body={text} />
            </Fragment>
          ) : (
            <Fragment key={id}>
              <TextCard title={title} body={text} />
              <SkeletonImage src={image} />
            </Fragment>
          )
        )}
      </Content>
      <Paper noMargin={isMobile}>
        {slideshow && slideshow.length > 0 && <Gallery items={slideshow} />}
      </Paper>
    </Padding>
  );
};
