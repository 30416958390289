import React, { Fragment } from "react";
import PublishingItem from "../components/items/PublishingItem";
import Quote from "../components/Quote";
import ItemGrid from "../components/containers/ItemGrid";
import Paper from "../components/containers/Paper";

export default ({ items, quote, isMobile }) => (
  <Fragment>
    <Quote {...quote} />
    <Paper noMargin={!isMobile} noSpace>
      <ItemGrid
        Component={PublishingItem}
        items={items}
        itemWidth={isMobile ? 347 : 528}
        showAll={true}
        maxPerRow={2}
      />
    </Paper>
  </Fragment>
);
