import React, { useEffect } from "react";
import styled from "styled-components";
import frontpage from "../../assets/images/frontpage.jpg";
import responsive, { breakpoint } from "../../utils/responsive";
import Ribbon from "../Ribbon";
import FadeInVideo from "../FadeInVideo";
import SkeletonImage from "../SkeletonImage";
import { VerticalContainer } from "../Layout";
import { H1, Paragraph } from "../typography";
import { LanguageContext } from "../LanguageContext";
import HelmetProvider from "../HelmetProvider";
import { useMediaQuery } from "react-responsive/src";
import { CenteredEmblem } from "../Icon";

const ArrowSkeletonImage = styled(SkeletonImage)`
  background: ${props =>
    props.maroon
      ? props.theme.background.dark.string()
      : props.theme.background.contrast.string()};
  // filter: brightness(60%);
  mix-blend-mode: multiply;
  
  height: 100vh;
  max-height: 67.5rem;
  
  ${responsive.lg.andSmaller`
    min-height: 0;
    height: 36.5rem;
  `}

  ${responsive.md.andSmaller`
    min-height: 0;
    height: 25.5rem;
  `}

  ${responsive.xs.andSmaller`
    min-height: 0;
    height: 14.5rem;
  `}
  object-position: top center;
`;

const Paper = styled.div`
  background: ${props => props.theme.background.default.string()};
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

const Description = styled(VerticalContainer)`
  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;
  z-index: 11;
  align-items: center;
  text-align: center;
  padding: 1em 4em;

  ${responsive.md.andSmaller`
    bottom: 0;
  `}

  ${responsive.md.andSmaller`
    padding: 1em 2em;
  `}

  h1 {
    line-height: 1;
    font-weight: 600;
    font-size: 3.3vw;
    ${responsive.md.andSmaller`
      font-weight: 500;
    `}

    ${responsive.xs.andSmaller`
    `}
    
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: ${props => props.theme.text.light.string()};
    font-size: 1.2em;
    text-align: justify;

    @media (min-width: 1600px) {
      font-size: 1.5em;
    }

    ${responsive.sm.andSmaller`
      width: 100%
    `};

    ${responsive.xs.andSmaller`
      font-size: 0.625rem;
    `}
  }
`;

const Sponsored = styled.img`
  position: absolute;
  height: 2.5em;
  right: 0;
  left: auto;
  bottom: 1em;
  opacity: 0.95;

  ${responsive.lg.andSmaller`
    height: auto;
    width: 25%;
    max-width: 12em;
  `};

  ${responsive.md.andSmaller`
    width: 20%;
  `};
`;

const Separator = styled.span`
  position: relative;
  width: 90%;
  height: 2px;
  background: ${props => props.theme.text.default.string()};
  margin: 2em 0;
  ${responsive.md.andSmaller`
    height: 1px;
    margin: 1em 0;
  `}
`;

const Wrap = styled.div`
  position: relative;
  height: 100%;

  background: linear-gradient(
    0deg,
    #000000c7 -12.16%,
    rgba(37, 35, 31, 0) 97.86%
  );
`;

const Line = styled.div`
  width: 100%;
  height: 4px;
  background: ${props => props.theme.colors.primary.string()};
  z-index: 21;
  position: relative;
`;

const Body = styled.div`
  position: relative;
  > div + div {
    margin-top: 9rem;
    ${responsive.md.andSmaller`
      margin-top: 3rem;
    `}
  }

  > section + *:not(section):not(span) {
    margin-top: 9rem;
    ${responsive.md.andSmaller`
      margin-top: 3rem;
    `}
  }
  > * + .margin {
    margin-top: 9rem;
    ${responsive.md.andSmaller`
      margin-top: 3rem;
    `}
  }
`;

const Page = ({
  cover,
  meta,
  Component,
  contentService,
  location,
  ...rest
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
      // behavior: 'smooth'
    });
  }, []);

  const {
    coverImage,
    poster,
    video,
    grayscale,
    title,
    description,
    sponsoredImage
  } = cover || {};
  const isDesktop = useMediaQuery({ minWidth: breakpoint.lg });
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });

  return (
    <HelmetProvider meta={meta}>
      <Paper>
        <Wrap>
          <Line />
          {video ? (
            <FadeInVideo video={video} poster={poster} cover={coverImage} />
          ) : (
            <ArrowSkeletonImage
              src={coverImage || frontpage}
              fullscreen={true}
              color={"#333333"}
              grayscale={grayscale}
            />
          )}
          <Description>
            {title && <H1 style={{ textAlign: "center" }}>{title}</H1>}
            {title && (
              <Separator>
                {sponsoredImage && (
                  <Sponsored src={sponsoredImage} alt={"sponsored by"} />
                )}
              </Separator>
            )}
            <Paragraph size={"l"} style={{ textAlign: "center" }}>{description || `\u00A0`}</Paragraph>
          </Description>
        </Wrap>
        {isDesktop && <Ribbon />}

        <Body>
          <Component
            contentService={contentService}
            {...rest}
            isMobile={isMobile}
            isDesktop={isDesktop}
          />
          <CenteredEmblem />
        </Body>
      </Paper>
    </HelmetProvider>
  );
};

const PageContext = ({ pageKey, id, ...props }) => (
  <LanguageContext.Consumer>
    {({ contentService }) => (
      <Page
        {...props}
        {...contentService.page(pageKey, { id })}
        translate={contentService.translate}
        contentService={contentService}
      />
    )}
  </LanguageContext.Consumer>
);

export default PageContext;
