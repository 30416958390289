import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Paper from "../components/containers/Paper";
import PosterItem from "../components/items/PosterItem";
import Abstract from "../components/Abstract";
import ItemGrid from "../components/containers/ItemGrid";
import Float from "../components/Float";
import floatImage from "../assets/images/ko-to-tamo-scenario-1.png";
import FilmProduction from "../components/FilmProduction";
import ArchiveSection from "../components/ArchiveSection";
import responsive, { Desktop } from "../utils/responsive";
import { DOCUMENTARY_TYPES } from "../enums";
import { ButtonLink } from "../components/Button";
import { Group, optionalFilterBy } from "./Distribution";
import SectionTitle from "../components/SectionTitle";
import { colors } from "../theme";

const FilterGroup = styled(Group)`
  margin: 0 0 1.55rem 9rem;

  ${responsive.md.andSmaller`
    padding: 0;
    margin: 0 0 1.55rem 0;
  `}

  a {
    font-size: 1.5rem;
    margin-bottom: 0.875em;
    color: ${props => props.theme.text.default.string()};

    ${responsive.md.andSmaller`
      font-size: ${props => props.theme.textSize.md};
    `}

    ${responsive.sm.andSmaller`
      font-size: ${props => props.theme.textSize.s};
    `}
    text-transform: uppercase;
  }
  * {
    padding: 0em 1em;
    font-weight: 400;
  }
  *:not(:first-child) {
    border-left: 1px solid ${props => props.theme.text.default.string()};
  }
`;

const Wrapper = styled.span`
  margin-top: -8em;
  ${responsive.md.andSmaller`
    margin-top: -2.9em;
  `}
  ${responsive.sm.andSmaller`
    margin-top: -2.4em;
  `}
`;

const Filter = ({ items, translate, children }) => {
  const [currentType, setType] = useState();
  const filtered = optionalFilterBy(items, "documentary_type", currentType);

  return (
    <Wrapper>
      <FilterGroup>
        {DOCUMENTARY_TYPES.map(type => (
          <ButtonLink
            style={{
              color:
                type === currentType || !currentType
                  ? colors.vibrant.string()
                  : colors.navigation.string()
            }}
            onClick={() =>
              type === currentType ? setType(undefined) : setType(type)
            }
            key={type}
          >
            {translate(`DOCUMENTARY_TYPE.${type}`)}
          </ButtonLink>
        ))}
      </FilterGroup>
      {children(filtered)}
    </Wrapper>
  );
};

export default ({
  featured,
  documentary,
  linkProvider,
  contentService,
  archive,
  production,
  isMobile,
  translate,
}) => (
  <Fragment>
    <FilmProduction {...production} isMobile={isMobile} />
    <Paper title={featured.title} id={"featured"} noMargin>
      {featured.abstract && <Abstract>{featured.abstract}</Abstract>}
      <ItemGrid
        initialRows={isMobile ? 2 : 4}
        items={featured.items}
        Component={PosterItem}
        linkProvider={linkProvider}
        itemWidth={isMobile ? 40 : 243}
      />
    </Paper>
    <SectionTitle minWidth={"90%"} minWidthLg={"45rem"} />
    <Paper id={"documentary"} noMargin>
      <Desktop>
        <Float src={floatImage} style={{ top: "-10em" }} />
      </Desktop>
      {documentary.abstract && <Abstract>{documentary.abstract}</Abstract>}
      <Filter items={documentary.items} translate={translate}>
        {items => (
          <ItemGrid
            initialRows={isMobile ? 2 : 4}
            items={items}
            Component={PosterItem}
            // linkProvider={linkProvider}
            itemWidth={isMobile ? 40 : 243}
          />
        )}
      </Filter>
    </Paper>
    <ArchiveSection
      archive={archive}
      contentService={contentService}
      isMobile={isMobile}
    />
  </Fragment>
);
