import React, { useState } from "react";
import Select from "react-select";
import styled, { keyframes } from "styled-components";
import { theme } from "../theme";
import magnifier from "../assets/internal/magnifier.svg";
import Icon from "./Icon";
import SkeletonImage from "./SkeletonImage";
import { navigate } from "@reach/router";
import { withLanguage } from "./LanguageContext";
import responsive, { breakpoint } from "../utils/responsive";
import { useMediaQuery } from "react-responsive/src";
import { htmlContentStyles } from "./typography";
import ReactHtmlParser from "react-html-parser";
const backgroundColor = theme.colors.midnight.alpha(0.8).string();

const customStyles = {
  option: provided => ({
    ...provided,
    padding: 20,
    outline: "none",
    background: backgroundColor,
    color: theme.colors.primary.string()
  }),
  control: own => ({
    ...own,
    background: backgroundColor,
    color: theme.colors.primary.string(),
    border: "none",
    boxShadow: "none",
    "&:hover, &:focus": {
      borderColor: theme.border.color.focus.string(),
      outline: "none"
    },
    height: "2.626em"
  }),
  input: onw => ({
    ...onw,
    color: theme.colors.primary.string(),
    fontWeight: 800
  }),
  singleValue: provided => ({
    ...provided,
    transition: "opacity 200ms",
    color: theme.colors.primary.string(),
    fontWeight: 800
  }),
  placeholder: own => ({
    ...own,
    color: theme.colors.primary.string()
  }),
  clearIndicator: own => ({
    ...own,
    color: theme.colors.primary.string(),
    "&:hover, &:focus": {
      color: theme.colors.primary.string()
    }
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: theme.border.color.default.string()
  }),
  menu: base => ({
    ...base,
    background: "none",
    padding: 0,
    border: "none",
    boxShadow: "none"
  }),
  noOptionsMessage: base => ({
    ...base,
    background: backgroundColor,
    color: theme.colors.primary.string(),
    padding: "1em"
  })
};

const MagnifyingGlass = props => (
  <Icon
    {...props}
    style={{
      padding: "0.5em",
      width: "2.5em",
      height: "2.5em",
      color: theme.colors.primary.string(),
      filter: "saturate(10) hue-rotate(-11deg) contrast(0.7)",
      ...(props.style || {})
    }}
  >
    {magnifier}
  </Icon>
);

const HtmlLabel = styled.label`
  ${htmlContentStyles}
`;

const OptionContainer = styled.div`
  background: ${backgroundColor};
  color: ${theme.colors.primary.string()};
  padding: 0.5em;
  label {
    color: ${theme.colors.primary.string()};
    font-size: 1em;
    padding: 0.5em;
    margin-left: 0.5em;
  }
  :hover {
    background: ${theme.colors.lighter.alpha(0.2).string()};
  }
  display: flex;
  align-items: center;
`;

const Option = ({ innerProps, data }) => (
  <OptionContainer {...innerProps}>
    <SkeletonImage
      src={data.thumbnail || data.image}
      alt={data.label}
      width={"3em"}
      height={"4em"}
      style={{ maxWidth: "3em" }}
    />
    <HtmlLabel>{ReactHtmlParser(data.label)}</HtmlLabel>
  </OptionContainer>
);

const rollIn = keyframes`
  0% { opacity: 0; width: 30% }
  100% { opacity: 1; width: 100%; }
`;

const rollOut = keyframes`
  0% { opacity: 1; width: 100% }
  100% { opacity: 0; width: 30%; }
`;

const SearchBox = styled.div`
  position: fixed;
  right: 4.5em;
  top: 1.4em;
  // ${responsive.sm.andSmaller`
    top: 0.8em;
    right: 2.7em;
    font-size: 0.8em;
  // `}
  z-index: 20;
  margin-right: 1em;
  width: 16em;
  .opened {
    animation: ${rollIn} 0.3s;
    width: 100%;
    opacity: 1;
  }
  .closed {
    animation: ${rollOut} 0.3s;
    width: 0;
    opacity: 0;
    pointer-events: none;
  }
`;

const Inner = styled.div`
  position: absolute;
  right: 0;
`;

const Search = ({
  contentService: { search, translate },
  onClose,
  className
}) => {
  const onSelect = val => {
    if (val && val.linkProvider && val.id) {
      navigate(val.linkProvider(val.slug || val.id));
      onClose();
    }
  };

  return (
    <Inner className={className}>
      <Select
        autoFocus={true}
        isClearable={true}
        styles={customStyles}
        noOptionsMessage={() => translate("search.no_options")}
        loadingMessage={() => translate("search.loading")}
        placeholder={
          className === "opened" ? translate("search.placeholder") : null
        }
        components={{
          DropdownIndicator: MagnifyingGlass,
          Option,
          IndicatorSeparator: () => null
        }}
        options={search}
        onChange={onSelect}
      />
    </Inner>
  );
};

const ToggleableSearch = props => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  return (
    <SearchBox style={props.style}>
      <Search
        {...props}
        visible={open}
        onClose={() => setOpen(false)}
        className={!open ? "closed" : "opened"}
      />
      <MagnifyingGlass
        onClick={() => setOpen(!open)}
        style={{
          position: "absolute",
          top: isMobile ? "3px" : "1px",
          right: 0
        }}
      />
    </SearchBox>
  );
};

export default withLanguage(ToggleableSearch);
