import styled, { css } from "styled-components";
import withProps from "../utils/withProps";

const Float = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props =>
    props.left &&
    css`
      right: auto;
    `}

  ${props =>
    props.right &&
    css`
      left: auto;
    `}
`;

export default withProps({ alt: "Decoration scenario" })(Float);
