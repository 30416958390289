import React, { Fragment } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import logo from "../../assets/internal/logo.png";
import Link from "../navigation/NavLink";
import responsive, { breakpoint } from "../../utils/responsive";
import { useMediaQuery } from "react-responsive/src";
import Icon from "../Icon";

const variants = {
  open: {
    opacity: 0.8,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  },
  closed: {
    opacity: 0
  }
};

export const CFLogo = ({ style }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  return (
    <Fragment>
      <Link
        to={"/"}
        style={{
          position: "absolute",
          top: isMobile ? "1rem" : "2rem",
          left: isMobile ? "1rem" : "2rem",
          zIndex: 20,
          ...style
        }}
      >
        <Icon style={{ width: isMobile ? "2.35rem" : "5rem" }}>{logo}</Icon>
      </Link>
    </Fragment>
  );
};

const Logo = styled(motion.img)`
  object-fit: contain;
  height: 100%;
  margin-top: 3.3rem;
  opacity: 0.8;
  width: 4.8125rem;
  ${responsive.sm.andSmaller`
    width: 2.35rem
    margin-top: 0.7rem;
  `}
`;

export default ({ style, onClick }) => (
  <Link to={"/"} style={style} onClick={onClick}>
    <Logo variants={variants} src={logo} alt={"Centar Ffilm Logo"} />
  </Link>
);
