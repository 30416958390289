import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import defaultTo from "lodash/defaultTo";
const transform = (val, fn) => (val ? fn(val) : val);

const HelmetProvider = ({ meta, children }) => {
  const title = [get(meta, "title"), "Centar Film"]
    .filter(_ => !!_)
    .join(" | ");
  const description = defaultTo(
    transform(get(meta, "description"), val =>
      val.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 200)
    ),
    "Centar Film je jedna od vodećih producentskih i distributerskih kuća bivše Jugoslavije osnovana 1966. godine u Beogradu. Nekadašnje je žarište produkcije jugoslovenske kinematografije i susretište autora slobodnog filmskog stvaralaštva, iz kog su potekli kultni filmski klasici."
  );
  const image = get(
    meta,
    "image",
    // "https://assets.centarfilm.com/media/meta-share.jpg"
    // "https://assets.centarfilm.com/media/news-press/sloboda/sloboda_1.png"
    "https://assets.centarfilm.com/media/news-press/merima/merima_1.jpeg"
  );
  return (
    <Fragment>
      <Helmet>
        {title && <title>{title}</title>}
        {image && <meta property="og:image" content={image} />}
        {image && <meta name="twitter:image" content={image} />}

        {title && <meta property="og:title" content={title} />}
        {title && <meta name="twitter:title" content={title} />}

        {description && (
          <meta property="og:description" content={description} />
        )}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {description && <meta name="description" content={description} />}
      </Helmet>
      {children}
    </Fragment>
  );
};

export default HelmetProvider;
