import React from "react";
import styled from "styled-components";
import { buttonVariants } from "../social/SocialButton";
import { motion } from "framer-motion";
import { LANGUAGE_ICONS } from "../LanguageContext";

const Icon = styled(motion.img)`
  width: 34px;
  height: 34px;

  font-size: 1rem;
  padding: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const LangButton = ({ lang, still, onClick }) => (
  <Icon
    variants={buttonVariants}
    whileHover={!still ? { scale: 1.1 } : undefined}
    whileTap={!still ? { scale: 0.95 } : undefined}
    onClick={onClick}
    src={LANGUAGE_ICONS[lang]}
  />
);

export default LangButton;
