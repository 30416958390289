import React from "react";
import styled, { css } from "styled-components";
import SkeletonImage from "../SkeletonImage";
import { first } from "lodash/array";
import { H4, Label, Paragraph } from "../typography";
import { LanguageContext } from "../LanguageContext";
import Link from "../navigation/NavLink";
import responsive from "../../utils/responsive";
import BookmarkLink from "../navigation/BookmarkLink";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  ${props =>
    props.blend &&
    css`
      justify-content: center;
    `}
  
  ${props =>
    !props.blend &&
    css`
      border-bottom: 6px solid ${props => props.theme.text.default.string()};
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
    `}
  ${responsive.md.andLarger`
    ${props =>
      props.blend &&
      `
      flex-direction: row;
      img:not(.loading) {
        width: 50%;
        min-height: 30rem;
      }
      div {
        max-width: 50%;
      }
    `}
  `}
  z-index: 1;
`;

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2em 6em 2em 2em;
  ${responsive.sm.andSmaller`
    padding: 2em 2em;
    ${props =>
      props.blend &&
      css`
        padding-bottom: 0;
      `}
  `}
  
  ${props =>
    props.blend &&
    css`
      justify-content: center;
      p {
        flex: 0 1 auto;
      }
      a {
        align-self: flex-start;
        padding-top: 1em;
        border-top: 2px solid
          ${props => props.theme.border.color.default.string()};
      }
    `}

  ${props =>
    !props.blend &&
    css`
      background: white;
      padding: 1.5em 3.5em;
      p {
        flex: 1 0 auto;
      }

      a {
        align-self: flex-end;
      }
    `}

  > h4,
  p {
    color: ${props => props.theme.text.emphasized.string()};
  }
  
  
  > a {
    font-size: 1.2em;
    ${responsive.sm.andSmaller`
      border-top: none;
      align-self: flex-end;
      font-size: 0.75rem;
    `};
  }

  ${responsive.md.andSmaller`
    > h4 {
      margin: 0;
    }
    > label {
      margin-bottom: 0.5rem;
    }
  `};
`;

const NewsItem = ({
  id,
  slug,
  date,
  syllabus_title,
  syllabus,
  images,
  linkProvider,
  blend
}) => (
    <BookmarkLink
        bookmark={slug || id}
        to={linkProvider ? linkProvider(slug || id) : "/"}
        style={{ margin: "0 auto", zIndex: 1 }}
    >
      <Container blend={blend}>
        <SkeletonImage
          alt={syllabus_title}
          src={first(images)}
          height={"12.625em"}
          style={{ objectFit: "cover", objectPosition: "center center" }}
        />
        <Card blend={blend}>
          <H4>{syllabus_title}</H4>
          <Label uppercase>{date}</Label>
          <Paragraph size={"m"}>{syllabus}</Paragraph>
          <LanguageContext.Consumer>
            {({ contentService }) => (
              <Link color="navColor" to={linkProvider(slug || id)}>
                {contentService.translate("actions.read_more")}
              </Link>
            )}
          </LanguageContext.Consumer>
        </Card>
      </Container>
    </BookmarkLink>
);

export default NewsItem;
