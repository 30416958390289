import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Paper } from "./containers/Paper";
import prev from "../assets/internal/prev.svg";
import next from "../assets/internal/next.svg";
import Icon from "./Icon";
import SkeletonImage from "./SkeletonImage";
import { EllipsisParagraph, H5 } from "./typography";
import responsive from "../utils/responsive";
import { CenteredContainer } from "./Layout";
import Link from "./navigation/NavLink";
import { useMeasure } from "react-use";
import { useMediaQuery } from "react-responsive/src";
import ReactHtmlParser from "react-html-parser";

export const Arrow = ({ onClick, dir, className, style = {} }) => (
  <Icon
    className={className}
    onClick={onClick}
    alt={`arrow_${dir}`}
    style={{ ...style, margin: "auto", minWidth: "1.75rem" }}
  >
    {dir === "prev" ? prev : next}
  </Icon>
);

const ArrowPrev = <Arrow dir={"prev"} />;
const ArrowNext = <Arrow dir={"next"} />;

const thumbSettingsWide = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  lazyLoad: true,
  prevArrow: ArrowPrev,
  nextArrow: ArrowNext,
  responsive: [
    {
      breakpoint: 1204,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        prevArrow: null,
        nextArrow: null
      }
    }
  ]
};

const thumbSettingsNarrow = {
  dots: false,
  infinite: true,
  lazyLoad: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  prevArrow: ArrowPrev,
  nextArrow: ArrowNext,
  responsive: [
    {
      breakpoint: 1254,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1004,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        prevArrow: null,
        nextArrow: null
      }
    }
  ]
};

const thumbSettingsCentered = {
  dots: false,
  centerMode: false,
  infinite: true,
  slidesToShow: 4,
  swipeToSlide: true,
  lazyLoad: true,
  prevArrow: ArrowPrev,
  nextArrow: ArrowNext,
  centerPadding: "60px"
};

const Container = styled(CenteredContainer)`
  justify-content: unset;
  div,
  img {
    height: 11.5rem;
  }
  i {
    object-fit: fill;
  }
  padding: 0;
  width: ${props => props.width || "21.875rem"};
  // padding: 0 1.5625rem;
  padding: 0 1rem;
  // padding: 0;

  h5 {
    align-self: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props => props.width || "21.875rem"};
    margin: 1em 0 0.6em 0;
    ${responsive.sm.andSmaller`
      align-self: center;
      max-width: ${props => props.width || "21.875rem"};
    `}
    color: ${props => props.theme.text.emphasized.string()};
  }

  p {
    margin: 0;
    align-self: start;
    ${responsive.sm.andSmaller`
      align-self: center;
    `}
  }

  ${responsive.sm.andSmaller`
    width: 100%
    padding: 1em;
    div, img {
     height: 70vh;
    }
  `}

  overflow: hidden;
`;

export const SlideshowItem = ({
  image,
  thumbnail,
  title,
  description,
  onClick,
  linkProvider,
  id,
  slug,
  width,
  innerItemWidth
}) => (
  <Container width={width} innerItemWidth={innerItemWidth}>
    <Link to={linkProvider ? linkProvider(slug || id) : "/"} zoom>
      <SkeletonImage
        src={thumbnail || image}
        alt={title}
        onClick={onClick}
        width={innerItemWidth}
      />
      <H5>{title}</H5>
      <EllipsisParagraph lines={4}>
        {ReactHtmlParser(description)}
      </EllipsisParagraph>
    </Link>
  </Container>
);

const NarrowMargin = styled(Paper)`
  // padding: 0 4em;
  padding: 0 1em;

  .slick-list {
    justify-content: space-around;
    width: ${props => props.width}px;
    margin: 0 1.6875rem;

    ${responsive.sm.andSmaller`
      margin: 0;
    `}
  }
  ${responsive.sm.andSmaller`
      padding: 2em 0em;
  `}
`;

const chooseMode = mode => {
  if (mode === "centered") {
    return thumbSettingsCentered;
  } else if (mode === "narrow") {
    return thumbSettingsNarrow;
  } else {
    return thumbSettingsWide;
  }
};

const Slideshow = ({
  items,
  ItemComponent,
  onClick,
  mode,
  style,
  itemWidth = 400,
  itemPadding = 54,
  ...rest
}) => {
  const Item = ItemComponent || SlideshowItem;
  const [ref, { width }] = useMeasure();

  const navigationWidth = 110; // 110 for arrows + margin
  const itemsPerRow = Math.floor((width - navigationWidth) / itemWidth);
  const isMobile = useMediaQuery({ query: "(max-device-width: 750px)" });

  return (
    <NarrowMargin
      style={style}
      ref={ref}
      width={isMobile ? width : itemsPerRow * itemWidth}
    >
      <Slider
        {...Object.assign({
          ...chooseMode(mode),
          infinite: items.length > 4,
          slidesToShow: Math.max(itemsPerRow, 1)
        })}
        style={{ display: "flex", justifyContent: "center", flex: "1 0 auto" }}
      >
        {items.map((item, i) => (
          <Item
            key={i}
            {...item}
            {...rest}
            onClick={onClick ? () => onClick(i) : undefined}
            width={isMobile ? "100%" : `${itemWidth}px`}
            innerItemWidth={`${itemWidth - itemPadding}px`}
          />
        ))}
      </Slider>
    </NarrowMargin>
  );
};

export default Slideshow;
