import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    id: "dist_1",
    order: 1,
    slug: "moja-genijalna-prijateljica",
    type: "SERIES",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2018,
    title: translate("dist_1_title"),
    title_original: "L’AMICA GENIALE",
    description: translate("dist_1_description"),
    duration: "50 min",
    country: translate("dist_1_country"),
    episode: translate("dist_1_episode"),
    season: translate("dist_1_season"),
    genre: translate("dist_1_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Saverio Constanzo"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members:
          "Saverio Constanzo, Elena Ferrante, Francesco Piccolo, Laura Paolucci"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Lorenzo Mieli, Mario Gianani, Domenico Procacc"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Wildside, Fandango, Rai Fiction, Tim Vision, HBO"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Margherita Mazzucco, Elisa Del Genio, Gaia Girace, Ludovica Nasti, Valentina Acca"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Fabio Cianchetti"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Giancarlo Basili"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Antonella Cannarozzi"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Francesca Calvelli"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Max Richter"
      }
    ],
    director: "Saverio Constanzo",
    image: urlForMedia("distribution/moja_genijalna_prijateljica/poster.jpg"),
    thumbnail: urlForMedia(
      "distribution/moja_genijalna_prijateljica/thumbnail.jpg"
    ),
    image_cover: urlForMedia(
      "distribution/moja_genijalna_prijateljica/cover.jpg"
    ),
    image_description: urlForMedia(
      "distribution/moja_genijalna_prijateljica/sinopsis.jpeg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/sinopsis.jpeg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/moja_5.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/moja_3.jpg"
        )
      },
      {
        image: urlForMedia("distribution/moja_genijalna_prijateljica/cover.jpg")
      },
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/moja_2.jpeg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/moja_1.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moja_genijalna_prijateljica/moja_4.jpg"
        )
      }
    ]
  },
  {
    id: "dist_2",
    order: 2,
    slug: "zenski-raj",
    type: "SERIES",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2015,
    yearEnd: 2019,
    title: translate("dist_2_title"),
    title_original: "IL PARADISO DELLE SIGNORE",
    description: translate("dist_2_description"),
    duration: "50 min",
    country: translate("dist_2_country"),
    episode: translate("dist_2_episode"),
    season: translate("dist_2_season"),
    genre: translate("dist_2_genre"),
    people: [
      {
        role: translate("role_director_plural"),
        type: "role_director",
        members:
          "Isabella Leoni, Marco Maccaferri, Riccardo Mosca, Francesco Pavolini, Monica Vullo"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members:
          "Francesco Di Giovanni, Cristiano Testa , Eleonora Cimpanelli , Luca Biglione"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Giannandrea Pecorelli"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Rai Fiction, Aurora TV"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Alessandro Tersigni, Roberto Farnesi, Gloria Radulescu, Vanessa Gravina, Giorgio Lupano, Enrico\nOetiker, Alice Torriani, Giuseppe Zeno, Giusy Buscemi, Christiane Filangeri"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Lorenzo Adorisio, Stefano Palombi"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Stefano Giambanco"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Chiara Ferrantini"
      },
      {
        role: translate("role_editor_plural"),
        type: "role_editor",
        members: "Francesco Bilotti, Paolo Guerrieri"
      },
      {
        role: translate("role_composer_plural"),
        type: "role_composer",
        members: "Francesco De Luca, Alessandro Forti"
      }
    ],
    director:
      "Isabella Leoni, Marco Maccaferri, Riccardo Mosca, Francesco Pavolini, Monica Vullo",
    image: urlForMedia("distribution/zenski_raj/poster.jpg"),
    thumbnail: urlForMedia("distribution/zenski_raj/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/zenski_raj/cover.jpg"),
    image_description: urlForMedia("distribution/zenski_raj/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/zenski_raj/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_7.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_3.JPG")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_4.JPG")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_2.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_6.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/cover.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_5.jpg")
      },
      {
        image: urlForMedia("distribution/zenski_raj/ženski raj_1.jpg")
      }
    ]
  },
  {
    id: "dist_3",
    order: 3,
    slug: "mirai",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2018,
    title: translate("dist_3_title"),
    title_original: "MIRAI",
    description: translate("dist_3_description"),
    duration: "94 min",
    country: translate("dist_3_country"),
    awards: [
      {
        name: "OSCAR",
        title: "ACADEMY AWARD;\nBest Animated Film Nominee;\n2019"
      },
      {
        name: "GOLDEN_GLOBE",
        title: "GOLDEN GLOBE;\nBest Motion Picture Nominee;\n2019"
      },
      {
        name: "QUINZAINE",
        title: "Quinzaine"
      },
      {
        name: "OTHER",
        title: "ANNIE AWARD;\nBest Independent Feature Animation;\n2019"
      },
      {
        name: "OTHER",
        title: "Stiges Film Festival;\n2018"
      },
      {
        name: "OTHER",
        title: "San Sebastian International Film Festival;\n2018"
      }
    ],
    genre: translate("dist_3_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Mamoru Hosoda"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Mamoru Hosoda"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Yûichi Adachi Takuya Itô, Genki Kawamura, Yûichirô Saitô, Nozomu Takahashi"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members:
          "Studio Chizu, Dentsu, Kadokawa, NTT Docomo, Nippon Television Network"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Gen Hoshino, Haru Kuroki, Koji Yakusho, Kumiko Aso, Mitsuo Yoshihara, Moka Kamishiraishi,\nYoshiko Miyazaki"
      },
      {
        role: translate("role_production_designer_plural"),
        type: "role_production_designer",
        members:
          "Anri Jôjô, Yoshitaka Kameda, Reio Ono, Makoto Tanijiri, Tupera Tupera"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Daisuke Iga"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Shigeru Nishiyama"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Masakatsu Takagi"
      }
    ],
    director: "Mamoru Hosoda",
    image: urlForMedia("distribution/mirai/poster.jpg"),
    thumbnail: urlForMedia("distribution/mirai/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/mirai/cover.jpg"),
    image_description: urlForMedia("distribution/mirai/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/mirai/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/mirai_4.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/mirai_2.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/mirai_5.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/cover.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/mirai_1.jpg")
      },
      {
        image: urlForMedia("distribution/mirai/mirai_3.jpg")
      }
    ]
  },
  {
    id: "dist_4",
    order: 4,
    slug: "greh",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2019,
    title: translate("dist_4_title"),
    title_original: "IL PECCATO",
    description: translate("dist_4_description"),
    duration: "134 min",
    country: translate("dist_4_country"),
    awards: [
      {
        name: "OTHER",
        title: "SPECIAL CLOSING EVENT;\nRome Film Festival; \n2019"
      },
      {
        name: "OTHER",
        title: "LIFETIME ACHIEVEMENT AWARD;\nBlack Nights Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title:
          "BEST FILM NOMINEE;\nFEST Internatioanl Film Festival Belgrade;\n2020"
      }
    ],
    genre: translate("dist_4_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Andrey Konchalovskiy"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Andrey Konchalovskiy, Elena Kiseleva"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Alisher Burkhanovich Usmanov, Elda Ferri, Olesya Gidrat, Andrey Konchalovskiy"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Andrei Konchalovsky Studios, Jean Vigo Italia, Rai Cinema"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Alberto Testone, Orso Maria Guerrini, Yuliya Vysotskaya, Adriano Chiaramida, Massimo De Francovich, Antonio Gargiulo, Jakob Diehl, Nicola Adobati"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandr Simonov"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Maurizio Sabatini"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Dmitriy Andreev"
      },
      {
        role: translate("role_editor_plural"),
        type: "role_editor",
        members: "Karolina Maciejewska, Sergey Taraskin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Eduard Artemev"
      }
    ],
    director: "Andrey Konchalovskiy",
    image: urlForMedia("distribution/greh/poster.jpg"),
    thumbnail: urlForMedia("distribution/greh/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/greh/cover.jpg"),
    image_description: urlForMedia("distribution/greh/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/greh/greh_3.jpg")
      },
      {
        image: urlForMedia("distribution/greh/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/greh/greh_2.jpg")
      },
      {
        image: urlForMedia("distribution/greh/greh_5.jpg")
      },
      {
        image: urlForMedia("distribution/greh/cover.jpg")
      },
      {
        image: urlForMedia("distribution/greh/greh_1.jpg")
      },
      {
        image: urlForMedia("distribution/greh/greh_4.jpg")
      }
    ]
  },
  {
    id: "dist_5",
    order: 5,
    slug: "kamo-srece",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2019,
    title: translate("dist_5_title"),
    title_original: "MAGARI",
    description: translate("dist_5_description"),
    duration: "100 min",
    country: translate("dist_5_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "BEST FILM NOMINEE;\nFEST Internatioanl Film Festival Belgrade;\n2020"
      }
    ],
    genre: translate("dist_5_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ginevra Elkann"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Ginevra Elkann, Chiara Barzini"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Fabio Carlà"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Wildside, Iconoclast, Rai Cinema"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Riccardo Scamarcio, Alba Rohrwacher, Milo Roussel, Ettore Giustiniani, Oro de Commarque, Céline Sallette, Brett Gelman, Benjamin Baroche"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Vladan Radović"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Roberto De Angelis"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Sergio Zambon"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Desideria Rayner"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Riccardo Sinigallia"
      }
    ],
    director: "Ginevra Elkann",
    image: urlForMedia("distribution/kamo_srece/poster.jpg"),
    thumbnail: urlForMedia("distribution/kamo_srece/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/kamo_srece/cover.jpg"),
    image_description: urlForMedia("distribution/kamo_srece/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/kamo_srece/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/magari_5.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/magari_3.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/cover.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/magari_2.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/magari_1.jpg")
      },
      {
        image: urlForMedia("distribution/kamo_srece/magari_4.jpg")
      }
    ]
  },
  {
    id: "dist_6",
    order: 6,
    slug: "umreces-u-dvadesetoj",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2019,
    title: translate("dist_6_title"),
    title_original: "YOU WILL DIE AT TWENTY",
    description: translate("dist_6_description"),
    duration: "105 min",
    country: translate("dist_6_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "LION OF THE FUTURE;\nBEST DEBUT FILM;\nVenice Film Festival 2019"
      },
      {
        name: "OTHER",
        title:
          "GRAND PRIX DU JURY FICTION FOR BEST FILM;\nAmiens International Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "GOLDEN STAR;\nEl Gouna Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "BEST SCREENPLAY;\nCarthage Film Festival; \n2019"
      },
      {
        name: "OTHER",
        title: "HAMBURG PRODUCERS AWARD;\nHamburg Film Festival; \n2019"
      },
      {
        name: "OTHER",
        title: "SPECIAL MENTION;\nMumbai Film Festival; \n2019"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;\nFribourg International Film Festival;\n2020"
      },
      {
        name: "OTHER",
        title:
          "BEST FILM NOMINEE;\nFEST Internatioanl Film Festival Belgrade;\n2020"
      }
    ],
    genre: translate("dist_6_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Amjad Abu Alala"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Amjad Abu Alala, Yousef Ibrahim"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Arnaud Dommerc, Hossam Elouan, Ingrid Lill Høgtun, Michael Henrichs"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Andolfi,  Transit Films, Duofilm, Die Gesellschaft DGS"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Mustafa Shehata, Islam Mubarak, Muhamoud Elsarajj"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Sébastien Goepfert"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Heba Othman"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Amin Bouhafa"
      }
    ],
    director: "Amjad Abu Alala",
    image: urlForMedia("distribution/umreces_u_dvadesetoj/poster.jpg"),
    thumbnail: urlForMedia("distribution/umreces_u_dvadesetoj/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/umreces_u_dvadesetoj/cover.jpg"),
    image_description: urlForMedia(
      "distribution/umreces_u_dvadesetoj/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/umrećeš_2.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/umrećeš_3.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/umrećeš_1.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/umrećeš_4.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/cover.jpg")
      },
      {
        image: urlForMedia("distribution/umreces_u_dvadesetoj/umrećeš_5.jpg")
      }
    ]
  },
  {
    id: "dist_7",
    order: 7,
    slug: "nas-dve",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2019,
    title: translate("dist_7_title"),
    title_original: "DEUX",
    description: translate("dist_7_description"),
    duration: "96 min",
    country: translate("dist_7_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "BEST DEBUT FILM;\nFEST Internatioanl Film Festival Belgrade;\n2020"
      },
      {
        name: "OTHER",
        title: "GRAND JURY PRIZE;\nAngers European First Film Festival;\n2020"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS;\nDublin International Film Festival;\n2020"
      },
      {
        name: "OTHER",
        title: "Toronto International Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "Thessaloniki International Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "Rome Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "Les Arcs Film Festival;\n2019"
      },
      {
        name: "OTHER",
        title: "International Film Festival Rotterdam;\n2020"
      }
    ],
    genre: translate("dist_7_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Filippo Meneghetti"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Filippo Meneghetti, Malysone Bovorasmy"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Laurent Baujard, Pierre-Emmanuel"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Paprika Films, Tarantula, Artémis Productions"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Barbara Sukowa, Martine Chevallier, Léa Drucker, Muriel Benazeraf, Jérôme Varanfrain"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aurélien Marra"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Laurie Colson"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Magdalena Labuz"
      },
      {
        role: translate("role_editor_plural"),
        type: "role_editor",
        members: "Julia Maby, Ronan Tronchot"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Michele Menini"
      }
    ],
    director: "Filippo Meneghetti",
    image: urlForMedia("distribution/nas_dve/poster.jpg"),
    thumbnail: urlForMedia("distribution/nas_dve/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/nas_dve/cover.jpg"),
    sponsored_image: urlForMedia("distribution/nas_dve/sponsored.png"),
    image_description: urlForMedia("distribution/nas_dve/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/nas_dve/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/nas dve_3.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/nas dve_5.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/cover.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/nas dve_1.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/nas dve_4.jpg")
      },
      {
        image: urlForMedia("distribution/nas_dve/nas dve_2.jpg")
      }
    ]
  },
  {
    id: "dist_8",
    order: 8,
    slug: "duran-duran-iza-scene",
    type: "MOVIE",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2013,
    title: translate("dist_8_title"),
    title_original: "DURAN DURAN: UNSTAGED",
    description: translate("dist_8_description"),
    duration: "121 min",
    country: translate("dist_8_country"),
    genre: translate("dist_8_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "David Lynch"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Peter Deming"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Andrew Kelly, Blake W. Morrison, Sabrina S. Sutherland"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "American Express, Archlight Films, Little Studio Films"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Simon Le Bon, John Taylor, Roger Taylor, Nick Rhodes, Gerard Way, Beth Ditto, Kelis, Mark Ronson"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Peter Deming"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "David Harari"
      },
      {
        role: translate("role_music"),
        type: "role_music",
        members: "Duran Duran"
      }
    ],
    director: "David Lynch",
    image: urlForMedia("distribution/duran_duran/poster.jpg"),
    thumbnail: urlForMedia("distribution/duran_duran/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/duran_duran/cover.jpg"),
    image_description: urlForMedia("distribution/duran_duran/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/duran_duran/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/duran_duran_1.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/duran_duran_5.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/cover.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/duran_duran_2.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/duran_duran_3.jpg")
      },
      {
        image: urlForMedia("distribution/duran_duran/duran_duran_4.jpg")
      }
    ]
  },
  {
    id: "dist_9",
    order: 9,
    slug: "mlada-zena",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2017,
    title: translate("dist_9_title"),
    title_original: "JEUNE FEMME",
    description: translate("dist_9_description"),
    duration: "97 min",
    country: translate("dist_9_country"),
    awards: [
      {
        name: "CANNES2",
        title: "Camera Dor Logo"
      },
      {
        name: "OTHER",
        title: "PRIX DU JURY;\nChamps - Élysées Film Festival;\n2017"
      },
      {
        name: "OTHER",
        title: "BEST FILM;\nStockholm Film Festival;\n2017"
      },
      {
        name: "OTHER",
        title: "BEST FILM;\nJanela Do Recife International Film Festival;\n2017"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS;\nBratislava International Film Festival;\n2017"
      },
      {
        name: "OTHER",
        title: "PRIX D’ORNANO;\nDeauville Film Festival;\n2017"
      }
    ],
    genre: translate("dist_9_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Léonor Serraille"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Léonor Serraille"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Sandra da Fonseca"
      },
      {
        role: translate("production_company"),
        type: "production_company",
        members: "Blue Monday Productions"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Laetitia Dosch, Souleymane Seye Ndiaye, Grégoire Monsaingeon"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Émilie Noblet"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Valérie Valéro"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Hyat Luszpinski"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Clémence Carré"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Julie Roué"
      }
    ],
    director: "Léonor Serraille",
    image: urlForMedia("distribution/mlada_zena/poster.jpg"),
    thumbnail: urlForMedia("distribution/mlada_zena/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/mlada_zena/cover.jpg"),
    image_description: urlForMedia("distribution/mlada_zena/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/mlada_zena/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/mlada_3.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/mlada_5.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/mlada_2.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/cover.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/mlada_4.jpg")
      },
      {
        image: urlForMedia("distribution/mlada_zena/mlada_1.jpg")
      }
    ]
  },
  {
    id: "dist_10",
    order: 10,
    slug: "sofija",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2018,
    title: translate("dist_10_title"),
    title_original: "SOFIA",
    description: translate("dist_10_description"),
    duration: "80 min",
    country: translate("dist_10_country"),
    awards: [
      {
        name: "OTHER",
        title: "NEW VOICES NEW AWARDS;\nPalm Springs Iff ;\n2019"
      },
      {
        name: "OTHER",
        title: "BEST SCREENPLAY;\nFestival Du Francophone D’Angoulême;\n2018"
      },
      {
        name: "OTHER",
        title: "BEST DIRECTOR;\nBratislava International Film Festival;\n2018"
      },
      {
        name: "OTHER",
        title: "JURY’S SPECIAL PRIZE;\nFestival Cinemed Bruxelles;\n2018"
      }
    ],
    genre: translate("dist_10_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Meryem Benm'Barek-Aloïsi"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Meryem Benm'Barek-Aloïsi"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Olivier Delbosc"
      },
      {
        role: translate("production_company"),
        type: "production_company",
        members: "Curiosa Films"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Maha Alemi, Lubna Azabal, Sarah Perles, Faouzi Bensaïdi, Hamza Khafif, Nadia Niazi, Rawia"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Son Doan"
      },
      {
        role: translate("role_production_designer"),
        type: "role_production_designer",
        members: "Samuel Charbonnot"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Céline Perréard"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Fanny Lamothe"
      }
    ],
    director: "Meryem Benm'Barek-Aloïsi",
    image: urlForMedia("distribution/sofia/poster.jpg"),
    thumbnail: urlForMedia("distribution/sofia/thumbnail.jpg"),
    image_cover: urlForMedia("distribution/sofia/cover.jpg"),
    image_description: urlForMedia("distribution/sofia/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/sofia/sinopsis.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/sofia_1.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/sofia_2.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/sofia_5.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/sofia_4.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/cover.jpg")
      },
      {
        image: urlForMedia("distribution/sofia/sofia_3.jpg")
      }
    ]
  },
  {
    id: "dist_11",
    order: 11,
    slug: "osmi-putnik",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1979,
    title: translate("dist_11_title"),
    title_original: "Alien",
    description: translate("dist_11_description"),
    duration: "117 min",
    country: translate("dist_11_country"),
    awards: [
      {
        name: "OSCAR",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "OSCAR",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "BAFTA",
        count: 2,
        outcome: "Winner"
      },
      {
        name: "BAFTA",
        count: 4,
        outcome: "Nominee"
      },
      {
        name: "SATURN",
        count: 3,
        outcome: "Winner"
      },
      {
        name: "SATURN",
        count: 4,
        outcome: "Nominee"
      },
      {
        name: "HUGO",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "GRAMMY",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "JUPITER",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_11_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ridley Scott"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Tom Skerritt, Sigourney Weaver, Veronica Cartwright, Harry Dean Stanton, John Hurt, Ian\nHolm, Yaphet Kotto"
      }
    ],
    director: "Ridley Scott",
    image: urlForMedia("distribution/archive/8_passanger/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/8_passanger/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/8_passanger/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_12",
    order: 12,
    slug: "9-nedelja",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1986,
    title: translate("dist_12_title"),
    title_original: "9 ½ Weeks",
    description: translate("dist_12_description"),
    duration: "117 min",
    country: translate("dist_12_country"),
    genre: translate("dist_12_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Adrian Lyne"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Mickey Rourke, Kim Basinger, Margaret Whitton"
      }
    ],
    director: "Adrian Lyne",
    image: urlForMedia("distribution/archive/nineth/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/nineth/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/nineth/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_13",
    order: 13,
    slug: "gandi",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1982,
    title: translate("dist_13_title"),
    title_original: "Gandhi",
    description: translate("dist_13_description"),
    duration: "191 min",
    country: translate("dist_13_country"),
    awards: [
      {
        name: "OSCAR",
        count: 8,
        outcome: "Winner"
      },
      {
        name: "OSCAR",
        count: 3,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 5,
        outcome: "Nominee"
      },
      {
        name: "BAFTA",
        count: 5,
        outcome: "Winner"
      },
      {
        name: "BAFTA",
        count: 11,
        outcome: "Nominee"
      },
      {
        name: "GRAMMY",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_13_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Richard Attenborough"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ben Kingsley, Rohini Hattangadi, Candice Bergen, Roshan Seth, Edward Fox, John Gielgud,\nTrevor Howard"
      }
    ],
    director: "Richard Attenborough",
    image: urlForMedia("distribution/archive/gandhi/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/gandhi/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/gandhi/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_14",
    order: 14,
    slug: "predator",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1987,
    title: translate("dist_14_title"),
    title_original: "Predator",
    description: translate("dist_14_description"),
    duration: "107 min",
    country: translate("dist_14_country"),
    awards: [
      {
        name: "OSCAR",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "SATURN",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "SATURN",
        count: 3,
        outcome: "Nominee"
      },
      {
        name: "HUGO",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_14_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "John McTiernan"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Arnold Schwarzenegger, Carl Weathers, Elpidia Carrillo, Bill Duke, Jesse Ventura, Sonny\nLandham, Richard Chaves"
      }
    ],
    director: "John McTiernan",
    image: urlForMedia("distribution/archive/predator/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/predator/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/predator/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_15",
    order: 15,
    slug: "vatrene-kocije",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1981,
    title: translate("dist_15_title"),
    title_original: "Chariots of Fire",
    description: translate("dist_15_description"),
    duration: "125 min",
    country: translate("dist_15_country"),
    awards: [
      {
        name: "OSCAR",
        count: 4,
        outcome: "Winner"
      },
      {
        name: "OSCAR",
        count: 3,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "BAFTA",
        count: 3,
        outcome: "Winner"
      },
      {
        name: "BAFTA",
        count: 7,
        outcome: "Nominee"
      },
      {
        name: "CANNES3",
        count: 2,
        outcome: "Winner"
      },
      {
        name: "CANNES3",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GRAMMY",
        count: 1,
        outcome: "Winner"
      }
    ],
    genre: translate("dist_15_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Hugh Hudson"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Nicholas Farrell, Nigel Havers, Ian Charleson, Ben Cross, Daniel Gerroll, Ian Holm, John\nGielgud"
      }
    ],
    director: "Hugh Hudson",
    image: urlForMedia("distribution/archive/charriots/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/charriots/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/charriots/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_16",
    order: 16,
    slug: "policajac-sa-beverli-hilsa",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1984,
    title: translate("dist_16_title"),
    title_original: "Beverly Hills Cop",
    description: translate("dist_16_description"),
    duration: "105 min",
    country: translate("dist_16_country"),
    awards: [
      {
        name: "OSCAR",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "BAFTA",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GRAMMY",
        count: 1,
        outcome: "Winner"
      }
    ],
    genre: translate("dist_16_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Martin Brest"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Eddie Murphy, Judge Reinhold, John Ashton, Lisa Eilbacher, Ronny Cox"
      }
    ],
    director: "Martin Brest",
    image: urlForMedia("distribution/archive/beverly/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/beverly/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/beverly/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_17",
    order: 17,
    slug: "lice-sa-oziljkom",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1983,
    title: translate("dist_17_title"),
    title_original: "Scarface",
    description: translate("dist_17_description"),
    duration: "170 min",
    country: translate("dist_17_country"),
    awards: [
      {
        name: "GOLDEN_GLOBE",
        count: 3,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_17_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Brian De Palma"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Al Pacino, Steven Bauer, Michelle Pfeiffer, Mary Elizabeth Mastrantonio"
      }
    ],
    director: "Brian De Palma",
    image: urlForMedia("distribution/archive/scarface/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/scarface/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/scarface/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_18",
    order: 18,
    slug: "brejkdens",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1984,
    title: translate("dist_18_title"),
    title_original: "Breakin'",
    description: translate("dist_18_description"),
    duration: "90 min",
    country: translate("dist_18_country"),
    genre: translate("dist_18_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Joel Silberg"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Lucinda Dickey, Adolfo Quinones, Michael Chambers, Ben Lokey"
      }
    ],
    director: "Joel Silberg",
    image: urlForMedia("distribution/archive/breakin/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/breakin/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/breakin/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_19",
    order: 19,
    slug: "varljivo-sunce",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1994,
    title: translate("dist_19_title"),
    title_original: "Utomlennye Solntsem",
    description: translate("dist_19_description"),
    duration: "135 min",
    country: translate("dist_19_country"),
    awards: [
      {
        name: "OSCAR",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "BAFTA",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "FELIX",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "CANNES3",
        count: 2,
        outcome: "Winner"
      },
      {
        name: "CANNES3",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_19_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Nikita Mikhalkov"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Oleg Menshikov, Nikita Mikhalkov, Ingeborga Dapkunaite, Nadezdha Mikhalkova, Vyacheslav\nTikhonov"
      }
    ],
    director: "Nikita Mikhalkov",
    image: urlForMedia("distribution/archive/sun/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/sun/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/sun/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_20",
    order: 20,
    slug: "regtajm",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1981,
    title: translate("dist_20_title"),
    title_original: "Ragtime",
    description: translate("dist_20_description"),
    duration: "155 min",
    country: translate("dist_20_country"),
    awards: [
      {
        name: "OSCAR",
        count: 8,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 7,
        outcome: "Nominee"
      },
      {
        name: "BAFTA",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GRAMMY",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_20_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Milos Forman"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "James Cagney, Brad Dourif, Moses Gunn, Elizabeth McGovern, Kenneth McMillan"
      }
    ],
    director: "Milos Forman",
    image: urlForMedia("distribution/archive/ragtime/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/ragtime/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/ragtime/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_21",
    order: 21,
    slug: "fickaraldo",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "ARCHIVE",
    year: 1982,
    title: translate("dist_21_title"),
    title_original: "Fitzcarraldo",
    description: translate("dist_21_description"),
    duration: "158 min",
    country: translate("dist_21_country"),
    awards: [
      {
        name: "CANNES3",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "CANNES3",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GOLDEN_GLOBE",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "BAFTA",
        count: 1,
        outcome: "Nominee"
      },
      {
        name: "GERMAN",
        count: 1,
        outcome: "Winner"
      },
      {
        name: "GERMAN",
        count: 1,
        outcome: "Nominee"
      }
    ],
    genre: translate("dist_21_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Werner Herzog"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Klaus Kinski, Claudia Cardinale, José Lewgoy, Miguel Ángel Fuentes"
      }
    ],
    director: "Werner Herzog",
    image: urlForMedia("distribution/archive/fickaraldo/poster.jpg"),
    thumbnail: urlForMedia("distribution/archive/fickaraldo/poster.jpg"),
    image_cover: urlForMedia("distribution/archive/fickaraldo/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "dist_22",
    order: 22,
    slug: "konacni-izvestaj",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2020,
    title: translate("dist_22_title"),
    title_original: "FINAL REPORT",
    description: translate("dist_22_description"),
    duration: "118 min",
    country: translate("dist_22_country"),
    awards: [
      {
        name: "OTHER",
        title: "Tallinn Black Nights Film Festival\n2020"
      }
    ],
    genre: translate("dist_22_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "István Szabó"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "István Szabó"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Pál Sándor, Attila Tõzsér"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Film Street, Filmkontroll"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Klaus Maria Brandauer, Károly Eperjes, Dorottya Udvaros, András Stohl, Éva Kerekes, Mari Csomós, Ági Szirtes, Péter Andorai, Eszter Csákányi, Tímea Virga, Ákos Orosz"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Lajos Koltai"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Márton Gothár"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Atti Pacsay"
      }
    ],
    director: "István Szabó",
    image: urlForMedia("distribution/konacni_izvestaj/poster.jpg"),
    thumbnail: urlForMedia(
      "distribution/konacni_izvestaj/zarojelentes_srgb.0056168.png"
    ),
    image_cover: urlForMedia(
      "distribution/konacni_izvestaj/zarojelentes_srgb.0056168.png"
    ),
    image_description: urlForMedia(
      "distribution/konacni_izvestaj/zarojelentes_srgb.0045719.png"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0056168.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0045719.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0012655.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0026333.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0043453.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0037032.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0013453.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0052984.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0006628.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0015493.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0049630.png"
        )
      },
      {
        image: urlForMedia("distribution/konacni_izvestaj/sinopsis_konačni.png")
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0047694.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0054102.png"
        )
      },
      {
        image: urlForMedia("distribution/konacni_izvestaj/naslovna_konačni.png")
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0047619.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0049445.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0013240.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0033724.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0029993.png"
        )
      },
      {
        image: urlForMedia(
          "distribution/konacni_izvestaj/zarojelentes_srgb.0051530.png"
        )
      }
    ]
  },
  {
    id: "dist_23",
    order: 23,
    slug: "sve-ce-biti-u-redu",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2020,
    title: translate("dist_23_title"),
    title_original: "COSA SARÀ",
    description: translate("dist_23_description"),
    duration: "101 min",
    country: translate("dist_23_country"),
    awards: [
      {
        name: "OTHER",
        title: "Rome Film Fest\n2020"
      }
    ],
    genre: translate("dist_23_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Francesco Bruni"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Francesco Bruni"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Carlo Degli Esposti, Nicola Serra"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members: "Palomar, Vision Distribution"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Kim Rossi Stuart, Lorenza Indovina, Barbara Ronchi, Giuseppe Pambieri, Raffaella Lebboroni, Nicola Nocella, Fotinì Peluso, Tancredi Galli, Elettra Mallaby, Stefano Rossi Giordani"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Carlo Rinaldi"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Alessandro Heffler"
      },
      {
        role: translate("role_composer_plural"),
        type: "role_composer",
        members: "Stefano Ratchev, Mattia Carratello"
      }
    ],
    director: "Francesco Bruni",
    image: urlForMedia("distribution/sve_ce_biti_u_redu/poster.jpg"),
    thumbnail: urlForMedia("distribution/sve_ce_biti_u_redu/naslovna_sve.png"),
    image_cover: urlForMedia(
      "distribution/sve_ce_biti_u_redu/naslovna_sve.png"
    ),
    image_description: urlForMedia(
      "distribution/sve_ce_biti_u_redu/sinopsis_sve.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/sve_ce_biti_u_redu/naslovna_sve.png")
      },
      {
        image: urlForMedia("distribution/sve_ce_biti_u_redu/sinopsis_sve.jpg")
      },
      {
        image: urlForMedia(
          "distribution/sve_ce_biti_u_redu/rossi stuart, peluso, indovina atb-20-24-0046∏paolociriello.jpg"
        )
      }
    ]
  },
  {
    id: "dist_24",
    order: 24,
    slug: "oce-nas",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2020,
    title: translate("dist_24_title"),
    title_original: "PADRENOSTRO",
    description: translate("dist_24_description"),
    duration: "122 min",
    country: translate("dist_24_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "VOLPI CUP FOR BEST ACTOR \nVenice Film Festival \n2020\n\nBusan International Film Festival\n2020"
      }
    ],
    genre: translate("dist_24_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Claudio Noce"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Claudio Noce, Enrico Audenino"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Andrea Calbucci, Pierfrancesco Favino, Maurizio Piazza"
      },
      {
        role: translate("production_company_plural"),
        type: "production_company",
        members:
          "Lungta Film, PKO Cinema & Co., Tendercapital s, Vision Distribution"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Pierfrancesco Favino, Barbara Ronchi, Mattia Garaci, Francesco Gheghi, Antonio Gerardi, Francesco Colella, Anna Maria De Luca, Mario Pupella, Lea Favino, Eleonora De Luca, Simone Chiacchiararelli"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Michele D’Attanasio"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Giogiò Franchini"
      },
      {
        role: translate("role_composer_plural"),
        type: "role_composer",
        members: "Mattia Carratello, Stefano Ratchev"
      }
    ],
    director: "Claudio Noce",
    image: urlForMedia("distribution/oce_nas/poster.jpg"),
    thumbnail: urlForMedia("distribution/oce_nas/DSC_6320 copy.jpg"),
    image_cover: urlForMedia("distribution/oce_nas/DSC_6320 copy.jpg"),
    image_description: urlForMedia("distribution/oce_nas/DSC_5000 copy.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("distribution/oce_nas/DSC_6320 copy.jpg")
      },
      {
        image: urlForMedia("distribution/oce_nas/DSC_5000 copy.jpg")
      },
      {
        image: urlForMedia("distribution/oce_nas/DSC_6469 copy.jpg")
      },
      {
        image: urlForMedia("distribution/oce_nas/sinopsis_oče naš.jpg")
      },
      {
        image: urlForMedia("distribution/oce_nas/naslovna_oče naš.jpg")
      }
    ]
  },
  {
    id: "dist_25",
    order: 25,
    slug: "moj-brat-juri-dinosauruse",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2019,
    title: translate("dist_25_title"),
    title_original: "MIO FRATELLO RINCORRE I DINOSAURI",
    description: translate("dist_25_description"),
    duration: "101 min",
    country: translate("dist_25_country"),
    awards: [
      {
        name: "OTHER",
        title: "YOUNG AUDIENCE AWARD\nEuropean Film Award\n2020"
      }
    ],
    genre: translate("dist_25_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Stefan Cipani"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Fabio Bonifacci"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Alessandro Gassman, Isabell Ragonese, Rossy de\nPalma, Francesco Gheghi, Lorenzo Sisto"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Sergi Batroli"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Massimo Quaglia"
      }
    ],
    director: "Stefan Cipani",
    image: urlForMedia("distribution/moj_brat_juri_dinosauruse/poster.jpg"),
    thumbnail: urlForMedia(
      "distribution/moj_brat_juri_dinosauruse/1I0A3979.jpg"
    ),
    image_cover: urlForMedia(
      "distribution/moj_brat_juri_dinosauruse/1I0A3979.jpg"
    ),
    image_description: urlForMedia(
      "distribution/moj_brat_juri_dinosauruse/sinopsis_moj.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/1I0A3979.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/sinopsis_moj.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/1I0A9045.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/naslovna_moj.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/1I0A3792.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/MY BROTHER CHASES DINOSAUROS- VISION DISTRIBUTION.jpg"
        )
      },
      {
        image: urlForMedia(
          "distribution/moj_brat_juri_dinosauruse/1I0A0168.jpg"
        )
      }
    ]
  },
  {
    id: "dist_26",
    order: 26,
    slug: "izmedju-dva-sveta",
    type: "MOVIE",
    status: "IN_THEATERS",
    category: "CURRENT",
    year: 2021,
    title: translate("dist_26_title"),
    title_original: "OUISTREHAM",
    description: translate("dist_26_description"),
    genre: translate("dist_26_genre"),
    people: [],
    director: "",
    locked: false,
    slideshow: []
  }
];
