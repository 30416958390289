import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    id: "merima-isakovic-intervju",
    date: "AVGUST 10, 2022",
    syllabus_title: translate("news_merima-isakovic-intervju_syl_title"),
    syllabus: translate("news_merima-isakovic-intervju_syllabus"),
    text1: translate("news_merima-isakovic-intervju_text1"),
    text_author: translate("news_merima-isakovic-intervju_text_author"),
    header_image: translate("news_merima-isakovic-intervju_header_image"),
    q1: translate("news_merima-isakovic-intervju_q1"),
    q2: translate("news_merima-isakovic-intervju_q2"),
    q3: translate("news_merima-isakovic-intervju_q3"),
    q4: translate("news_merima-isakovic-intervju_q4"),
    q5: translate("news_merima-isakovic-intervju_q5"),
    q6: translate("news_merima-isakovic-intervju_q6"),
    q7: translate("news_merima-isakovic-intervju_q7"),
    q8: translate("news_merima-isakovic-intervju_q8"),
    q9: translate("news_merima-isakovic-intervju_q9"),
    q10: translate("news_merima-isakovic-intervju_q10"),
    a1: translate("news_merima-isakovic-intervju_a1"),
    a2: translate("news_merima-isakovic-intervju_a2"),
    a3: translate("news_merima-isakovic-intervju_a3"),
    a4: translate("news_merima-isakovic-intervju_a4"),
    a5: translate("news_merima-isakovic-intervju_a5"),
    a6: translate("news_merima-isakovic-intervju_a6"),
    a7: translate("news_merima-isakovic-intervju_a7"),
    a8: translate("news_merima-isakovic-intervju_a8"),
    a9: translate("news_merima-isakovic-intervju_a9"),
    a10: translate("news_merima-isakovic-intervju_a10"),
    images: [
      urlForMedia("news-press/merima/merima_1.jpeg"),
      urlForMedia("news-press/merima/merima_2.jpeg"),
      urlForMedia("news-press/merima/merima_3.jpeg"),
      urlForMedia("news-press/merima/merima_4.jpeg")
    ],
    ctalink: null
  },
  {
    id: "sloboda-micalovic-intervju-suncokret",
    date: "MART 1, 2022",
    syllabus_title: translate(
      "news_sloboda-micalovic-intervju-suncokret_syl_title"
    ),
    syllabus: translate("news_sloboda-micalovic-intervju-suncokret_syllabus"),
    text1: translate("news_sloboda-micalovic-intervju-suncokret_text1"),
    text_author: translate(
      "news_sloboda-micalovic-intervju-suncokret_text_author"
    ),
    header_image: translate(
      "news_sloboda-micalovic-intervju-suncokret_header_image"
    ),
    q1: translate("news_sloboda-micalovic-intervju-suncokret_q1"),
    q2: translate("news_sloboda-micalovic-intervju-suncokret_q2"),
    q3: translate("news_sloboda-micalovic-intervju-suncokret_q3"),
    q4: translate("news_sloboda-micalovic-intervju-suncokret_q4"),
    q5: translate("news_sloboda-micalovic-intervju-suncokret_q5"),
    q6: translate("news_sloboda-micalovic-intervju-suncokret_q6"),
    q7: translate("news_sloboda-micalovic-intervju-suncokret_q7"),
    q8: translate("news_sloboda-micalovic-intervju-suncokret_q8"),
    a1: translate("news_sloboda-micalovic-intervju-suncokret_a1"),
    a2: translate("news_sloboda-micalovic-intervju-suncokret_a2"),
    a3: translate("news_sloboda-micalovic-intervju-suncokret_a3"),
    a4: translate("news_sloboda-micalovic-intervju-suncokret_a4"),
    a5: translate("news_sloboda-micalovic-intervju-suncokret_a5"),
    a6: translate("news_sloboda-micalovic-intervju-suncokret_a6"),
    a7: translate("news_sloboda-micalovic-intervju-suncokret_a7"),
    a8: translate("news_sloboda-micalovic-intervju-suncokret_a8"),
    images: [
      urlForMedia("news-press/sloboda/sloboda_1.png"),
      urlForMedia("news-press/sloboda/sloboda_2.png"),
      urlForMedia("news-press/sloboda/sloboda_3.png"),
      urlForMedia("news-press/sloboda/sloboda_4.png"),
      urlForMedia("news-press/sloboda/sloboda_5.png"),
      urlForMedia("news-press/sloboda/sloboda_6.png")
    ],
    ctalink: null
  },
  {
    id: "kreka-eksluzivna-projekcija",
    date: "JUN 16, 2021",
    syllabus_title: translate("news_kreka-eksluzivna-projekcija_syl_title"),
    syllabus: translate("news_kreka-eksluzivna-projekcija_syllabus"),
    text1: translate("news_kreka-eksluzivna-projekcija_text1"),
    text3: translate("news_kreka-eksluzivna-projekcija_text3"),
    header_image: translate("news_kreka-eksluzivna-projekcija_header_image"),
    images: [
      urlForMedia("news-press/kreka/THUMBNAIL.jpg"),
      urlForMedia("news-press/kreka/kreka01.jpg"),
      urlForMedia("news-press/kreka/kreka02.jpg"),
      urlForMedia("news-press/kreka/kreka03.jpg"),
      urlForMedia("news-press/kreka/kreka04.jpg"),
      urlForMedia("news-press/kreka/kreka05.jpg")
    ],
    ctalink: null
  }
];
