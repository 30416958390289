import {useEffect, useRef} from 'react';
import {useLocation} from "@reach/router";


const useFocusElement = (id) => {
  const ref = useRef();
  const location = useLocation();
  const hash = location.hash.replace('#', '');

  useEffect(() => {
    if(ref && ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, [ref])

  return hash === id ? ref : undefined;
}

export default useFocusElement;
