export default {
  nodeEnv: process.env.NODE_ENV,
  publicURL: process.env.PUBLIC_URL || "http://localhost:3000",
  googleMapsKey:
    process.env.GOOGLE_MAPS_KEY || "AIzaSyAmAb43m1-vx5OFjjO_aqEZl_dbpgcU8tE",
  facebookPage: "https://www.facebook.com/CentarFilm/",
  instagramProfile: "https://www.instagram.com/centarfilm/",
  youtubeChannel: "https://www.youtube.com/channel/UCP1Dy0OBRxl1j7-dAV6TxAQ",
  assetsBaseUrl:
    process.env.NODE_ENV === "development"
      ? "http://192.168.1.213:3000"
      : "https://assets.centarfilm.com"
};
