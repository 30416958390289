import color from "color";

export const colors = {
  vibrant: color("#CDAD77"),
  primary: color("#C7A366"),
  navigation: color("#E8D9C0"),
  subtle: color("#DAD2C0"),
  light: color("#E6E0D4"),
  lighter: color("#F5F2ED"),
  lightest: color("#FAFBF7"),
  dark: color("#45423A"),
  midnight: color("#25231F"),
  darkerContrast: color("#461719"),
  darkContrast: color("#50191B"),
  mediumContrast: color("#48171A"),
  yetAnotherRed: color("#581E21"),
  contrast: color("#80282E")
};

const textSize = {
  xs: "0.75rem",
  s: "0.875rem",
  m: "1rem",
  ml: "1.125rem",
  l: "1.2rem",
  xl: "1.5rem"
};

const lineHeight = {
  xs: `calc(1.3 * ${textSize.xs})`,
  s: `calc(1.4 * ${textSize.s})`,
  m: `calc(1.5 * ${textSize.m})`,
  ml: `calc(1.44 * ${textSize.ml})`,
  l: `calc(1.5 * ${textSize.l})`,
  xl: `calc(1.4 * ${textSize.xl})`
};

const fontWeight = {
  bold: 600,
  medium: 500,
  regular: 400,
  thin: 300
};

export const theme = {
  textSize,
  lineHeight,
  fontWeight,
  colors,
  defaultSpacing: "1rem",

  text: {
    default: colors.primary,
    vibrant: colors.vibrant,
    subtle: colors.lighter.alpha(0.8),
    light: colors.lighter,
    emphasized: colors.dark,
    contrast: colors.darkContrast
  },

  background: {
    default: colors.lightest,
    primary: colors.primary,
    dark: colors.contrast,
    contrast: colors.midnight
  },

  link: {
    primaryColor: colors.subtle,
    navigation: colors.navigation,
    navColor: colors.primary,
    secondaryColor: colors.primary.alpha(0.6),
    underlined: false
  },

  button: {
    default: {
      background: colors.light.alpha(0.2),
      color: colors.primary,
      borderColor: color("transparent")
    },
    primary: {
      background: colors.primary,
      color: colors.lightest,
      borderColor: colors.primary
    },
    secondary: {
      background: colors.lightest,
      color: colors.primary,
      borderColor: colors.primary
    },
    danger: {
      background: colors.darkerContrast,
      borderColor: colors.darkerContrast,
      color: colors.subtle
    },
    neutral: {
      background: color("transparent"),
      color: colors.subtle,
      borderColor: colors.darkerContrast
    }
  },
  border: {
    width: "1px",
    radius: 0,
    color: {
      default: colors.light,
      focus: colors.primary
    }
  }
};
