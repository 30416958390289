import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    title: translate("about_0_title"),
    text: translate("about_0_text"),
    image: urlForMedia("about/about_1.jpg")
  },
  {
    title: translate("about_1_title"),
    text: translate("about_1_text"),
    image: urlForMedia("about/about_2.jpg")
  },
  {
    title: translate("about_2_title"),
    text: translate("about_2_text"),
    image: urlForMedia("about/about_3.jpg")
  },
  {
    title: translate("about_3_title"),
    text: translate("about_3_text"),
    image: urlForMedia("about/about_4.jpg")
  },
  {
    title: translate("about_4_title"),
    text: translate("about_4_text"),
    image: urlForMedia("about/about_5.jpg")
  },
  {
    title: translate("about_5_title"),
    text: translate("about_5_text"),
    image: urlForMedia("about/about_6.jpg")
  },
  {
    title: translate("about_6_title"),
    text: translate("about_6_text"),
    image: urlForMedia("about/about_7.jpg")
  },
  {
    title: translate("about_7_title"),
    text: translate("about_7_text"),
    image: urlForMedia("about/about_8.jpg")
  },
  {
    title: translate("about_8_title"),
    text: translate("about_8_text"),
    image: urlForMedia("about/about_9.jpg")
  },
  {
    title: translate("about_9_title"),
    text: translate("about_9_text"),
    image: urlForMedia("about/about_10.jpg")
  },
  {
    title: translate("about_10_title"),
    text: translate("about_10_text"),
    image: urlForMedia("about/about_11.jpg")
  }
];
