import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Text } from "../typography";
import Link from "../navigation/NavLink";
import responsive from "../../utils/responsive";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const ListItem = styled(motion.li)`
  margin: 0;
  padding: 0;
  opacity: 0.8;
  list-style: none;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 4.75rem;
  padding-right: 2rem;
  ${responsive.sm.andSmaller`
    padding-left: 2rem;
  `}
`;

const Item = styled(Text.withComponent(Link))`
  border-radius: 5px;
  font-size: 1.2em;
  text-decoration: none;
  color: ${props => props.theme.link.navigation.string()};
  ${responsive.sm.andSmaller`
    font-size: 0.9rem;
  `}
  text-transform: uppercase;
`;

export const MenuItem = ({ to, label, onClick, disabled }) => (
  <ListItem
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    <Item
      to={to}
      onClick={disabled ? onClick : onClick}
      style={disabled ? undefined : undefined}      
    >
      {label}
    </Item>
  </ListItem>
);
