import React from "react";
import styled from "styled-components";
import { htmlContentStyles, Paragraph } from "./typography";
import ReactHtmlParser from "react-html-parser";

const StyledParagraph = styled(Paragraph)`
  white-space: pre-line;
  ${htmlContentStyles}
`;

export default ({ content, ...props }) => (
  <StyledParagraph {...props}>{ReactHtmlParser(content)}</StyledParagraph>
);
