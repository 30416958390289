import React, { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import { HorizontalContainer, VerticalContainer } from "../Layout";
import SkeletonImage from "../SkeletonImage";
import { B, EllipsisParagraph, H5, Label, Paragraph } from "../typography";
import responsive, { breakpoint } from "../../utils/responsive";
import { LanguageContext } from "../LanguageContext";
import placeholder from "../../assets/placeholder.jpg";
import { theme } from "../../theme";
import { useMediaQuery } from "react-responsive/src";
import { isPlural } from "../../utils/helpers";
import HtmlContent from "../HtmlContent";

const Container = styled(HorizontalContainer)`
  position: relative;
  max-width: 33rem;
  height: 17.8125rem;

  ${responsive.xs.andSmaller`
    max-width: 21.75rem;
    height: 11.5rem;
  `}

  margin: 0 0.5em;
  ${props =>
    props.border &&
    css`
      border-bottom: 6px solid ${props => props.theme.text.default.string()};
    `}

  box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.2);
  * {
    color: ${props => props.theme.text.emphasized.string()};
  }

  h5 {
    margin: 0;
    margin-bottom: 0.5em;
    flex: 2;
    font-size: 1.1rem;
  }

  label {
    flex: 1;
  }

  p {
    margin: unset;
    margin-top: 0;
    margin-bottom: auto;
    font-size: 0.9em;
  }

  > div {
    padding: 1em 2em;
    margin-bottom: 1rem;
    ${responsive.sm.andSmaller`
      margin-bottom: 0.9rem;
      padding: 1em;
    `};
  }
  ${props =>
    props.inverse &&
    css`
      background-blend-mode: multiply;
      mix-blend-mode: multiply;
      background-image: url(${props => props.image});
      background-size: cover;
      background-position: center;
      background-color: #423c31;
      * {
        color: ${props => props.theme.text.light.string()};
      }

      p {
        padding-top: 0.5em;
        border-top: 1px solid ${props => props.theme.text.subtle.string()};
      }
    `};
`;

const Span = styled.span`
  margin: 0.875em 0;
  width: 100%;
  ${responsive.xs.andSmaller`    
    margin-top: 0;
    white-space: no-wrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
  `}
  > h5 {
    ${responsive.xs.andSmaller`    
        margin-bottom: 0;
        font-size: 0.9rem;
    `}
  }

  > label {
    color: ${props => props.theme.text.default.string()};
    ${responsive.xs.andSmaller`
      white-space: nowrap;
      font-size: 0.8rem;
    `}
  }

  > p {
    :before {
      color: ${props => props.theme.text.default.string()} !important;
    }
    > label {
      color: ${props => props.theme.text.default.string()} !important;
      ${responsive.xs.andSmaller`    
        font-size: 1rem;
      `}
    }
  }
`;

const Wrapper = styled.div`
  cursor: pointer;
  transition: all 0.2s ease;
`;

const ContentContainer = styled(VerticalContainer)`
  overflow: auto;
  flex: 1 0 0;
  ${responsive.xs.andSmaller`
    font-size: 0.8em;
  `}
  > p {
    margin: auto 0;
  }
`;

const About = styled.div`
  margin-bottom: 0.875rem;
  ${responsive.sm.andSmaller`
    margin-bottom: 0;
    label, p {
      font-size: 0.6875rem;
    }
  `}
`;

const Banner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 2rem;
  line-height: 24px;
  background-color: ${props => props.theme.text.default.string()};
  padding: 0 1rem;
  color: white;
  text-transform: uppercase;

  text-align: center;
  right: 0;
  bottom: 0;

  ${responsive.sm.andSmaller`
    font-size: 11px;
    height: auto;  
  `}
`;

const Front = ({
  image,
  title,
  author,
  publishers,
  year,
  translate,
  description,
  isMobile
}) => (
  <Fragment>
    <SkeletonImage
      width={isMobile ? "7.25rem" : "12.5rem"}
      src={image}
      style={{ maxWidth: "12.5rem" }}
    />
    <ContentContainer>
      <Span style={{ marginBottom: 0 }}>
        <H5>{title}</H5>
        {isMobile ? (
          <Label>{author}</Label>
        ) : (
          <EllipsisParagraph lines={2}>
            <Label>{author}</Label>
          </EllipsisParagraph>
        )}
      </Span>
      <HtmlContent
        content={description}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      />
      <About>
        <Label size={"s"}>
          <B style={{ color: theme.colors.vibrant.string() }}>
            {translate("general.publishingYear")}
          </B>
          {` | ${year}`}
        </Label>
        <Paragraph>
          <B style={{ color: theme.colors.vibrant.string() }}>
            {translate(
              isPlural(publishers) ? "general.publishers" : "general.publisher"
            )}
          </B>
          {` | ${publishers}`}
        </Paragraph>
      </About>
      <Banner>{translate("general.limitedEdition")}</Banner>
    </ContentContainer>
  </Fragment>
);

const Back = ({ title, author, description }) => (
  <ContentContainer>
    <Span>
      <H5>{title}</H5>
      <Label>{author}</Label>
    </Span>
    <HtmlContent content={description} />
  </ContentContainer>
);

export default props => {
  const [flipped, setFlipped] = useState(false);
  const toggle = state => () => {
    if (flipped !== state) {
      setFlipped(state);
    }
  };

  const isMobile = useMediaQuery({
    query: `(max-device-width: ${breakpoint.sm}px)`
  });

  return (
    <LanguageContext.Consumer>
      {({ contentService }) => (
        <Wrapper onMouseEnter={toggle(true)} onMouseLeave={toggle(false)}>
          <Container
            inverse={flipped}
            image={(flipped ? props.imageBack : props.image) || placeholder}
            border={!flipped}
            onClick={toggle(!flipped)}
          >
            {!flipped ? (
              <Front
                {...props}
                translate={contentService.translate}
                isMobile={isMobile}
              />
            ) : (
              <Back {...props} isMobile={isMobile} />
            )}
          </Container>
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  );
};
