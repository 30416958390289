import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Label, Paragraph } from "./typography";
import responsive from "../utils/responsive";
import { CenteredContainer } from "./Layout";
import film_strip from "../assets/internal/film_strip.png";
import AnimatedNumber from "react-animated-number";
import useIsInViewport from "use-is-in-viewport";

const Container = styled(CenteredContainer)`
  justify-content: unset;
  text-align: center;
  padding: 2em 0;
  p {
    margin: 0;
  }

  ${responsive.sm.andSmaller`
    padding: 1rem 0;
    p {
      padding: 1rem 1rem;
    }
  `}

  label {
    font-size: 5.625em;
    ${responsive.md.andSmaller`
      font-size: 3em;
    `}
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 18em);
  align-items: center;

  > * {
    border-right: 1px solid ${props => props.theme.text.default.string()};
  }

  *:last-child {
    border: none;
  }

  padding: 2.75rem 8.125rem;
  height: 20.2125rem;

  background-image: url(${film_strip});
  align-items: center;
  justify-content: center;

  ${responsive.sm.andSmaller`
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    row-gap: 1em;
    padding: 2.75rem 2rem;
    *:nth-child(even) {
      border: none;
    }
  `}
`;

const Stat = ({ value, description }) => (
  <Container>
    <Label>
      <AnimatedNumber
        value={value}
        style={{
          transition: "0.8s ease-out",
          transitionProperty: "background-color, color, opacity"
        }}
        stepPrecision={0}
        duration={1000}
      />
    </Label>
    <Paragraph style={{ textTransform: "uppercase" }}>{description}</Paragraph>
  </Container>
);

const Stats = ({ items = [] }) => {
  const [triggered, trigger] = useState(false);
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 10 });

  useEffect(() => {
    if (isInViewport) {
      trigger(true);
    }
  }, [isInViewport, trigger]);

  return (
    <List ref={targetRef}>
      {triggered && items.map((item, i) => <Stat key={i} {...item} />)}
    </List>
  );
};

export default Stats;
