import React, { Fragment } from "react";
import NewsItem from "../components/items/NewsItem";
import Paper from "../components/containers/Paper";
import Slideshow from "../components/Slideshow";
import ItemGrid from "../components/containers/ItemGrid";
import Float from "../components/Float";
import floatImage from "../assets/images/ko-to-tamo-scenario-2.png";
import { Desktop } from "../utils/responsive";

export default ({
  linkProvider,
  items,
  slideshow,
  slideshowLinkProvider,
  contentService: { translate },
  isMobile
}) => (
  <Fragment>
    <Paper title={translate("news.title")} noMargin={!isMobile}>
      <Desktop>
        <Float src={floatImage} right />
      </Desktop>
      <ItemGrid
        Component={NewsItem}
        linkProvider={linkProvider}
        items={items}
        itemWidth={440}
        showAll={true}
      />
    </Paper>
    <Desktop>
      <Paper noMargin>
        <Slideshow
          items={slideshow}
          linkProvider={slideshowLinkProvider}
          itemWidth={360}
          itemPadding={32}
        />
      </Paper>
    </Desktop>
  </Fragment>
);
