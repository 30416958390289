import React, { Fragment, useState, useMemo, useEffect } from "react";
import styled, { css } from "styled-components";
import Button from "../Button";
import { LanguageContext } from "../LanguageContext";
import { useMeasure } from "react-use";
import responsive from "../../utils/responsive";
import { useLocation } from "@reach/router";
import findIndex from "lodash/findIndex";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.itemsPerRow},
    minmax(min-content, ${props => props.itemWidth}px)
  );
  ${props =>
    props.equal &&
    css`
      grid-template-columns: repeat(${props => props.itemsPerRow}, 1fr);
    `}

  grid-gap: 2.3125rem;
  grid-row-gap: 4rem;
  justify-content: center;

  ${responsive.md.andSmaller`
    grid-row-gap: 2.375rem;
    grid-gap: 1rem;
  `}
`;

export const MoreButton = styled(Button)`
  height: 3.75rem;
  width: 14rem;
  margin: auto;
  margin-top: 4em;
  ${responsive.md.andSmaller`
    height: 3.25rem;
    width: 12rem;
    font-size:
  `}

  ${responsive.sm.andSmaller`
    height: 3rem;
    width: 8rem;
    font-size:
  `}
`;

const Pager = ({
  items,
  initialRows,
  children,
  itemsPerRow,
  maxRows,
  showAll,
  MoreButtonComponent = MoreButton
}) => {
  const [rows, setRows] = useState(initialRows || 1);
  const location = useLocation();

  useEffect(() => {
    if (location.hash && location.hash.length > 1 && itemsPerRow > 1) {
      const hash = location.hash.replace("#", "");
      const focusIdx = findIndex(
        items,
        ({ id, slug }) => hash === slug || hash === id
      );

      if (focusIdx > -1) {
        const rows = Math.ceil((focusIdx + 1) / itemsPerRow);
        setRows(rows);
      }
    }
  }, [location, itemsPerRow, items]);

  const paged = useMemo(() => items.slice(0, itemsPerRow * rows), [
    items,
    rows,
    itemsPerRow
  ]);

  const showMore = () => {
    const prevScroll = document.body.getBoundingClientRect().top;
    setRows(rows + initialRows);
    setTimeout(
      () =>
        window.scrollTo({
          top: Math.abs(prevScroll),
          left: 0,
          behavior: "smooth"
        }),
      50
    );
  };

  const hasMore = useMemo(() => paged.length < items.length, [items, paged]);

  if (showAll) {
    return children({ items: items });
  }

  return (
    <LanguageContext.Consumer>
      {({ contentService }) => (
        <Fragment>
          {children({ items: paged })}
          {hasMore && (rows < maxRows || !maxRows) && (
            <MoreButtonComponent onClick={showMore}>
              {contentService.translate("actions.show_more")}
            </MoreButtonComponent>
          )}
        </Fragment>
      )}
    </LanguageContext.Consumer>
  );
};

export default ({
  items: allItems,
  Component,
  style,
  initialRows = 2,
  itemWidth = 200,
  maxRows,
  maxPerRow,
  showAll,
  perRowForce,
  MoreButtonComponent,
  ...rest
}) => {
  const [ref, { width }] = useMeasure();
  const itemsPerRowO =
    perRowForce || Math.max(Math.floor(width / (itemWidth + 74)), 1);
  const itemsPerRow = maxPerRow
    ? Math.min(itemsPerRowO, maxPerRow)
    : itemsPerRowO;

  return (
    <Pager
      items={allItems}
      initialRows={initialRows}
      itemsPerRow={itemsPerRow}
      maxRows={maxRows}
      showAll={showAll}
      MoreButtonComponent={MoreButtonComponent}
    >
      {({ items }) => (
        <Grid
          style={style}
          itemsPerRow={itemsPerRow}
          ref={ref}
          equal={!!perRowForce}
          itemWidth={itemWidth}
        >
          {items.map((item, i) => (
            <Component key={i} {...item} {...rest} />
          ))}
        </Grid>
      )}
    </Pager>
  );
};
