import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { ListItem, MenuItem } from "./MenuItem";
import styled from "styled-components";
import { withLanguage } from "../LanguageContext";
import responsive from "../../utils/responsive";

const variants = {
  open: {
    overflowY: "auto",
    margin: "auto",
    transition: { staggerChildren: 0.03, delayChildren: 0.2 },
    display: "flex",
    flexDirection: "column"
  },
  closed: {
    overflowY: "hidden",
    display: "none",
    margin: "none",
    transition: { staggerChildren: 0.02, staggerDirection: -1 }
  }
};

const List = styled(motion.ul)`
  margin: 0;
  padding: 0;
  opacity: 0.8;
  padding: 2rem;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  margin: auto;

  ${responsive.sm.andSmaller`
    flex: 1 0 0;
    justify-content: center;
    padding-bottom: 96px;
  `}
`;

const separatorVariants = {
  open: {
    y: 0,
    width: "100%",
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    width: 0,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const SeparatorItem = styled.div`
  width: 100%;
  height: 2px;
  background: #48171a;
`;

const Separator = () => (
  <ListItem
    variants={separatorVariants}
    style={{ padding: 0, marginBottom: 16 }}
  >
    <SeparatorItem />
  </ListItem>
);

const Navigation = ({ onClick, contentService: { navigation } }) => (
  <List variants={variants}>
    {navigation.map((route, i) => (
      <Fragment key={i}>
        <MenuItem
          to={route.to}
          label={route.label}
          onClick={onClick}
          disabled={route.disabled}
        />
        {i !== navigation.length - 1 && <Separator />}
      </Fragment>
    ))}
  </List>
);

export default withLanguage(Navigation);
