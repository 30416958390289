import React from "react";
import styled, { css, keyframes } from "styled-components";
import Lottie from "react-lottie";
import loadingData from "../assets/loading.json";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0.8;
  }
`;

const Rect = styled.div`
  background: ${props => props.color};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  ${props =>
    props.animate &&
    css`
      animation: ${pulse} 1.5s infinite alternate;
    `}
  flex: 1 0 auto;
  ${props =>
    props.fullScreen &&
    css`
      width: 100vw;
      height: 100vh;
    `};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const colors = ["#D4D4D7"];

const randomColor = () => colors[Math.floor(Math.random() * colors.length)];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet"
  }
};

export default ({
  width,
  height,
  fullScreen,
  color,
  style,
  animate = true,
  ...rest
}) => (
  <Rect
    width={width}
    height={height}
    color={color || randomColor()}
    fullScreen={fullScreen}
    style={style}
    animate={animate}
    {...rest}
  >
    {/*<Loading className="loading" src={loadingGif} alt={"loading"} />*/}
    <Lottie options={defaultOptions} width={"40%"} height={"30%"} />
  </Rect>
);
