export const SHOW_STATUS = {
  COMING_SOON: "COMING_SOON",
  IN_THEATERS: "IN_THEATERS",
  EX_YU: "EX_YU"
};

export const SHOW_STATUSES = [SHOW_STATUS.COMING_SOON, SHOW_STATUS.IN_THEATERS];

export const SHOW_TYPE = {
  MOVIE: "MOVIE",
  SERIES: "SERIES"
};

export const MOVIE_CATEGORY = {
  FEATURED: "FEATURED",
  DOCUMENTARY: "DOCUMENTARY"
};

export const DOCUMENTARY_TYPE = {
  DOCUMENTARY: "DOCUMENTARY",
  PERSONALITIES: "PERSONALITIES"
};

export const SHOW_TYPES = [SHOW_TYPE.MOVIE, SHOW_TYPE.SERIES];
export const DOCUMENTARY_TYPES = [
  DOCUMENTARY_TYPE.DOCUMENTARY,
  DOCUMENTARY_TYPE.PERSONALITIES
];

export const SHOW_BOX_OFFICE = {
  SOLD: "SOLD"
};

export const DISTRIBUTION_CATEGORY = {
  PRODUCTION: "PRODUCTION",
  ARCHIVE: "ARCHIVE"
};

export const AWARD = {
  BAFTA: "BAFTA",
  GOLDEN_GLOBE: "GOLDEN_GLOBE",
  EMMY: "EMMY",
  GRAMMY: "GRAMMY",
  HUGO: "HUGO",
  JUPITER: "JUPITER",
  OSCAR: "OSCAR",
  CANNES: "CANNES",
  CANNES2: "CANNES2",
  CANNES3: "CANNES3",
  FELIX: "FELIX",
  QUINZAINE: "QUINZAINE",
  GERMAN: "GERMAN",
  SATURN: "SATURN",
  OTHER: "OTHER"
};

export const AWARD_LABELS = {
  BAFTA: "Bafta",
  GOLDEN_GLOBE: "Golden Globe",
  EMMY: "Emmy",
  GRAMMY: "Grammy",
  HUGO: "Hugo",
  JUPITER: "Jupiter",
  OSCAR: "Oscar",
  CANNES: "Golden Palm",
  GERMAN: "German",
  SATURN: "Saturn",
  CANNES2: "Cannes2",
  CANNES3: "Cannes",
  FELIX: "Felix",
  QUINZAINE: "Quinzaine",
  OTHER: "OTHER"
};

export const AWARD_ICONS = {
  [AWARD.BAFTA]: require("./assets/awards/bafta.svg"),
  [AWARD.GOLDEN_GLOBE]: require("./assets/awards/golden_globe.svg"),
  [AWARD.EMMY]: require("./assets/awards/emmy.svg"),
  [AWARD.GRAMMY]: require("./assets/awards/grammy.svg"),
  [AWARD.HUGO]: require("./assets/awards/hugo.svg"),
  [AWARD.JUPITER]: require("./assets/awards/jupiter.svg"),
  [AWARD.OSCAR]: require("./assets/awards/oscar.svg"),
  [AWARD.CANNES]: require("./assets/awards/cannes.svg"),
  [AWARD.CANNES3]: require("./assets/awards/cannes.svg"),
  [AWARD.SATURN]: require("./assets/awards/saturn.svg"),
  [AWARD.GERMAN]: require("./assets/awards/German.svg"),
  [AWARD.CANNES2]: require("./assets/awards/logo_kan2.svg"),
  [AWARD.FELIX]: require("./assets/awards/felix.svg"),
  [AWARD.QUINZAINE]: require("./assets/awards/quinzaine.svg")
};
