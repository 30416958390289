import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    id: "long_1",
    order: 1,
    slug: "ko-to-tamo-peva",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1980,
    title: translate("long_1_title"),
    description: translate("long_1_description"),
    duration: "87 min",
    country: translate("long_1_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN SUPPORTING ROLE;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "GRAND BRONZE ARENA;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "ZLATNI VJENAC STUDIJA AWARD;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "MLADOST MAGAZINE AWARD;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "BEST SCREENPLAY;Film Screenplay Festival Vrnjačka Banja;1980"
      },
      {
        name: "OTHER",
        title: "CAR KONSTANTIN AWARD;Film Festival Niš;1980"
      },
      {
        name: "OTHER",
        title: "GOLDEN CLAPBOARD;Film Festival Niš;1980"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;Montreal World Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "GRAND PRIZE OF THE JURY;Montreal World Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;International Film Festival Panama;1981"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;International Film Festival Salamanca;1982"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;International Film Festival Rotterdam;1982"
      },
      {
        name: "OTHER",
        title:
          "SPECIAL JURY MENTION;Vevey Inrernational Funny Film Festival;1982"
      },
      {
        name: "OTHER",
        title:
          "PRIZE OF THE ECUMENICAL JURY;International Film Festival Mannheim;1982"
      },
      {
        name: "OTHER",
        title: "PHILIP MORRIS AWARD;Paris;1982"
      },
      {
        name: "OTHER",
        title: "GEORGE SADOUL FILM AWARD;Paris;1984"
      },
      {
        name: "OTHER",
        title: "Chicago International Film Festival;1981;"
      }
    ],
    genre: translate("long_1_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Slobodan Šijan"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dušan Kovačević"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Pavle Vuisić, Aleksandar Berček, Danilo Bata Stojković, Milivoje Mića Tomić, Dragan Nikolić, Neda Arnerić, Slavko Štimac, Taško Načić, Borislav Stjepanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mira Čohadžić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Vukobratović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vojislav Kostić"
      }
    ],
    director: "Slobodan Šijan",
    image: urlForMedia("movies/ko_to_tamo_peva/poster.jpg"),
    thumbnail: urlForMedia("movies/ko_to_tamo_peva/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ko_to_tamo_peva/cover.jpg"),
    image_description: urlForMedia("movies/ko_to_tamo_peva/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ko_to_tamo_peva/ko_2.jpg")
      },
      {
        image: urlForMedia("movies/ko_to_tamo_peva/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/ko_to_tamo_peva/ko_1.jpg")
      },
      {
        image: urlForMedia("movies/ko_to_tamo_peva/ko_4.jpg")
      },
      {
        image: urlForMedia("movies/ko_to_tamo_peva/cover.jpg")
      },
      {
        image: urlForMedia("movies/ko_to_tamo_peva/ko_3.jpg")
      }
    ]
  },
  {
    id: "long_2",
    order: 2,
    slug: "maratonci-trce-pocasni-krug",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1982,
    title: translate("long_2_title"),
    description: translate("long_2_description"),
    duration: "96 min",
    country: translate("long_2_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1982"
      },
      {
        name: "OTHER",
        title: "BEST DIALOGUE;Film Screenplay Festival Vrnjačka Banja;1982"
      },
      {
        name: "OTHER",
        title: "SPECIAL JURY PRIZE;World Film Festival Montreal;1982"
      },
      {
        name: "OTHER",
        title: "BEST SCREENPLAY;Marseille International Film Festival;1982"
      },
      {
        name: "OTHER",
        title: "Cairo International Film Festival;1982"
      },
      {
        name: "OTHER",
        title: "Flandres International Film Festival Ghent;1982"
      },
      {
        name: "OTHER",
        title: "LA Film Festival;1982"
      },
      {
        name: "OTHER",
        title: "Montpellier International Festival of Mediterranean Film;1982;"
      }
    ],
    genre: translate("long_2_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Slobodan Šijan"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dušan Kovačević"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Milivoje Mića Tomić, Mija Aleksić, Pavle Vuisić, Danilo Bata Stojković, Bogdan Diklić, Zoran Radmilović, Bora Todorović, Jelisaveta Seka Sablić, Melita Bihalji, Fahro Konjhodžić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mira Čohadžić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Vukobratović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Slobodan Šijan",
    image: urlForMedia("movies/maratonci_trce_pocasni_krug/poster.jpg"),
    thumbnail: urlForMedia("movies/maratonci_trce_pocasni_krug/thumbnail.jpg"),
    image_cover: urlForMedia("movies/maratonci_trce_pocasni_krug/cover.jpg"),
    image_description: urlForMedia(
      "movies/maratonci_trce_pocasni_krug/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/maratonci_1.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/maratonci_3.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/cover.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/maratonci_5.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/maratonci_6.jpg")
      },
      {
        image: urlForMedia("movies/maratonci_trce_pocasni_krug/maratonci_2.jpg")
      }
    ]
  },
  {
    id: "long_3",
    order: 3,
    slug: "kad-budem-mrtav-i-beo",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_3_title"),
    description: translate("long_3_description"),
    duration: "80 min",
    country: translate("long_3_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND GOLDEN ARENA;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "BEST DIRECTOR;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "FILM OF THE YEAR;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title:
          "FRIPRESCI CRITICS AWARD;International Film Festival Karlovy Vary;1968"
      },
      {
        name: "OTHER",
        title: "SPECIAL JURY PRIZE;Athens International Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "DON QUIJOTE AWARD;MOOOV Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Film Festival Nis;1968"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS;Film Festival Nis;1968;"
      }
    ],
    genre: translate("long_3_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mihailo Đurič, Dušan Perković, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragan Nikolić, Ružica Sokić, Neda Spasojević, Dara Čalenić, Slobodan Aligrudić, Severin Bijelić, Nikola Milić, Zorica Šumadinac"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Živojin Pavlović",
    image: urlForMedia("movies/kad_budem_mrtav_i_beo/poster.jpg"),
    thumbnail: urlForMedia("movies/kad_budem_mrtav_i_beo/thumbnail.jpg"),
    image_cover: urlForMedia("movies/kad_budem_mrtav_i_beo/cover.jpg"),
    image_description: urlForMedia("movies/kad_budem_mrtav_i_beo/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/kad_budem_1.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/kad_budem_5.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/cover.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/kad_budem_2.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/kad_budem_4.jpg")
      },
      {
        image: urlForMedia("movies/kad_budem_mrtav_i_beo/kad_budem_3.jpg")
      }
    ]
  },
  {
    id: "long_4",
    order: 4,
    slug: "zaseda",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1969,
    title: translate("long_4_title"),
    description: translate("long_4_description"),
    duration: "80 min",
    country: translate("long_4_country"),
    awards: [
      {
        name: "OTHER",
        title: "SPECIAL JURY AWARD;Pula Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "ARS SEPTIMA AWARD;Pula Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "SPECIAL DIPLOMA;Pula Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE FOR ACTOR;Film Festival Niš;1969"
      },
      {
        name: "OTHER",
        title: "GOLDEN LION;Venice International Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "Sydney Film Festival;1970"
      },
      {
        name: "OTHER",
        title: "Hong Kong International Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Seattle International Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "International Istanbul Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Moscow International Film Festival;2001;"
      }
    ],
    genre: translate("long_4_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Mihailo Đurić, Dušan Perković, Aleksandar Radulović, Dragan Terzić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Milena Dravić, Ivica Vidović, Severin Bijelić, Slobodan Aligrudić, Pavle Vuisić, Dragomir Felba, Marija Milutinović, Mirjana Blašković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Živojin Pavlović",
    image: urlForMedia("movies/zaseda/poster.jpg"),
    thumbnail: urlForMedia("movies/zaseda/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zaseda/cover.jpg"),
    image_description: urlForMedia("movies/zaseda/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/zaseda/zaseda_5.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/zaseda_4.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/cover.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/zaseda_2.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/zaseda_3.jpg")
      },
      {
        image: urlForMedia("movies/zaseda/zaseda_1.jpg")
      }
    ]
  },
  {
    id: "long_5",
    order: 5,
    slug: "budjenje-pacova",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_5_title"),
    description: translate("long_5_description"),
    duration: "70 min",
    country: translate("long_5_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND SILVER ARENA;Pula Film Festival;1967"
      },
      {
        name: "OTHER",
        title:
          "SILVER BEAR FOR BEST DIRECTOR;Berlin International Film Festival;1967"
      },
      {
        name: "OTHER",
        title: "EKRAN MAGAZINE AWARD;Magazine for Film and Television;1967;"
      }
    ],
    genre: translate("long_5_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Gordan Mihić, Ljubiša Kozomora"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mihailo Đurić, Duško Ercegović, Dušan Perković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slobodan Perović, Dušica Žegarac, Severin Bijelić, Mirjana Blašković, Mića Tomić, Nikola Milić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Natko Devčić"
      }
    ],
    director: "Živojin Pavlović",
    image: urlForMedia("movies/budjenje_pacova/poster.jpg"),
    thumbnail: urlForMedia("movies/budjenje_pacova/thumbnail.jpg"),
    image_cover: urlForMedia("movies/budjenje_pacova/cover.jpg"),
    image_description: urlForMedia("movies/budjenje_pacova/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/budjenje_pacova/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/budjenje_pacova/budjenje_1.jpg")
      },
      {
        image: urlForMedia("movies/budjenje_pacova/budjenje_4.jpg")
      },
      {
        image: urlForMedia("movies/budjenje_pacova/budjenje_2.jpg")
      },
      {
        image: urlForMedia("movies/budjenje_pacova/budjenje_3.jpg")
      },
      {
        image: urlForMedia("movies/budjenje_pacova/cover.jpg")
      }
    ]
  },
  {
    id: "long_6",
    order: 6,
    slug: "divlje-seme",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_6_title"),
    description: translate("long_6_description"),
    duration: "85 min",
    country: translate("long_6_country"),
    genre: translate("long_6_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Kokan Rakonjac"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Lazić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Dušan Perković, Milenko Popović, Slobodan Roganović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljuba Tadić, Jelena Žigon, Severin Bjelić, Milan Srdoč, Svetolik Nikačević, Sima Janićijević"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Petković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Zvonimir Avramović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Bjenjaš"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Kokan Rakonjac",
    image: urlForMedia("movies/divlje_seme/poster.jpg"),
    thumbnail: urlForMedia("movies/divlje_seme/thumbnail.jpg"),
    image_cover: urlForMedia("movies/divlje_seme/thumbnail.jpg"),
    image_description: urlForMedia("movies/divlje_seme/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "long_7",
    order: 7,
    slug: "hasanaginica",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_7_title"),
    description: translate("long_7_description"),
    duration: "81 min",
    country: translate("long_7_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST MUSIC;Pula Film Festival;1967;"
      }
    ],
    genre: translate("long_7_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Mića Popović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Mića Popović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Dušan Perković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Milena Dravić, Rade Marković, Relja Bašić, Rastislav Jović, Đorđe Nenadović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Marković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Vera Popović Božičković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Bjenjaš"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Mića Popović",
    image: urlForMedia("movies/hasanaginica/poster.jpg"),
    thumbnail: urlForMedia("movies/hasanaginica/thumbnail.jpg"),
    image_cover: urlForMedia("movies/hasanaginica/cover.jpg"),
    image_description: urlForMedia("movies/hasanaginica/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/hasanaginica/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/hasanaginica/hasanaginica_4.jpg")
      },
      {
        image: urlForMedia("movies/hasanaginica/cover.jpg")
      },
      {
        image: urlForMedia("movies/hasanaginica/hasanaginica_5.jpg")
      },
      {
        image: urlForMedia("movies/hasanaginica/hasanaginica_1.jpg")
      }
    ]
  },
  {
    id: "long_8",
    order: 8,
    slug: "koraci-kroz-magle",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_8_title"),
    description: translate("long_8_description"),
    duration: "74 min",
    country: translate("long_8_country"),
    genre: translate("long_8_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žorž Skrigin"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Mihailo Renovčević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Salko Bojčić, Mihailo Đurić, Dušan Ercegović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Husein Čokić, Bora Todorović, Milan Srdoč, Vojislav Mirić, Tanasije Uzunović, Nikola Simić, Milan Panić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Sekula Banović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Radoslava Spahić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Žorž Skrigin",
    image: urlForMedia("movies/koraci_kroz_magle/poster.jpg"),
    thumbnail: urlForMedia("movies/koraci_kroz_magle/thumbnail.jpg"),
    image_cover: urlForMedia("movies/koraci_kroz_magle/cover.jpg"),
    image_description: urlForMedia("movies/koraci_kroz_magle/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/koraci_kroz_magle/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/koraci_kroz_magle/koraci_3.jpg")
      },
      {
        image: urlForMedia("movies/koraci_kroz_magle/koraci_1.jpg")
      },
      {
        image: urlForMedia("movies/koraci_kroz_magle/cover.jpg")
      },
      {
        image: urlForMedia("movies/koraci_kroz_magle/koraci_2.jpg")
      }
    ]
  },
  {
    id: "long_9",
    order: 9,
    slug: "nemirni",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_9_title"),
    description: translate("long_9_description"),
    duration: "75 min",
    country: translate("long_9_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER ARENA FOR BEST ACTRESS;Pula Film Festival;1967"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE FOR BEST ACTOR;Film Festival Niš;1967;"
      }
    ],
    genre: translate("long_9_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Kokan Rakonjac"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dušan Savković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Dušan Perković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Špela Rozin, Milena Dravić, Marko Todorović, Dušica Žegarac, Janez Vrhovec, Vojislav Mirić, Mića Orlović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Miodrag Hadžić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Olga Rovnan"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Jelena Bjenjaš"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branko Perak"
      }
    ],
    director: "Kokan Rakonjac",
    image: urlForMedia("movies/nemirni/poster.jpg"),
    thumbnail: urlForMedia("movies/nemirni/thumbnail.jpg"),
    image_cover: urlForMedia("movies/nemirni/cover.jpg"),
    image_description: urlForMedia("movies/nemirni/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/nemirni/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/nemirni/nemirni_2.jpg")
      },
      {
        image: urlForMedia("movies/nemirni/nemirni_5.jpg")
      },
      {
        image: urlForMedia("movies/nemirni/nemirni_4.jpg")
      },
      {
        image: urlForMedia("movies/nemirni/cover.jpg")
      },
      {
        image: urlForMedia("movies/nemirni/nemirni_1.jpg")
      }
    ]
  },
  {
    id: "long_10",
    order: 10,
    slug: "noz",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1967,
    title: translate("long_10_title"),
    description: translate("long_10_description"),
    duration: "85 min",
    country: translate("long_10_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER ARENA FOR;BEST ACTRESS"
      },
      {
        name: "OTHER",
        title: "Pula Film Festival"
      },
      {
        name: "OTHER",
        title: "1967"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE FOR BEST ACTOR"
      },
      {
        name: "OTHER",
        title: "Film Festival Niš"
      },
      {
        name: "OTHER",
        title: "1967;"
      }
    ],
    genre: translate("long_10_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Božidar Mitrović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Velimir Bata Živojinović, Ljubiša Samardžić, Vera Čukić, Rade Marković, Mija Aleksić, Dara Čalenić, Vesna Krajina, Janez Vrhovec"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Ivatović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Jovan Radić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Miljenko Prohaska"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/noz/poster.jpg"),
    thumbnail: urlForMedia("movies/noz/thumbnail.jpg"),
    image_cover: urlForMedia("movies/noz/cover.jpg"),
    image_description: urlForMedia("movies/noz/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/noz/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/noz/cover.jpg")
      }
    ]
  },
  {
    id: "long_11",
    order: 11,
    slug: "brat-doktora-homera",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_11_title"),
    description: translate("long_11_description"),
    duration: "84 min",
    country: translate("long_11_country"),
    genre: translate("long_11_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Boško Mitrović, Aco Petrovski"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Velimir Bata Živojinović, Vojislav Mirić, Ljuba Tadić, Pavle Vuisić, Jelena Žigon, Zdravka Krstulović, Petre Prličko, Abdurahman Šalja, Stojan Aranđelović, Jovan Miličević,"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Ivatović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Zagorka Stojanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vanja Bjenjaš"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Bojan Adamič"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/brat_dr._homera/brat_2.jpg"),
    thumbnail: urlForMedia("movies/brat_dr._homera/brat_2.jpg"),
    image_cover: urlForMedia("movies/brat_dr._homera/brat_2.jpg"),
    image_description: urlForMedia("movies/brat_dr._homera/brat_1.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/brat_dr._homera/brat_2.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/brat_1.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/brat_6.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/brat_5.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/brat_4.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/brat_3.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/thumbnail.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/cover.jpg")
      },
      {
        image: urlForMedia("movies/brat_dr._homera/poster.jpg")
      }
    ]
  },
  {
    id: "long_12",
    order: 12,
    slug: "operacija-beograd",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_12_title"),
    description: translate("long_12_description"),
    duration: "92 min",
    country: translate("long_12_country"),
    genre: translate("long_12_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Šušnjić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dušan Bulajić, Aleksandar Gavrić, Velimir Bata Živojinović, Dušica Žegarac, Faruk Begoli, Rastko Tadić, Pavle Bogatinčević, Stojan Aranđelović, Marko Todorović, Zdravka Krstulović, Severin Bijelić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Ivatović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Zagorka Stojanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/operacija_beograd/poster.jpg"),
    thumbnail: urlForMedia("movies/operacija_beograd/thumbnail.jpg"),
    image_cover: urlForMedia("movies/operacija_beograd/cover.jpg"),
    image_description: urlForMedia("movies/operacija_beograd/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/operacija_beograd/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/operacija_beograd/operacija_2.jpg")
      },
      {
        image: urlForMedia("movies/operacija_beograd/operacija_5.jpg")
      },
      {
        image: urlForMedia("movies/operacija_beograd/operacija_1.jpg")
      },
      {
        image: urlForMedia("movies/operacija_beograd/cover.jpg")
      },
      {
        image: urlForMedia("movies/operacija_beograd/operacija_3.jpg")
      }
    ]
  },
  {
    id: "long_13",
    order: 13,
    slug: "pusti-snovi",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_13_title"),
    description: translate("long_13_description"),
    duration: "96 min",
    country: translate("long_13_country"),
    genre: translate("long_13_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Soja Jovanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Borivoje Razić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Mija Aleksić, Mira Banjac, Uglješa Rajićević, Nuša Marović, Mića Tomić, Ana Krasojević, Arsen Dedić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Nenad Jovičić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miomir Denić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Pavlović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Milanka Nanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Miodrag Ilić"
      }
    ],
    director: "Soja Jovanović",
    image: urlForMedia("movies/pusti_snovi/poster.jpg"),
    thumbnail: urlForMedia("movies/pusti_snovi/thumbnail.jpg"),
    image_cover: urlForMedia("movies/pusti_snovi/cover.jpg"),
    image_description: urlForMedia("movies/pusti_snovi/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/pusti_snovi/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/pusti_snovi/pusti_2.jpg")
      },
      {
        image: urlForMedia("movies/pusti_snovi/cover.jpg")
      },
      {
        image: urlForMedia("movies/pusti_snovi/pusti_6.jpg")
      },
      {
        image: urlForMedia("movies/pusti_snovi/pusti_5.jpg")
      },
      {
        image: urlForMedia("movies/pusti_snovi/pusti_4.jpg")
      }
    ]
  },
  {
    id: "long_14",
    order: 14,
    slug: "sarajevski-atentat",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_14_title"),
    description: translate("long_14_description"),
    duration: "88 min",
    country: translate("long_14_country"),
    genre: translate("long_14_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Fadil Hadžić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Fadil Hadžić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Lućina Vinjicka, Bert Sotlar, Janez Rohaček, Predrag Finci, Svetolik Nikačević, Faruk Begoli, Maks Furijan, Majda Potokar, Franek Trefalt"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Frano Vodopivec"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Jelisaveta Gobecki"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Radojka Tanhofer"
      }
    ],
    director: "Fadil Hadžić",
    image: urlForMedia("movies/sarajevski_atentat/poster.jpg"),
    thumbnail: urlForMedia("movies/sarajevski_atentat/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sarajevski_atentat/cover.jpg"),
    image_description: urlForMedia("movies/sarajevski_atentat/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sarajevski_atentat/atentat_7.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/atentat_4.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/atentat_1.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/antentat_2.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/atentat_3.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/cover.jpg")
      },
      {
        image: urlForMedia("movies/sarajevski_atentat/atentat_6.jpg")
      }
    ]
  },
  {
    id: "long_15",
    order: 15,
    slug: "vuk-sa-prokletija",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_15_title"),
    description: translate("long_15_description"),
    duration: "86 min",
    country: translate("long_15_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN GATE OF PULA AUDIENCE AWARD;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A LEADING ROLE;Pula Film Festival;1968"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE FOR BEST ACTOR;Film Festival Niš;1968;"
      }
    ],
    genre: translate("long_15_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Abdurahman Šalja, Murrteza Peza"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljuba Tadić, Abdurahman Šalja, Josif Tatić, Vesna Krajina, Faruk Begoli, Darko Damevski, Branko Pleša, Melihate Ajeti, Dragomir Felba"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Ivatović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlatko Gilić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nerudin Loxha"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Redžo Mulić"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/vuk_sa_prokletija/poster.jpg"),
    thumbnail: urlForMedia("movies/vuk_sa_prokletija/thumbnail.jpg"),
    image_cover: urlForMedia("movies/vuk_sa_prokletija/cover.jpg"),
    image_description: urlForMedia("movies/vuk_sa_prokletija/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/vuk_sa_prokletija/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/vuk_sa_prokletija/vuk_4.jpg")
      },
      {
        image: urlForMedia("movies/vuk_sa_prokletija/vuk_3.jpg")
      },
      {
        image: urlForMedia("movies/vuk_sa_prokletija/cover.jpg")
      },
      {
        image: urlForMedia("movies/vuk_sa_prokletija/vuk_2.jpg")
      },
      {
        image: urlForMedia("movies/vuk_sa_prokletija/vuk_1.jpg")
      }
    ]
  },
  {
    id: "long_16",
    order: 16,
    slug: "u-raskoraku",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1968,
    title: translate("long_16_title"),
    description: translate("long_16_description"),
    duration: "83 min",
    country: translate("long_16_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER ARENA FOR LEADING ACTRESS;Pula Film Festival;1967;"
      }
    ],
    genre: translate("long_16_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Milenko Štrbac"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Đorđe Milojević, Duško Ercegović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragomir Bojanić-Gidra, Gizela Vuković, Dušan Janičijević, Ljuba Tadić, Ljiljana Lasić, Jovan Janićijević, Dragomir Felba, Ljubiša Samardžić, Stanoje Dušanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography_plural"),
        type: "role_scenography",
        members: "Vlastimir Gavrik,Vladislav Lasić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Anđelka Slijepčević"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Mirjana Mitić"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Dušan Radić"
      }
    ],
    director: "Milenko Štrbac",
    image: urlForMedia("movies/u_raskoraku/poster.jpg"),
    thumbnail: urlForMedia("movies/u_raskoraku/thumbnail.jpg"),
    image_cover: urlForMedia("movies/u_raskoraku/cover.jpg"),
    image_description: urlForMedia("movies/u_raskoraku/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/u_raskoraku/u_raskoraku_1.jpg")
      },
      {
        image: urlForMedia("movies/u_raskoraku/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/u_raskoraku/u_raskoraku_4.jpg")
      },
      {
        image: urlForMedia("movies/u_raskoraku/cover.jpg")
      },
      {
        image: urlForMedia("movies/u_raskoraku/u_raskoraku_3.jpg")
      },
      {
        image: urlForMedia("movies/u_raskoraku/u_raskoraku_2.jpg")
      }
    ]
  },
  {
    id: "long_17",
    order: 17,
    slug: "kad-cujes-zvona",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1969,
    title: translate("long_17_title"),
    description: translate("long_17_description"),
    duration: "92 min",
    country: translate("long_17_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A LEADING ROLE;Pula Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "GOLDEN GATE OF PULA AUDIENCE AWARD;Pula Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE FOR ACTOR;Film Festival Niš;1969"
      },
      {
        name: "OTHER",
        title: "VLADIMIR NAZOR AWARD;Zagreb Film Festival;1969;"
      }
    ],
    genre: translate("long_17_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Antun Vrdoljak"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Antun Vrdoljak"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Sulejman Kapić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Boris Dvornik, Pavle Vuisić, Boris Buzančić, Fabijan Šovagović, Branka Vrdoljak, Ivica Vidović, Antun Nalis, Izet Hajdarhodžić, Branko Špoljar"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Frano Vodopivec"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Željko Senečić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Ružica Ištvanović Fanelli"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Radojka Tanhofer"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Anđelko Klobučar"
      }
    ],
    director: "Antun Vrdoljak",
    image: urlForMedia("movies/kad_cujes_zvona/poster.jpg"),
    thumbnail: urlForMedia("movies/kad_cujes_zvona/thumbnail.jpg"),
    image_cover: urlForMedia("movies/kad_cujes_zvona/cover.jpg"),
    image_description: urlForMedia("movies/kad_cujes_zvona/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/kad_cujes_zvona/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/kad_cujes_zvona/kad_cujes_4.jpg")
      },
      {
        image: urlForMedia("movies/kad_cujes_zvona/kad_cujes_5.jpg")
      },
      {
        image: urlForMedia("movies/kad_cujes_zvona/kad_cujes_3.jpg")
      },
      {
        image: urlForMedia("movies/kad_cujes_zvona/cover.jpg")
      }
    ]
  },
  {
    id: "long_18",
    order: 18,
    slug: "krvava-bajka",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1969,
    title: translate("long_18_title"),
    description: translate("long_18_description"),
    duration: "88 min",
    country: translate("long_18_country"),
    genre: translate("long_18_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Branimir Janković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Branimir Janković"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Petar Milojević, Dušan Ercegović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Zorica Miladinović, Mira Stupica, Mija Aleksić, Slobodan Mladenović, Dragan Petrović, Velimir Bata Živojinović, Miodrag Dabić, Zoran Petrović, Ljuba Tadić, Franek Trefalt, Vasa Pantelić, Ljubiša Jovanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Jirži Vujček"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mirjana Ostojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljerka Stanojević"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vojislav Kostić"
      }
    ],
    director: "Branimir Janković",
    image: urlForMedia("movies/krvava_bajka/poster.jpg"),
    thumbnail: urlForMedia("movies/krvava_bajka/thumbnail.jpg"),
    image_cover: urlForMedia("movies/krvava_bajka/cover.jpg"),
    image_description: urlForMedia("movies/krvava_bajka/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/krvava_bajka/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/krvava_bajka/krvava_2.jpg")
      },
      {
        image: urlForMedia("movies/krvava_bajka/krvava_1.jpg")
      },
      {
        image: urlForMedia("movies/krvava_bajka/krvava_3.jpg")
      },
      {
        image: urlForMedia("movies/krvava_bajka/cover.jpg")
      }
    ]
  },
  {
    id: "long_19",
    order: 19,
    slug: "silom-otac",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1969,
    title: translate("long_19_title"),
    description: translate("long_19_description"),
    duration: "86 min",
    country: translate("long_19_country"),
    genre: translate("long_19_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Soja Jovanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Borislav Mihailović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Milo Vukadinović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Miodrag Petrović Čkalja, Dragutin Dobričanin, Mihajlo Bata Paskaljević, Dara Čalenić, Ana Krasojević, Mirjana Nikolić, Milutin Mičović, Radmila Guteša, Mira Banjac, Ljuba Tadić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Nenad Jovičić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miomir Denić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Pavlović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Milanka Nanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Miodrag Ilić"
      }
    ],
    director: "Soja Jovanović",
    image: urlForMedia("movies/silom_otac/poster.jpg"),
    thumbnail: urlForMedia("movies/silom_otac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/silom_otac/cover.jpg"),
    image_description: urlForMedia("movies/silom_otac/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/silom_otac/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/silom_5.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/cover.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/silom_2.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/silom_3.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/silom_1.jpg")
      },
      {
        image: urlForMedia("movies/silom_otac/silom_4.jpg")
      }
    ]
  },
  {
    id: "long_20",
    order: 20,
    slug: "ubistvo-na-podmukao-i-svirep-nacin-i-iz-niskih-pobuda",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1969,
    title: translate("long_20_title"),
    description: translate("long_20_description"),
    duration: "88 min",
    country: translate("long_20_country"),
    genre: translate("long_20_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Žika Mitrović, Milan Miličević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Boško Mitrović, Marko Vrdoljak"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Mihailo Miša Janketić, Irena Prosen, Zoran Milosavljević, Petar Kralj, Mija Aleksić, Aleksandar Gavrić, Marko Todorović, Dušan Perković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Nikola Majdak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Zagorka Stojanović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Zagorka Stojanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Darko Kraljić"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/ubistvo_na_podmukao_i_svirep_nacin/poster.jpg"),
    thumbnail: urlForMedia(
      "movies/ubistvo_na_podmukao_i_svirep_nacin/thumbnail.jpg"
    ),
    image_cover: urlForMedia(
      "movies/ubistvo_na_podmukao_i_svirep_nacin/cover.jpg"
    ),
    image_description: urlForMedia(
      "movies/ubistvo_na_podmukao_i_svirep_nacin/ubistvo4.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia(
          "movies/ubistvo_na_podmukao_i_svirep_nacin/ubistvo3.jpg"
        )
      },
      {
        image: urlForMedia(
          "movies/ubistvo_na_podmukao_i_svirep_nacin/ubistvo4.jpg"
        )
      },
      {
        image: urlForMedia(
          "movies/ubistvo_na_podmukao_i_svirep_nacin/ubistvo1.jpg"
        )
      },
      {
        image: urlForMedia(
          "movies/ubistvo_na_podmukao_i_svirep_nacin/cover.jpg"
        )
      },
      {
        image: urlForMedia(
          "movies/ubistvo_na_podmukao_i_svirep_nacin/ubistvo2.jpg"
        )
      }
    ]
  },
  {
    id: "long_21",
    order: 21,
    slug: "opklada",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1970,
    title: translate("long_21_title"),
    description: translate("long_21_description"),
    duration: "81 min",
    country: translate("long_21_country"),
    awards: [
      {
        name: "OTHER",
        title: "BRONZE ARENA;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A LEADING ROLE;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title:
          "DIPLOMA FOR BEST ACTOR IN A SUPPORTING ROLE;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title: "PRIZE FOR ACTRESS;Film Festival Niš;1971;"
      }
    ],
    genre: translate("long_21_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mihailo Đurić, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dušica Žegarac, Boris Dvornik, Velimir Bata Živojinović, Pavle Vuisić, Dragutin Dobričanin, Dragomir Felba"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rudi Vaupotič"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/opklada/poster.jpg"),
    thumbnail: urlForMedia("movies/opklada/thumbnail.jpg"),
    image_cover: urlForMedia("movies/opklada/cover.jpg"),
    image_description: urlForMedia("movies/opklada/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/opklada/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/opklada/cover.jpg")
      }
    ]
  },
  {
    id: "long_22",
    order: 22,
    slug: "siroma-sam-al-sam-besan",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1970,
    title: translate("long_22_title"),
    description: translate("long_22_description"),
    duration: "81 min",
    country: translate("long_22_country"),
    genre: translate("long_22_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljubiša Samardžić, Ana Matić, Dara Čalenić, Branislav Ciga Milenković, Nikola Milić,  Ksenija Lazarov, Miroslava Bobić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Ivica Rajković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Srbislav Vlaški"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Dragoljub Ivkov",
    image: urlForMedia("movies/siroma_sam_al_sam_besan/poster.jpg"),
    thumbnail: urlForMedia("movies/siroma_sam_al_sam_besan/thumbnail.jpg"),
    image_cover: urlForMedia("movies/siroma_sam_al_sam_besan/siroma2.jpg"),
    image_description: urlForMedia(
      "movies/siroma_sam_al_sam_besan/thumbnail.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/siroma_sam_al_sam_besan/siroma2.jpg")
      },
      {
        image: urlForMedia("movies/siroma_sam_al_sam_besan/siroma1.jpg")
      },
      {
        image: urlForMedia("movies/siroma_sam_al_sam_besan/siroma3.jpg")
      },
      {
        image: urlForMedia("movies/siroma_sam_al_sam_besan/siroma4.jpg")
      }
    ]
  },
  {
    id: "long_23",
    order: 23,
    slug: "crveno-klasje",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1970,
    title: translate("long_23_title"),
    description: translate("long_23_description"),
    duration: "85 min",
    country: translate("long_23_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND GOLDEN ARENA FOR BEST FILM;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title: "SILVER ARENA FOR LEADING ACTOR;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DOP;Pula Film Festival;1971"
      },
      {
        name: "OTHER",
        title: "CARICA TEODORA AWARD;Film Festival Niš;1971;"
      }
    ],
    genre: translate("long_23_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Majda Potokar, Rade Šerbedžija, Irena Glonar, Majda Grbac, Arnold Tovornik, Jože Zupan"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Živojin Pavlović",
    image: urlForMedia("movies/crveno_klasje/poster.jpg"),
    thumbnail: urlForMedia("movies/crveno_klasje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/crveno_klasje/cover.jpg"),
    image_description: urlForMedia("movies/crveno_klasje/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/crveno_klasje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/crveno_klasje/klasje_5.jpg")
      },
      {
        image: urlForMedia("movies/crveno_klasje/klasje_3.jpg")
      },
      {
        image: urlForMedia("movies/crveno_klasje/klasje_2.jpg")
      },
      {
        image: urlForMedia("movies/crveno_klasje/klasje_4.jpg")
      },
      {
        image: urlForMedia("movies/crveno_klasje/cover.jpg")
      }
    ]
  },
  {
    id: "long_24",
    order: 24,
    slug: "devojka-sa-kosmaja",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1972,
    title: translate("long_24_title"),
    description: translate("long_24_description"),
    duration: "82 min",
    country: translate("long_24_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ROSE;Karlovy Vary International Film Festival;1972;"
      }
    ],
    genre: translate("long_24_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragovan Jovanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragovan Jovanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Momčilo Dodočić, Petar Galović, Ljuben Mancev, Radmilo Stefanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljudmila Lisina, Mihailo Kostić, Nikola Simić, Mihailo Miša Janketić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Jirži Vujček"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vladislav Lašić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Milica Aleksandrov"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Darko Kraljić"
      }
    ],
    director: "Dragovan Jovanović",
    image: urlForMedia("movies/devojka_sa_kosmaja/poster.jpg"),
    thumbnail: urlForMedia("movies/devojka_sa_kosmaja/thumbnail.jpg"),
    image_cover: urlForMedia("movies/devojka_sa_kosmaja/cover.jpg"),
    image_description: urlForMedia("movies/devojka_sa_kosmaja/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/devojka_4.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/devojka_6.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/devojka_2.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/devojka_1.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/cover.jpg")
      },
      {
        image: urlForMedia("movies/devojka_sa_kosmaja/devojka_5.jpg")
      }
    ]
  },
  {
    id: "long_25",
    order: 25,
    slug: "i-bog-stvori-kafansku-pevacicu",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1972,
    title: translate("long_25_title"),
    description: translate("long_25_description"),
    duration: "89 min",
    country: translate("long_25_country"),
    genre: translate("long_25_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Jovan Živanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Jovan Živanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Vera Čukić, Lepa Lukić, Velimir Bata Živojinović, Žika Milenković, Pavle Vuisić, Slobodan Aligrudić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Huan Karlos Fero"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Olga Tiran"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Olga Tiran"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Huan Karlos Fero"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Darko Kraljić"
      }
    ],
    director: "Jovan Živanović",
    image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/poster.jpg"),
    thumbnail: urlForMedia(
      "movies/i_bog_stvori_kafansku_pevacicu/thumbnail.jpg"
    ),
    image_cover: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/cover.jpg"),
    image_description: urlForMedia(
      "movies/i_bog_stvori_kafansku_pevacicu/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_9.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_4.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_10.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_8.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_6.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/cover.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_3.jpg")
      },
      {
        image: urlForMedia("movies/i_bog_stvori_kafansku_pevacicu/i_bog_7.jpg")
      }
    ]
  },
  {
    id: "long_26",
    order: 26,
    slug: "kisa",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1972,
    title: translate("long_26_title"),
    description: translate("long_26_description"),
    duration: "70 min",
    country: translate("long_26_country"),
    genre: translate("long_26_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mila Borović, Mihajlo Ćirin, Milenko Stanković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dušica Žegarac, Dragomir Čumić, Irena Prosen, Nada Kasapić, Radmilo Čurčić, Divna Djoković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Nikola Majdak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milan Todorović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Divna Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Mirjana Mitić"
      }
    ],
    director: "Puriša Đorđević",
    image: urlForMedia("movies/kisa/poster.jpg"),
    thumbnail: urlForMedia("movies/kisa/thumbnail.jpg"),
    image_cover: urlForMedia("movies/kisa/thumbnail.jpg"),
    image_description: urlForMedia("movies/kisa/kisa2.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/kisa/kisa2.jpg")
      },
      {
        image: urlForMedia("movies/kisa/kisa3.jpg")
      },
      {
        image: urlForMedia("movies/kisa/kisa1.jpg")
      }
    ]
  },
  {
    id: "long_27",
    order: 27,
    slug: "tragovi-crne-devojke",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1972,
    title: translate("long_27_title"),
    description: translate("long_27_description"),
    duration: "85 min",
    country: translate("long_27_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1972"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A SUPPORTING ROLE;Pula Film Festival;1972;"
      }
    ],
    genre: translate("long_27_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Dragoljub Ivkov, Živojin Pavlović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Aleksandar Radulović, Mihailo Đurić, Rodoljub Đoković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Boris Dvornik, Neda Spasojević, Ružica Sokić, Pavle Vuisić, Velimir Bata Živojinović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Stanislava Branković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/tragovi_crne_devojke/poster.jpg"),
    thumbnail: urlForMedia("movies/tragovi_crne_devojke/thumbnail.jpg"),
    image_cover: urlForMedia("movies/tragovi_crne_devojke/cover.jpg"),
    image_description: urlForMedia("movies/tragovi_crne_devojke/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/tragovi_crne_devojke/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/tragovi_crne_devojke/tragovi_1.jpg")
      },
      {
        image: urlForMedia("movies/tragovi_crne_devojke/cover.jpg")
      },
      {
        image: urlForMedia("movies/tragovi_crne_devojke/tragovi_4.jpg")
      },
      {
        image: urlForMedia("movies/tragovi_crne_devojke/tragovi_5.jpg")
      }
    ]
  },
  {
    id: "long_28",
    order: 28,
    slug: "zuta",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1973,
    title: translate("long_28_title"),
    description: translate("long_28_description"),
    duration: "81 min",
    country: translate("long_28_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1973;"
      }
    ],
    genre: translate("long_28_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Vladimir Tadej"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Rodoljub Đoković, Mihailo Đurić, Aleksandar Radulović, Vladimir Tekić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ružica Sokić, Vera Čukuić, Aleksandar Gavrić, Borislav Stjepanović, Slobodan Aligrudić, Mira Banjac, Žika Milenković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vladimir Tadej"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Vladimir Tadej"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Bjenjaš"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Arsen Dedić"
      }
    ],
    director: "Vladimir Tadej",
    image: urlForMedia("movies/zuta/poster.jpg"),
    thumbnail: urlForMedia("movies/zuta/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zuta/cover.jpg"),
    image_description: urlForMedia("movies/zuta/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/zuta/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/zuta/zuta_1.jpg")
      },
      {
        image: urlForMedia("movies/zuta/zuta_3.jpg")
      },
      {
        image: urlForMedia("movies/zuta/cover.jpg")
      },
      {
        image: urlForMedia("movies/zuta/zuta_4.jpg")
      },
      {
        image: urlForMedia("movies/zuta/zuta_2.jpg")
      }
    ]
  },
  {
    id: "long_29",
    order: 29,
    slug: "dervis-i-smrt",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1974,
    title: translate("long_29_title"),
    description: translate("long_29_description"),
    duration: "102 min",
    country: translate("long_29_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER ARENA FOR THE FILM;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SCREENPLAY;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DOP;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A SUPPORTING ROLE;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C AWARD;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "GOLDEN RING AWARD;Pula Film Festival;1974"
      },
      {
        name: "OTHER",
        title: "ĆELE KULA AWARD;Film Festival Niš;1974"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SUPPORTING ACTOR;Pula Film Festival;1974;"
      }
    ],
    genre: translate("long_29_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Velimirović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Zdravko Velimirović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Cvetković - Ciga, Čedomir Ljubiša, Đorđe Milojević"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Vojislav Mirić, Olivera Katarina, Velimir Bata Živojinović, Boris Dvornik, Abdurahman Šalja, Faruk Begoli, Branko Pleša, Pavle Vuisić, Veljko Mandić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Nenad Jovičić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlastimir Gavrik"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Ljiljana Dragović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Iva Kosi"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Zdravko Velimirović",
    image: urlForMedia("movies/dervis_i_smrt/poster.jpg"),
    thumbnail: urlForMedia("movies/dervis_i_smrt/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dervis_i_smrt/cover.jpg"),
    image_description: urlForMedia("movies/dervis_i_smrt/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dervis_i_smrt/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/dervis_i_smrt/cover.jpg")
      }
    ]
  },
  {
    id: "long_30",
    order: 30,
    slug: "kosava",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1974,
    title: translate("long_30_title"),
    description: translate("long_30_description"),
    duration: "80 min",
    country: translate("long_30_country"),
    genre: translate("long_30_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragoslav Lazić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Milan Milićević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Dušan Perković, Milenko Stanković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Tatjana Bošković, Bekim Fehmiu, Velimir Bata Živojinović, Jovan Janićijević, Ilija Mihajlović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Vladislav Lalicki"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Radmila Nikolić"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Arsen Dedić"
      }
    ],
    director: "Dragoslav Lazić",
    image: urlForMedia("movies/kosava/poster.jpg"),
    thumbnail: urlForMedia("movies/kosava/thumbnail.jpg"),
    image_cover: urlForMedia("movies/kosava/cover.jpg"),
    image_description: urlForMedia("movies/kosava/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/kosava/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_6.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_2.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_5.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_10.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_1.jpg")
      },
      {
        image: urlForMedia("movies/kosava/cover.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_4.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_7.jpg")
      },
      {
        image: urlForMedia("movies/kosava/kosava_9.jpg")
      }
    ]
  },
  {
    id: "long_31",
    order: 31,
    slug: "protiv-kinga",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1974,
    title: translate("long_31_title"),
    description: translate("long_31_description"),
    duration: "74 min",
    country: translate("long_31_country"),
    genre: translate("long_31_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragovan Jovanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandra Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slobodan Perović, Boško Božin, Nenad Popović, Zoran Milenković, Eugen Verber, Slavoljub Antić, Dragoljub Milosavljević, Branislav Ciga Milenković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Bogoljub Petrović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Slobodan Mijačević"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Milica Aleksandrov"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Arsen Dedić"
      }
    ],
    director: "Dragovan Jovanović",
    image: urlForMedia("movies/protiv_kinga/poster.jpg"),
    thumbnail: urlForMedia("movies/protiv_kinga/thumbnail.jpg"),
    image_cover: urlForMedia("movies/protiv_kinga/cover.jpg"),
    image_description: urlForMedia("movies/protiv_kinga/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/protiv_kinga/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/protiv_kinga/protiv_4.jpg")
      },
      {
        image: urlForMedia("movies/protiv_kinga/cover.jpg")
      },
      {
        image: urlForMedia("movies/protiv_kinga/protiv_3.jpg")
      },
      {
        image: urlForMedia("movies/protiv_kinga/protiv_2.jpg")
      },
      {
        image: urlForMedia("movies/protiv_kinga/protiv_1.jpg")
      }
    ]
  },
  {
    id: "long_32",
    order: 32,
    slug: "sb-zatvara-krug",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1974,
    title: translate("long_32_title"),
    description: translate("long_32_description"),
    duration: "86 min",
    country: translate("long_32_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER ARENA FOR DOP;Pula Film Festival;1974;"
      }
    ],
    genre: translate("long_32_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Radivoje Popović, Dušan Perković, Djordkžje Milojević, Rodoljub Đoković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slobodan Dimitrijević, Dušica Žegarac, Dragomir Felba, Rade Marković, Voja Mirić, Faruk Begoli, Milan Gutović"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Milivoje Milivojević, Bogoljub Petrović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Slobodan Mijačević"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Zagorka Stojanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Arsen Dedić"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/sb_zatvara_krug/poster.jpg"),
    thumbnail: urlForMedia("movies/sb_zatvara_krug/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sb_zatvara_krug/cover.jpg"),
    image_description: urlForMedia("movies/sb_zatvara_krug/cover.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sb_zatvara_krug/cover.jpg")
      }
    ]
  },
  {
    id: "long_33",
    order: 33,
    slug: "decak-i-violina",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1975,
    title: translate("long_33_title"),
    description: translate("long_33_description"),
    duration: "80 min",
    country: translate("long_33_country"),
    awards: [
      {
        name: "OTHER",
        title: "SILVER MEDAL;Moscow International Film Festival;1969"
      },
      {
        name: "OTHER",
        title: "SILVER MEDAL;Avelino Neorealism Film Festival;1969;"
      }
    ],
    genre: translate("long_33_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Jovan Rančić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Alenka Rančić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Radivoje Popović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dobrvoje Drobac, Nebojša Bakočević, Svetislav Goncić, Dina Rutić, Janez Vrhovec, Alenka Rančić, Dragomir Felba, Vladan Živković, Tripo Simonuti"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dragoljub Petrović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mirjana Ostojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Sarina Stefanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Tripo Simonuti"
      }
    ],
    director: "Jovan Rančić",
    image: urlForMedia("movies/decak_i_violina/poster.jpg"),
    thumbnail: urlForMedia("movies/decak_i_violina/thumbnail.jpg"),
    image_cover: urlForMedia("movies/decak_i_violina/cover.jpg"),
    image_description: urlForMedia("movies/decak_i_violina/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/decak_i_violina/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/decak_i_violina/decak_2.jpg")
      },
      {
        image: urlForMedia("movies/decak_i_violina/cover.jpg")
      },
      {
        image: urlForMedia("movies/decak_i_violina/decak_1.jpg")
      }
    ]
  },
  {
    id: "long_34",
    order: 34,
    slug: "pavle-pavlovic",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1975,
    title: translate("long_34_title"),
    description: translate("long_34_description"),
    duration: "92 min",
    country: translate("long_34_country"),
    awards: [
      {
        name: "OTHER",
        title: "C.I.D.A.L.C AWARD;Pula Film Festival;1975"
      },
      {
        name: "OTHER",
        title: "GOLDEN MEDAL;Avelino Neorealism Film Festival;1975"
      },
      {
        name: "OTHER",
        title: "Trieste Film Festival;1998;"
      }
    ],
    genre: translate("long_34_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Puriša Đorđević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milenko Stanković, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Bekim Fehmiu, Milena Dravić, Ljuba Tadić, Rade Marković, Ljubinka Bobić, Slobodan Djurić, Karlo Bulić, Faruk Begoli, Danilo Bata Stojković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Tomislav Pinter"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Jovan Radić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Božana Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Stefanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Đorđe Karaklajić"
      }
    ],
    director: "Puriša Đorđević",
    image: urlForMedia("movies/pavle_pavlovic/poster.jpg"),
    thumbnail: urlForMedia("movies/pavle_pavlovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/pavle_pavlovic/cover.jpg"),
    image_description: urlForMedia("movies/pavle_pavlovic/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/pavle_pavlovic/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/pavle_pavlovic/pavle_4.jpg")
      },
      {
        image: urlForMedia("movies/pavle_pavlovic/cover.jpg")
      },
      {
        image: urlForMedia("movies/pavle_pavlovic/pavle_3.jpg")
      },
      {
        image: urlForMedia("movies/pavle_pavlovic/pavle_1.jpg")
      }
    ]
  },
  {
    id: "long_35",
    order: 35,
    slug: "devojacki-most",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1976,
    title: translate("long_35_title"),
    description: translate("long_35_description"),
    duration: "95 min",
    country: translate("long_35_country"),
    awards: [
      {
        name: "OTHER",
        title: "C.I.D.A.L.C AWARD;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "Thessaloniki International Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "International Film Festival Tehran;1977;"
      }
    ],
    genre: translate("long_35_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Dulović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Aleksandar Đurčinov"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Žarko Radić, Dragan Nikolić, Olga Kacjan, Marko Nikolić, Peter Karsten, Tanasije Uzunović, Rastislav Jović, Dževad Doraj, Miroljub Lešo, Dragomir Felba"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Petrovska"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Bojan Adamič"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/devojacki_most/poster.jpg"),
    thumbnail: urlForMedia("movies/devojacki_most/thumbnail.jpg"),
    image_cover: urlForMedia("movies/devojacki_most/cover.jpg"),
    image_description: urlForMedia("movies/devojacki_most/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/devojacki_most/devojacki_2.jpg")
      },
      {
        image: urlForMedia("movies/devojacki_most/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/devojacki_most/cover.jpg")
      },
      {
        image: urlForMedia("movies/devojacki_most/devojacki_1.jpg")
      }
    ]
  },
  {
    id: "long_36",
    order: 36,
    slug: "cuvar-plaze-u-zimskom-periodu",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1976,
    title: translate("long_36_title"),
    description: translate("long_36_description"),
    duration: "90 min",
    country: translate("long_36_country"),
    awards: [
      {
        name: "OTHER",
        title: "C.I.D.A.L.C AWARD;Berlin International Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST ART DIRECTOR;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "KOKAN RAKONJAC AWARD;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "MLADOST MAGAZINE AWARD;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "ZLATNI VJENAC STUDIJA AWARD;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "ZUM MAGAZINE AWARD;Pula Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "Film Festival Niš;1976"
      },
      {
        name: "OTHER",
        title: "International Istanbul Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "Valladolid International Film Festival;1976"
      },
      {
        name: "OTHER",
        title: "Chicago International Film Festival;1976;"
      }
    ],
    genre: translate("long_36_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Radivoje Popović, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Irfan Mensur, Danilo Bata Stojković, Godana Kosanović, Dara Čalenić, Mira Banjac, Velimir Bata Živojinović, Pavle Vuisić, Ružica Sokić, Faruk Begoli, Ana Krasojević, Bora Todorović, Mića Tomić, Stevan Minja, Dragomir Felba, Janez Vrhovec"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Petković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mirjana Ostojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/poster.jpg"),
    thumbnail: urlForMedia(
      "movies/cuvar_plaze_u_zimskom_periodu/thumbnail.jpg"
    ),
    image_cover: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cover.jpg"),
    image_description: urlForMedia(
      "movies/cuvar_plaze_u_zimskom_periodu/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cuvar_8.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cuvar_6.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cuvar_1.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cuvar_3.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cuvar_4.jpg")
      },
      {
        image: urlForMedia("movies/cuvar_plaze_u_zimskom_periodu/cover.jpg")
      }
    ]
  },
  {
    id: "long_37",
    order: 37,
    slug: "leptirov-oblak",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1977,
    title: translate("long_37_title"),
    description: translate("long_37_description"),
    duration: "82 min",
    country: translate("long_37_country"),
    genre: translate("long_37_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Zoran Cvijanović, Pavle Vuisić, Ružica Sokić, Jelisaveta Sablić, Sonja Savić, Emilija Cerović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Olga Rovnan"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Goran Bregović"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/leptirov_oblak/poster.jpg"),
    thumbnail: urlForMedia("movies/leptirov_oblak/thumbnail.jpg"),
    image_cover: urlForMedia("movies/leptirov_oblak/cover.jpg"),
    image_description: urlForMedia("movies/leptirov_oblak/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_6.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_8.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_3.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_7.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/cover.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_1.jpg")
      },
      {
        image: urlForMedia("movies/leptirov_oblak/leptirov_2.jpg")
      }
    ]
  },
  {
    id: "long_38",
    order: 38,
    slug: "miris-poljskog-cveca",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1977,
    title: translate("long_38_title"),
    description: translate("long_38_description"),
    duration: "93 min",
    country: translate("long_38_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST MUSIC;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title: "KODAK AWARD;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title: "Locarno Film Festival;1978;"
      }
    ],
    genre: translate("long_38_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Rajko Grlić, Srđan Karanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Stojanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljuba Tadić, Aleksandar Berček, Sonja Divac, Olga Spiridonović, Bogdan Diklić, Miodrag Radovanović, Branko Cvejić, Slobodan Aligrudić, Čedomir Petrović, Gorica Popović, Ljubomir Čipranić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Živko Zalar"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miomir Denić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Pavlović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/miris_poljskog_cvеca/poster.jpg"),
    thumbnail: urlForMedia("movies/miris_poljskog_cvеca/thumbnail.jpg"),
    image_cover: urlForMedia("movies/miris_poljskog_cvеca/cover.jpg"),
    image_description: urlForMedia("movies/miris_poljskog_cvеca/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_6.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_1.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_4.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_8.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/cover.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_7.jpg")
      },
      {
        image: urlForMedia("movies/miris_poljskog_cvеca/miris_3.jpg")
      }
    ]
  },
  {
    id: "long_39",
    order: 39,
    slug: "pas-koji-je-voleo-vozove",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1977,
    title: translate("long_39_title"),
    description: translate("long_39_description"),
    duration: "91 min",
    country: translate("long_39_country"),
    awards: [
      {
        name: "OTHER",
        title: "BRONZE ARENA;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR THE BEST PRODUCTION DESIGNER;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX FOR BEST ACTOR;Film Festival Niš;1978"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS CERTIFICATE;Film Festival Niš;1978"
      },
      {
        name: "OTHER",
        title:
          "BEST ACTOR CERTIFICATE;Film Festival Niš;1978;Film Festival Vrnjačka Banja;1978"
      },
      {
        name: "OTHER",
        title: "Berlin International Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "San Francisco Interntional Film Festival;1978;"
      }
    ],
    genre: translate("long_39_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Svetlana Bojković, Irfan Mensur, Velimir Bata Živojinović, Pavle Vuisić, Danilo Bata Stojković, Dušan Janićijević, Boro Begović, Dragomir Čumić, Katica Želi, Gordana Pavlov, Ljiljana Jovanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Petković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Miodrag Hadžić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/pas_koji_je_voleo_vozove/poster.jpg"),
    thumbnail: urlForMedia("movies/pas_koji_je_voleo_vozove/thumbnail.jpg"),
    image_cover: urlForMedia("movies/pas_koji_je_voleo_vozove/cover.jpg"),
    image_description: urlForMedia(
      "movies/pas_koji_je_voleo_vozove/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/pas_koji_je_voleo_vozove/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/pas_koji_je_voleo_vozove/cover.jpg")
      }
    ]
  },
  {
    id: "long_40",
    order: 40,
    slug: "specijalno-vaspitanje",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1977,
    title: translate("long_40_title"),
    description: translate("long_40_description"),
    duration: "110 min",
    country: translate("long_40_country"),
    awards: [
      {
        name: "OTHER",
        title: "DIPLOMA FOR BEST DIRECTOR;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title: "ZLATNI VJENAC STUDIJA AWARD;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C. AWARD;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title:
          "PRIZE OF THE ECUMENICAL JURY;International Film Festival Mannheim;1977"
      },
      {
        name: "OTHER",
        title: "International Film Festival Amsterdam;1977;"
      }
    ],
    genre: translate("long_40_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Marković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Goran Marković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Stojanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slavko Štimac, Bekim Fehmiu, Ljubiša Samardžić, Aleksandar Berček, Mića Tomić, Cvijeta Mesić, Branislav Lečić, Slobodan Aligrudić, Rade Marković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Živko Zalar"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miljen Kljaković"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Goran Marković",
    image: urlForMedia("movies/specijalno_vaspitanje/poster.jpg"),
    thumbnail: urlForMedia("movies/specijalno_vaspitanje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/specijalno_vaspitanje/cover.jpg"),
    image_description: urlForMedia("movies/specijalno_vaspitanje/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/specijalno_vaspitanje/specijalno_1.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/specijalno_5.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/cover.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/specijalno_3.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/specijalno_2.jpg")
      },
      {
        image: urlForMedia("movies/specijalno_vaspitanje/specijalno_4.jpg")
      }
    ]
  },
  {
    id: "long_41",
    order: 41,
    slug: "hajka",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1977,
    title: translate("long_41_title"),
    description: translate("long_41_description"),
    duration: "104 min",
    country: translate("long_41_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1977"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTOR IN A LEADING ROLE;Pula Film Festival;1977;"
      }
    ],
    genre: translate("long_41_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živojin Pavlović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Zivojin Pavlovic"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Aleksandar Radulović, Rodoljub Đoković, Miodrag Stevanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Rade Šerbedžija, Barbara Nielsen, Predrag Manojlović, Pavle Vuisić, Zaim Muzaferija, Miloš Žutić, Ratislav Jović, Velimir Živojinovic, Lazar Ristovski, Slobodanka Marković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Mirić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mirjana Ostojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      }
    ],
    director: "Živojin Pavlović",
    image: urlForMedia("movies/hajka/poster.jpg"),
    thumbnail: urlForMedia("movies/hajka/thumbnail.jpg"),
    image_cover: urlForMedia("movies/hajka/cover.jpg"),
    image_description: urlForMedia("movies/hajka/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/hajka/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_3.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_5.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_1.jpg")
      },
      {
        image: urlForMedia("movies/hajka/cover.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_2.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_6.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_4.jpg")
      },
      {
        image: urlForMedia("movies/hajka/hajka_7.jpg")
      }
    ]
  },
  {
    id: "long_42",
    order: 42,
    slug: "bosko-buha",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1978,
    title: translate("long_42_title"),
    description: translate("long_42_description"),
    duration: "124 min",
    country: translate("long_42_country"),
    awards: [
      {
        name: "OTHER",
        title: "BEST DIALOGUE AWARD;Film Festival Vrnjačka Banja;1978"
      },
      {
        name: "OTHER",
        title: "SPECIAL DIPLOMA;Film Festival Niš;1978"
      },
      {
        name: "OTHER",
        title: "SLOBODA STATUE AWARD;Sopot Film Festival;1978"
      },
      {
        name: "OTHER",
        title:
          "SILVER ELEPHANT AWARD FOR BEST DIRECTOR;Mumbai International Film Festival;1978"
      },
      {
        name: "OTHER",
        title:
          "SILVER ELEPHANT AWARD FOR BEST ACTOR;Mumbai International Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "Pula Film Festival;2013;"
      }
    ],
    genre: translate("long_42_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Branko Bauer"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Boško Matić, Dušan Perković"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Rodoljub Đoković, Živadin Đorđević, Milan Milovanović, Savo Popović, Aleksandar Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Žarko Radić, Marko Nikolić, Ljubiša Samardžić, Ivan Kojundžić, Milan Štrljić, Miroljub Lešo, Milena Dapčević, Dragan Bjelogrlić, Ljiljana Blagojević"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Blažina"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Divna Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Branko Bauer",
    image: urlForMedia("movies/bosko_buha/poster.jpg"),
    thumbnail: urlForMedia("movies/bosko_buha/thumbnail.jpg"),
    image_cover: urlForMedia("movies/bosko_buha/cover.jpg"),
    image_description: urlForMedia("movies/bosko_buha/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/bosko_buha/bosko_7.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/bosko_8.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/bosko_1.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/bosko_4.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/bosko_6.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/cover.jpg")
      },
      {
        image: urlForMedia("movies/bosko_buha/bosko_3.jpg")
      }
    ]
  },
  {
    id: "long_43",
    order: 43,
    slug: "paviljon-vi",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1978,
    title: translate("long_43_title"),
    description: translate("long_43_description"),
    duration: "92 min",
    country: translate("long_43_country"),
    awards: [
      {
        name: "CANNES3",
        title: "PRIZE OF THE ECUMENICAL JURY;Cannes Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C. AWARD;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "SILVER ARENA FOR BEST ACTOR;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "SPECIAL DIPLOMA;Film Festival Niš;1978"
      },
      {
        name: "OTHER",
        title: "La Rochelle International Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "Transilvania International Film Festival;1979;"
      }
    ],
    genre: translate("long_43_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Lucijan Pintilije"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Lucijan Pintilije"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slobodan Perović, Zoran Radmilović, Pavle Vuisić, Ljuba Tadić, Slavko Simić, Stevo Žigon, Dragomir Čumić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Jakšić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mira Čohadžić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Milica Poličević Jeftić"
      }
    ],
    director: "Lucijan Pintilije",
    image: urlForMedia("movies/paviljon_6/poster.jpg"),
    thumbnail: urlForMedia("movies/paviljon_6/thumbnail.jpg"),
    image_cover: urlForMedia("movies/paviljon_6/cover.jpg"),
    image_description: urlForMedia("movies/paviljon_6/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/paviljon_6/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/paviljon_6/paviljon_1.jpg")
      },
      {
        image: urlForMedia("movies/paviljon_6/paviljon_2.jpg")
      },
      {
        image: urlForMedia("movies/paviljon_6/cover.jpg")
      },
      {
        image: urlForMedia("movies/paviljon_6/paviljon_3.jpg")
      }
    ]
  },
  {
    id: "long_44",
    order: 44,
    slug: "poslednja-trka",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1978,
    title: translate("long_44_title"),
    description: translate("long_44_description"),
    duration: "87 min",
    country: translate("long_44_country"),
    awards: [
      {
        name: "OTHER",
        title: "SPECIAL DIPLOMA;Film Festival Niš;1979"
      },
      {
        name: "OTHER",
        title:
          "GRAND PRIX FOR UNICEF;FEST International Film Festival Belgrade;1979"
      },
      {
        name: "OTHER",
        title:
          "USSR OLYMPIC COMMITEE SILVER MEDAL;Moscow International Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "SECOND PRIZE;Grand Lyon Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "Venice International Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "International Film Festival Mannheim;1980;"
      }
    ],
    genre: translate("long_44_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Jovan Rančić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Veroslav Rančić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Radivoje Popović, Slobodan Roganović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Vladimir Buljan, Mirjana Joković, Bogoljub Petrović, Pavle Vuisić, Alenka Rančić, Dušan Janićijević, Nadežda Vukičević, Vladan Živković, Zoran Miljković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mirjana Ostojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_music"),
        type: "role_music",
        members: "Laboratorija zvuka"
      }
    ],
    director: "Jovan Rančić",
    image: urlForMedia("movies/poslednja_trka/poster.jpg"),
    thumbnail: urlForMedia("movies/poslednja_trka/thumbnail.jpg"),
    image_cover: urlForMedia("movies/poslednja_trka/cover.jpg"),
    image_description: urlForMedia("movies/poslednja_trka/trka3.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/poslednja_trka/trka1.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_trka/trka3.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_trka/sniopsis_trka.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_trka/trka2.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_trka/cover.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_trka/trka5.jpg")
      }
    ]
  },
  {
    id: "long_45",
    order: 45,
    slug: "licne-stvari",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1979,
    title: translate("long_45_title"),
    description: translate("long_45_description"),
    duration: "93 min",
    country: translate("long_45_country"),
    genre: translate("long_45_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Aleksandar Mandić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Aleksandar Mandić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Filip David"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Maja Sabljić, Zoran Cvijanović, Branovoj Djordjević, Olivera Ježina, Predrag Miletić, Sonja Savić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Radoslav Vladić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Aleksandar Mandić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Sarina Stefanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Goran Bregović"
      }
    ],
    director: "Aleksandar Mandić",
    image: urlForMedia("movies/licne_stvari/poster.jpg"),
    thumbnail: urlForMedia("movies/licne_stvari/thumbnail.jpg"),
    image_cover: urlForMedia("movies/licne_stvari/licne_4.jpg"),
    image_description: urlForMedia("movies/licne_stvari/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/licne_stvari/licne_4.jpg")
      },
      {
        image: urlForMedia("movies/licne_stvari/licne_1.jpg")
      },
      {
        image: urlForMedia("movies/licne_stvari/licne_3.jpg")
      },
      {
        image: urlForMedia("movies/licne_stvari/licne_2.jpg")
      }
    ]
  },
  {
    id: "long_46",
    order: 46,
    slug: "nacionalna-klasa-do-785-ccm",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1979,
    title: translate("long_46_title"),
    description: translate("long_46_description"),
    duration: "105 min",
    country: translate("long_46_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST ACTRESS;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "MLADOST MAGAZINE AWARD;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "ZUM MAGAZINE AWARD;Pula Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "Venice International Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "Avellino Neorealism Film Festival;1978"
      },
      {
        name: "OTHER",
        title: "Eurovision Palermo Film Festival;1978;"
      }
    ],
    genre: translate("long_46_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Marković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Goran Marković"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Aleksandar Stojanović, Slobodan Pavičević, Milan Vujnović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragan Nikolić, Bogdan Diklić, Bora Todorović, Voja Brajović, Aleksandar Berček, Gorica Popović, Rade Marković, Olivera Marković, Mića Tomić, Dragomir Felba, Danilo Bata Stojković, Jelica Sretenović, Irfan Mensur"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Živko Zalar"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Mirić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Goran Marković",
    image: urlForMedia("movies/nacionalna_klasa/poster.jpg"),
    thumbnail: urlForMedia("movies/nacionalna_klasa/thumbnail.jpg"),
    image_cover: urlForMedia("movies/nacionalna_klasa/cover.jpg"),
    image_description: urlForMedia("movies/nacionalna_klasa/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/nacionalna_klasa/nacionalna_1.jpg")
      },
      {
        image: urlForMedia("movies/nacionalna_klasa/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/nacionalna_klasa/nacionalna_4.jpg")
      },
      {
        image: urlForMedia("movies/nacionalna_klasa/cover.jpg")
      },
      {
        image: urlForMedia("movies/nacionalna_klasa/nacionalna_2.jpg")
      },
      {
        image: urlForMedia("movies/nacionalna_klasa/nacionalna_6.jpg")
      }
    ]
  },
  {
    id: "long_47",
    order: 47,
    slug: "usijanje",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1979,
    title: translate("long_47_title"),
    description: translate("long_47_description"),
    duration: "95 min",
    country: translate("long_47_country"),
    genre: translate("long_47_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Boro Drašković"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Boro Drašković, Mirko Kovač"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragan Maksimović, Rade Šerbedžija, Gordana Kosanović, Ivo Gregurević, Marko Todorović, Svetolik Nikačević, Fabijan Šovagović, Velimir Bata Živojinović, Zoran Radmilović, Mirjana Kodžić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Predrag Popović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Maja Drašković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Stefanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vartkes Baronijan"
      }
    ],
    director: "Boro Drašković",
    image: urlForMedia("movies/usijanje/poster.jpg"),
    thumbnail: urlForMedia("movies/usijanje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/usijanje/sinopsis.jpg"),
    image_description: urlForMedia("movies/usijanje/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/usijanje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/usijanje/usijanje_naslovne.jpg")
      },
      {
        image: urlForMedia("movies/usijanje/usijanje_2.jpg")
      },
      {
        image: urlForMedia("movies/usijanje/usijanje_4.jpg")
      },
      {
        image: urlForMedia("movies/usijanje/usijanje_3.jpg")
      },
      {
        image: urlForMedia("movies/usijanje/usijanje_1.jpg")
      }
    ]
  },
  {
    id: "long_48",
    order: 48,
    slug: "zemaljski-dani-teku",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1979,
    title: translate("long_48_title"),
    description: translate("long_48_description"),
    duration: "84 min",
    country: translate("long_48_country"),
    awards: [
      {
        name: "OTHER",
        title: "BRONZE ARENA FOR BEST FILM;Pula Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "DIPLOMA FOR BEST FILM EDITING;Pula Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "MLADOST MAGAZINE AWARD;Pula Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C. AWARD;Pula Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "MANAKI CRITICS AWARD;Film Festival Manaki Brothers;1979"
      },
      {
        name: "OTHER",
        title: "Venice International Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "Valladolid International Film Festival;1979"
      },
      {
        name: "OTHER",
        title: "Karlovy Vary International Film Festival;1979;"
      }
    ],
    genre: translate("long_48_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Filip David, Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Dimitrije Vujović, Obren Helcer, Šarlota Pešić, Mila Keča"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milan Spasić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/zemaljski_dani_teku/poster.jpg"),
    thumbnail: urlForMedia("movies/zemaljski_dani_teku/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zemaljski_dani_teku/cover.jpg"),
    image_description: urlForMedia("movies/zemaljski_dani_teku/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/zemaljski_dani_teku/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/zemaljski_dani_teku/cover.jpg")
      }
    ]
  },
  {
    id: "long_49",
    order: 49,
    slug: "erogena-zona",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1980,
    title: translate("long_49_title"),
    description: translate("long_49_description"),
    duration: "91 min",
    country: translate("long_49_country"),
    awards: [
      {
        name: "OTHER",
        title: "Film Festival Niš;1980"
      },
      {
        name: "OTHER",
        title: "Pula Film Festival;1980;"
      }
    ],
    genre: translate("long_49_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dejan Karaklajić"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Dejan Karaklajić, Rajko Grlić, Slobodan Stojanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mihailo Đurić, Aleksandar Radulović, Nebojša Vasilić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Milan Gutović, Marina Urbanc, Dušica Žegarac, Cvijeta Mesić, Branko Cvejić, Velimir Bata Živojinović, Bora Todorović, Duško Janičijević, Nikola Simić, Danilo Bata Stojković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Predrag Popović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Sava Aćin"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Milivoj Marković"
      }
    ],
    director: "Dejan Karaklajić",
    image: urlForMedia("movies/erogena_zona/poster.jpg"),
    thumbnail: urlForMedia("movies/erogena_zona/thumbnail.jpg"),
    image_cover: urlForMedia("movies/erogena_zona/cover.jpg"),
    image_description: urlForMedia("movies/erogena_zona/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/erogena_zona/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_1.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_3.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_5.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_8.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_2.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/cover.jpg")
      },
      {
        image: urlForMedia("movies/erogena_zona/erogena_6.jpg")
      }
    ]
  },
  {
    id: "long_50",
    order: 50,
    slug: "dani-od-snova",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1980,
    title: translate("long_50_title"),
    description: translate("long_50_description"),
    duration: "95 min",
    country: translate("long_50_country"),
    genre: translate("long_50_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Vlatko Gilić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Vlatko Gilić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Vladica Milosavljević, Boris Komnenić, Ljiljana Krstić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Ivatović"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Vlatko Gilić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Ksenija Zečević"
      }
    ],
    director: "Vlatko Gilić",
    image: urlForMedia("movies/dani_od_snova/poster.jpg"),
    thumbnail: urlForMedia("movies/dani_od_snova/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dani_od_snova/cover.jpg"),
    image_description: urlForMedia("movies/dani_od_snova/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dani_od_snova/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani6.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani3.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani8.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani2.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani4.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/cover.jpg")
      },
      {
        image: urlForMedia("movies/dani_od_snova/dani7.jpg")
      }
    ]
  },
  {
    id: "long_51",
    order: 51,
    slug: "petrijin-venac",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1980,
    title: translate("long_51_title"),
    description: translate("long_51_description"),
    duration: "99 min",
    country: translate("long_51_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND GOLDENARENA FOR BEST FILM;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "Venice International Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "Amsterdam World International Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "Montpellier International Festival of Mediterranean Film;1980"
      },
      {
        name: "OTHER",
        title: "La Rochelle International Film Festival;1980;"
      }
    ],
    genre: translate("long_51_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Mirjana Karanović, Dragan Maksimović, Marko Nikolić, Pavle Vuisić, Olivera Marković, Veljko Mandić, Ljiljana Krstić, Mića Tomić, Miloš Žutić, Rade Marković, Darinka Živković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Tomislav Pinter"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Mirić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Saša Kuljača"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/petrijin_venac/poster.jpg"),
    thumbnail: urlForMedia("movies/petrijin_venac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/petrijin_venac/cover.jpg"),
    image_description: urlForMedia("movies/petrijin_venac/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/petrijin_venac/petrija_2.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/petrija_4.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/petrija_6.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/petrija_5.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/petrija_7.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/cover.jpg")
      },
      {
        image: urlForMedia("movies/petrijin_venac/petrija_3.jpg")
      }
    ]
  },
  {
    id: "long_52",
    order: 52,
    slug: "poseban-tretman",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1980,
    title: translate("long_52_title"),
    description: translate("long_52_description"),
    duration: "93 min",
    country: translate("long_52_country"),
    awards: [
      {
        name: "CANNES3",
        title: "BEST SUPPORTING ACTRESS;Cannes Film Festival;1980"
      },
      {
        name: "GOLDEN_GLOBE",
        title: "GOLDEN GLOBE NOMINEE;LA Film Festival;1980"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A SUPPORTING ROLE;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C. AWARD;Pula Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS CERTIFICATE;Film Festival Niš;1978"
      },
      {
        name: "OTHER",
        title:
          "FIRST PRIZE FOR SCREENPLAY;Film Screenplay Festival Vrnjačka Banja;1980"
      },
      {
        name: "OTHER",
        title: "Sydney Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "New York City International Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "London International Film Festival;1980"
      },
      {
        name: "OTHER",
        title: "Valladolid International Film Festival;1980;"
      }
    ],
    genre: translate("long_52_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dušan Kovačević"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Dan Tana"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljuba Tadić, Dušica Žegarac, Danilo Bata Stojković, Milena Dravić, Milan Srdoč, Petar Kralj, Radmila Živković, Bora Todorović, Pavle Vuisić, Velimir Bata Živojinović, Janez Vrhovec, Predrag Bijelić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Petković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Ljiljana Dragović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vojislav Kostić"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/poseban_tretman/poster.jpg"),
    thumbnail: urlForMedia("movies/poseban_tretman/thumbnail.jpg"),
    image_cover: urlForMedia("movies/poseban_tretman/cover.jpg"),
    image_description: urlForMedia("movies/poseban_tretman/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/poseban_tretman/tretman_2.jpg")
      },
      {
        image: urlForMedia("movies/poseban_tretman/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/poseban_tretman/tretman_3.jpg")
      },
      {
        image: urlForMedia("movies/poseban_tretman/cover.jpg")
      }
    ]
  },
  {
    id: "long_53",
    order: 53,
    slug: "neka-druga-zena",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1981,
    title: translate("long_53_title"),
    description: translate("long_53_description"),
    duration: "92 min",
    country: translate("long_53_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "BEST ACTRESS CERTIFICATE;Film Festival Niš;1981;SPECIAL JURY PRIZE;Wegiel Film Festival;1981;"
      }
    ],
    genre: translate("long_53_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Dragan Marković, Dušan Perković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Nebojša Garić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Merima  Isaković, Dragan Nikolić, Ljubiša Samardžić, Petar Kralj, Svetlana Bojković, Ivan Klemenc, Dušica Žegarac,Vojislav Mirić, Dragomir Felba"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dragoljub Ivkov"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Petkovska"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/neka_druga_zena/poster.jpg"),
    thumbnail: urlForMedia("movies/neka_druga_zena/thumbnail.jpg"),
    image_cover: urlForMedia("movies/neka_druga_zena/cover.jpg"),
    image_description: urlForMedia("movies/neka_druga_zena/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/neka_druga_zena/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_6.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_3.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_7.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_5.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_8.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/cover.jpg")
      },
      {
        image: urlForMedia("movies/neka_druga_zena/neka_1.jpg")
      }
    ]
  },
  {
    id: "long_54",
    order: 54,
    slug: "pad-italije",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1981,
    title: translate("long_54_title"),
    description: translate("long_54_description"),
    duration: "113 min",
    country: translate("long_54_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND GOLDEN ARENA FOR BEST FILM;Pula Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "KODAK – PATHÉ AWARD;Pula Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "JELEN AWARD;Pula Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "Venice International Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "Karlovy Vary International Film Festival;1981;"
      }
    ],
    genre: translate("long_54_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Lordan Zafranović"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Lordan Zafranović, Mirko Kovač"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Sulejman Kapić, Igor Prizmić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Danijel Obricki, Ena Begovic, Gorica Popović, Velimir Bata Živojinović, Igor Hajdarhodžić, Mirjana Karanović, Miodrag Krivokapić, Dušan Janićijević, Dragan Maksimović, Ljiljana Krstić, Frano Lasić, Snežana  Savić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Drago Turina"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Irena Felicijan"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Josip Remener"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Alfi  Kabiljo"
      }
    ],
    director: "Lordan Zafranović",
    image: urlForMedia("movies/pad_italije/poster.jpg"),
    thumbnail: urlForMedia("movies/pad_italije/thumbnail.jpg"),
    image_cover: urlForMedia("movies/pad_italije/cover.jpg"),
    image_description: urlForMedia("movies/pad_italije/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/pad_italije/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/pad_italije/cover.jpg")
      }
    ]
  },
  {
    id: "long_55",
    order: 55,
    slug: "ritam-zlocina",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1981,
    title: translate("long_55_title"),
    description: translate("long_55_description"),
    duration: "88 min",
    country: translate("long_55_country"),
    awards: [
      {
        name: "OTHER",
        title: "KOKAN RAKONJAC AWARD;Pula Film Festival;1981"
      },
      {
        name: "OTHER",
        title:
          "BEST ACTRESS CERTIFICATE;Film Festival Niš;1981;BEST PRODUCTION DESIGN;Madrid International Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Madrid International Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Brussels International Film Festival;1981"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Porto International Film Festival;1983;"
      }
    ],
    genre: translate("long_55_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zoran Tadić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Pavao Pavličić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Nenad Pata"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ivica Vidović, Božidarka Frajt, Fabijan Šovagović, Tomislav Gotovac, Dragutin Klobučar, Snježana Tribuson"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Goran Trbuljak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Ante Nola"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Lidija Jojić"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Hrvoje Hegedušić"
      }
    ],
    director: "Zoran Tadić",
    image: urlForMedia("movies/ritam_zlocina/poster.jpg"),
    thumbnail: urlForMedia("movies/ritam_zlocina/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ritam_zlocina/cover.jpg"),
    image_description: urlForMedia("movies/ritam_zlocina/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ritam_zlocina/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/ritam_zlocina/cover.jpg")
      }
    ]
  },
  {
    id: "long_56",
    order: 56,
    slug: "sezona-mira-u-parizu",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1981,
    title: translate("long_56_title"),
    description: translate("long_56_description"),
    duration: "103 min",
    country: translate("long_56_country"),
    awards: [
      {
        name: "OTHER",
        title: "SPECIAL JURY PRIZE;Moscow International Film Festival;1981;"
      }
    ],
    genre: translate("long_56_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Predrag Golubović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Predrag Golubović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Branko Vukajlović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragan Nikolić, Marija Šnajder, Alen Nuri, Alida Vali, Erland Jozefson, Danijel Želen, Predrag Manojlović, Paskal Peti, Raf Valone, Džejn Čaplin"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Slobodan Jevtić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Marija Milivojević"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Kornelije Kovač"
      }
    ],
    director: "Predrag Golubović",
    image: urlForMedia("movies/sezona_mira_u_parizu/poster.jpg"),
    thumbnail: urlForMedia("movies/sezona_mira_u_parizu/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sezona_mira_u_parizu/cover.jpg"),
    image_description: urlForMedia("movies/sezona_mira_u_parizu/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sezona_mira_u_parizu/sezona_1.jpg")
      },
      {
        image: urlForMedia("movies/sezona_mira_u_parizu/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/sezona_mira_u_parizu/cover.jpg")
      },
      {
        image: urlForMedia("movies/sezona_mira_u_parizu/sezona_2.jpg")
      }
    ]
  },
  {
    id: "long_57",
    order: 57,
    slug: "idemo-dalje",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1982,
    title: translate("long_57_title"),
    description: translate("long_57_description"),
    duration: "93 min",
    country: translate("long_57_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GRAND PRIX FOR UNICEF;FEST International Film Festival Belgrade;1982"
      },
      {
        name: "OTHER",
        title: "SECOND PRIZE;Film Screenplay Festival Vrnjačka Banja;1982;"
      }
    ],
    genre: translate("long_57_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Šotra"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Slobodan Stojanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Srba Čuturilo, Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragan Nikolić, Velimir Bata Živojinović, Danilo Bata Stojković, Ena Begovic, Pavle Vuisić, Olivera Marković, Jelica Sretenović, Slobodan Aligrudić, Miloš Žutić, Milivoje Mića Tomić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Predrag Nikolić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Gordana Anđelković"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Bojana Subota"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Dušan Karuović"
      }
    ],
    director: "Zdravko Šotra",
    image: urlForMedia("movies/idemo_dalje/poster.jpg"),
    thumbnail: urlForMedia("movies/idemo_dalje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/idemo_dalje/cover.jpg"),
    image_description: urlForMedia("movies/idemo_dalje/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/idemo_dalje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/idemo_7.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/idemo_6.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/idemo_4.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/cover.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/idemo_1.jpg")
      },
      {
        image: urlForMedia("movies/idemo_dalje/idemo_2.jpg")
      }
    ]
  },
  {
    id: "long_58",
    order: 58,
    slug: "nesto-izmedju",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1982,
    title: translate("long_58_title"),
    description: translate("long_58_description"),
    duration: "105 min",
    country: translate("long_58_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST ACTOR;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DOP;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST COSTUME DESIGN;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "C.I.D.A.L.C AWARD;Pula Film Festival;1983;"
      }
    ],
    genre: translate("long_58_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Srđan Karanović, Endru Horton"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Keris Korfman, Dragan Nikolić, Predrag Manojlović, Renata Ulmanski, Zora Doknić-Manojlović, Gorica Popović, Nina Kirsanova, Petar Ilić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Živko Zalar"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miljen Kljaković"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Saša Kuljača"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/nesto_izmedju/poster.jpg"),
    thumbnail: urlForMedia("movies/nesto_izmedju/thumbnail.jpg"),
    image_cover: urlForMedia("movies/nesto_izmedju/cover.jpg"),
    image_description: urlForMedia("movies/nesto_izmedju/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_16.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_7.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_15.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_5.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_17.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_6.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_9.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_12.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/cover.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_8.jpg")
      },
      {
        image: urlForMedia("movies/nesto_izmedju/nesto_11.jpg")
      }
    ]
  },
  {
    id: "long_59",
    order: 59,
    slug: "suton",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1982,
    title: translate("long_59_title"),
    description: translate("long_59_description"),
    duration: "102 min",
    country: translate("long_59_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST PRODUCTION DESIGN;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "Moscow International Film Festival;2001;"
      }
    ],
    genre: translate("long_59_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Goran Paskaljević, Dan Tana, Filip David"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Dan Tana, Andrew Wood"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Karl Malden, Džodi Tilen, Demion Neš, Mia Rot, Pavle Vuisić, Dragan Maksimović, Stojan Aranđelović, Petar Božović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Tomislav Pinter"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Niko Matul"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Marija Danc"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Valter Šarf"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/suton/poster.jpg"),
    thumbnail: urlForMedia("movies/suton/thumbnail.jpg"),
    image_cover: urlForMedia("movies/suton/cover.jpg"),
    image_description: urlForMedia("movies/suton/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/suton/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/suton/suton_3.jpg")
      },
      {
        image: urlForMedia("movies/suton/suton_4.jpg")
      },
      {
        image: urlForMedia("movies/suton/suton_2.jpg")
      },
      {
        image: urlForMedia("movies/suton/suton_1.jpg")
      },
      {
        image: urlForMedia("movies/suton/cover.jpg")
      }
    ]
  },
  {
    id: "long_60",
    order: 60,
    slug: "igmanski-mars",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1983,
    title: translate("long_60_title"),
    description: translate("long_60_description"),
    duration: "96 min",
    country: translate("long_60_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR THE MAKE UP;Pula Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;Film Festival Niš;1983"
      },
      {
        name: "OTHER",
        title: "BEST FILM;Sopot International Film Festival;1983"
      },
      {
        name: "OTHER",
        title: "Film Screenplay Festival Vrnjačka Banja;1983"
      },
      {
        name: "OTHER",
        title: "Manaki Film Festival Bitola;1983;"
      }
    ],
    genre: translate("long_60_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Šotra"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Slobodan Stojanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Mihajlo Ćirin, Nebojša Garić, Nikola Popović, Savo Radulović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Tihomir Arsić, Željka Cvjetan, Branislav Lečić, Milan Štrljić, Velimir Bata Živojinović, Slavko Štimac, Aleksandar Berček, Milena Zupančič, Radko Polič, Danilo Lazović, Žarko Laušević, Marina Marković, Nada Đurevska, Lazar Ristovski, Miodrag Miki Krstović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Andrija Zafranović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vuk Kulenović"
      }
    ],
    director: "Zdravko Šotra",
    image: urlForMedia("movies/igmanski_mars/poster.jpg"),
    thumbnail: urlForMedia("movies/igmanski_mars/thumbnail.jpg"),
    image_cover: urlForMedia("movies/igmanski_mars/cover.jpg"),
    image_description: urlForMedia("movies/igmanski_mars/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/igmanski_mars/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/mars_1.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/mars_4.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/mars_2.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/mars_5.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/cover.jpg")
      },
      {
        image: urlForMedia("movies/igmanski_mars/mars_7.jpg")
      }
    ]
  },
  {
    id: "long_61",
    order: 61,
    slug: "kreka-lovac-na-snove",
    documentary_type: "DOCUMENTARY",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2021,
    title: translate("long_61_title"),
    description: translate("long_61_description"),
    duration: "92 min",
    country: translate("long_61_country"),
    awards: [
      {
        name: "OTHER",
        title: "International Film Festival FEST Belgrade;2021;"
      }
    ],
    genre: translate("long_61_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Slobodan Ivetić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Slobodan Ivetić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Jadranka Blanuša"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Miljen Kreka Kljaković, Johnny Depp, Majid Majidi, Vittorio Storaro, Barry Levinson, Brian Helgeland, Matti Leshem, Ridley Scott, Sergio Mimica – Gezzan, Ken Follet, Rola Bauer, Jerry Lewis, Goran Marković, Srđan Karanović, Slobodan Šijan, Goran Paskaljević, Dušan Kovačević, Ademir Kenović"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Ratko Kušić, Vladimir Nešović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Oblak Jurišin Kaufman"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Ana Krstajić"
      }
    ],
    director: "Slobodan Ivetić",
    image: urlForMedia("movies/kreka_lovac_na_snove/poster.jpg"),
    thumbnail: urlForMedia("movies/kreka_lovac_na_snove/9.jpg"),
    image_cover: urlForMedia("movies/kreka_lovac_na_snove/9.jpg"),
    image_description: urlForMedia("movies/kreka_lovac_na_snove/8.jpg"),
    locked: true,
    slideshow: [
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/9.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/8.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/img_0520.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/1.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/vittorio_storaro.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/img_0464.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/7.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/3.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/johnny_depp.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/img_0568.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/kreka_02.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/sinopsis_kreka.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/img_0605.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/barry_levinson.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/kreka_04.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/naslovna_kreka.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/2.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/kreka_depp.JPG")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/6.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/5.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/4.jpg")
      },
      {
        image: urlForMedia("movies/kreka_lovac_na_snove/img_0528.jpg")
      }
    ]
  },
  {
    id: "long_62",
    order: 62,
    slug: "poslednja-oaza",
    documentary_type: "DOCUMENTARY",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1983,
    title: translate("long_62_title"),
    description: translate("long_62_description"),
    duration: "91 min",
    country: translate("long_62_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND PRIX;FEST International Film Festival Belgrade;1984"
      },
      {
        name: "OTHER",
        title: "GOLDEN CERTIFICATE;Manaki Brothers Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "TARGA D’ORO AWARD;Tuscia Film Fest;1984"
      },
      {
        name: "OTHER",
        title: "GOLDEN CERTIFICATE;Versailles International Film Festival;1984;"
      }
    ],
    genre: translate("long_62_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Petar Lalović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Stjepan Zaninović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Petar Živadinović"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Petar Lalović, Zoran Bogdanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Đokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Zoran Radmilović"
      }
    ],
    director: "Petar Lalović",
    image: urlForMedia("movies/poslednja_oaza/poster.jpg"),
    thumbnail: urlForMedia("movies/poslednja_oaza/thumbnail.jpg"),
    image_cover: urlForMedia("movies/poslednja_oaza/oaza-5.jpg"),
    image_description: urlForMedia("movies/poslednja_oaza/oaza-10.jpg"),
    locked: true,
    slideshow: [
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-5.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-10.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-12.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-3.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-4.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-1.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/posterold.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-14.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-9.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-11.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-7.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-13.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-6.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-8.jpg")
      },
      {
        image: urlForMedia("movies/poslednja_oaza/oaza-2.jpg")
      }
    ]
  },
  {
    id: "long_63",
    order: 63,
    slug: "treci-kljuc",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1983,
    title: translate("long_63_title"),
    description: translate("long_63_description"),
    duration: "81 min",
    country: translate("long_63_country"),
    awards: [
      {
        name: "OTHER",
        title: "BEST FEMALE DEBUT;Film Festival Niš;1983"
      },
      {
        name: "OTHER",
        title: "GOLD COIN;Film Screenplay Festival Vrnjacka Banja;1984;"
      }
    ],
    genre: translate("long_63_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zoran Tadić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Pavao Pavličić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Nenad Pata"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Božidar Alić, Vedrana Međimorec, Franjo Majetić, Ivo Gregurović, Tomislav Gotovac, Đorđe Rapajić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Goran Trbuljak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Ante Nola"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Lada Gamulin"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vesna Štefić"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Aleksandar Bubanović"
      }
    ],
    director: "Zoran Tadić",
    image: urlForMedia("movies/treci_kljuc/poster.jpg"),
    thumbnail: urlForMedia("movies/treci_kljuc/thumbnail.jpg"),
    image_cover: urlForMedia("movies/treci_kljuc/treći_ključ.jpg"),
    image_description: urlForMedia(
      "movies/treci_kljuc/5512206_treci-kljuc-1jpg.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/treci_kljuc/treći_ključ.jpg")
      },
      {
        image: urlForMedia("movies/treci_kljuc/5512206_treci-kljuc-1jpg.jpg")
      },
      {
        image: urlForMedia(
          "movies/treci_kljuc/xdvoxw1qymuyuqav6nc05g3afxu1ingz.jpg"
        )
      }
    ]
  },
  {
    id: "long_64",
    order: 64,
    slug: "rani-snijeg-u-minhenu",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1984,
    title: translate("long_64_title"),
    description: translate("long_64_description"),
    duration: "89 min",
    country: translate("long_64_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DOP;Pula Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "SPECIAL MENTION FOR ACTRESS;Pula Film Festival;1984;"
      }
    ],
    genre: translate("long_64_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Bogdan Žižić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Goran Masot"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Sulejman Kapić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Pavle Vuisić, Drago Grgečić-Gabor, Ute Fidler, Relja Bašić, Uve Gurtler , Iva Marjanović Domljan"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Goran Trbuljak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Želimir Zagota"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Irena Felicijan"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Martin Tomić"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Ozren Depolo"
      }
    ],
    director: "Bogdan Žižić",
    image: urlForMedia("movies/rani_snijeg_u_minhenu/poster.jpg"),
    thumbnail: urlForMedia("movies/rani_snijeg_u_minhenu/thumbnail.jpg"),
    image_cover: urlForMedia("movies/rani_snijeg_u_minhenu/thumbnail.jpg"),
    image_description: urlForMedia("movies/rani_snijeg_u_minhenu/thumb.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/rani_snijeg_u_minhenu/thumb.jpg")
      },
      {
        image: urlForMedia("movies/rani_snijeg_u_minhenu/hqdefault.jpg")
      }
    ]
  },
  {
    id: "long_65",
    order: 65,
    slug: "varljivo-leto-68",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1984,
    title: translate("long_65_title"),
    description: translate("long_65_description"),
    duration: "90 min",
    country: translate("long_65_country"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND PRIX OF THE JURY;Bastia Italian Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;New Orleans Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "AUDIENCE AWARD;New Orleans Film Festival;1984;"
      }
    ],
    genre: translate("long_65_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Paskaljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Gordan Mihić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Dušan Ceklić, Nebojša Vasilć"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Slavko Štimac, Danilo Bata Stojković, Mija Aleksić, Mira Banjac, Sanja Vejnović, Neda Arnerić, Dragana Varagić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Petković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miljen Kljaković"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Marija Đorđević"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Olga Skrigin"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Hristić"
      }
    ],
    director: "Goran Paskaljević",
    image: urlForMedia("movies/varljivo_leto/poster.jpg"),
    thumbnail: urlForMedia("movies/varljivo_leto/thumbnail.jpg"),
    image_cover: urlForMedia("movies/varljivo_leto/cover.jpg"),
    image_description: urlForMedia("movies/varljivo_leto/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/varljivo_leto/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/varljivo_leto/varljivo_2.jpg")
      },
      {
        image: urlForMedia("movies/varljivo_leto/varljivo_1.jpg")
      },
      {
        image: urlForMedia("movies/varljivo_leto/varljivo_4.jpg")
      },
      {
        image: urlForMedia("movies/varljivo_leto/varljivo_3.jpg")
      },
      {
        image: urlForMedia("movies/varljivo_leto/cover.jpg")
      }
    ]
  },
  {
    id: "long_66",
    order: 66,
    slug: "davitelj-protiv-davitelja",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1984,
    title: translate("long_66_title"),
    description: translate("long_66_description"),
    duration: "102 min",
    country: translate("long_66_country"),
    awards: [
      {
        name: "HUGO",
        title: "GOLDEN HUGO NOMINEE;Chicago International Film Festival;1985"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST PRODUCTION DESIGN;Pula Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SOUND DESIGN;Pula Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "CAR KONSTANTIN AWARD;Film Festival Niš;1984"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR CERTIFICATE;Film Festival Niš;1984"
      },
      {
        name: "OTHER",
        title:
          "GRAND PRIX FOR BEST ACTOR;Madrid International Film Festival;1985"
      },
      {
        name: "OTHER",
        title: "Tokyo International Film Festival;1985"
      },
      {
        name: "OTHER",
        title: "International Film Festival Antwerp;1985"
      },
      {
        name: "OTHER",
        title: "San Francisco International Film Festival;1985"
      },
      {
        name: "OTHER",
        title: "Seattle International Film Festival;1985;"
      }
    ],
    genre: translate("long_66_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Slobodan Šijan"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Slobodan Šijan, Nebojša Pajkić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Tasko Načić, Nikola Simić, Srdjan Šaper, Rahela Ferari, Sonja Savić, Marija Baksa, Pavle Minčić, Jelisaveta Sablić, Žika Milenković, Milutin Karadžić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milorad Glušica"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Mira Čohadžić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Vukobratović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vuk Kulenović"
      }
    ],
    director: "Slobodan Šijan",
    image: urlForMedia("movies/davitelj_protiv_davitelja/poster.jpg"),
    thumbnail: urlForMedia("movies/davitelj_protiv_davitelja/thumbnail.jpg"),
    image_cover: urlForMedia("movies/davitelj_protiv_davitelja/cover.jpg"),
    image_description: urlForMedia(
      "movies/davitelj_protiv_davitelja/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/davitelj3.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/davitelj7.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/davitelj5.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/davitelj4.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/cover.jpg")
      },
      {
        image: urlForMedia("movies/davitelj_protiv_davitelja/davitelj6.jpg")
      }
    ]
  },
  {
    id: "long_67",
    order: 67,
    slug: "tajvanska-kanasta",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1985,
    title: translate("long_67_title"),
    description: translate("long_67_description"),
    duration: "100 min",
    country: translate("long_67_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A SUPPORTING ROLE;Pula Film Festival;1985"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR CERTIFICATE;Film Festival Niš;1985"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS CERTIFICATE;Film Festival Niš;1985;"
      }
    ],
    genre: translate("long_67_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Marković"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Goran Marković, Milan Nikolić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Boris Komnenić, Neda Arnerić, Gordana  Gadžić, Radko Polič, Predrag Miki Manojlović, Bora Todorović, Semka Sokolović Bertok, Bogdan Diklić, Voja Brajović, Petar Božović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Miloš Spasojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miljen Kljaković"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vuksan Lukovac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Goran Marković",
    image: urlForMedia("movies/tajvanska_kanasta/poster.jpg"),
    thumbnail: urlForMedia("movies/tajvanska_kanasta/thumbnail.jpg"),
    image_cover: urlForMedia("movies/tajvanska_kanasta/cover.jpg"),
    image_description: urlForMedia("movies/tajvanska_kanasta/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_8.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_2.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_3.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_1.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_5.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/cover.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_6.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_4.jpg")
      },
      {
        image: urlForMedia("movies/tajvanska_kanasta/tajvanska_7.jpg")
      }
    ]
  },
  {
    id: "long_68",
    order: 68,
    slug: "za-srecu-je-potrebno-troje",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1985,
    title: translate("long_68_title"),
    description: translate("long_68_description"),
    duration: "100 min",
    country: translate("long_68_country"),
    awards: [
      {
        name: "CANNES",
        title: "GOLDEN PALM;Valencia Festival of Mediterannean Cinema;1986"
      },
      {
        name: "OTHER",
        title: "GOLEDN ARENA FOR BEST PRODUCTION DESIGN;Pula Film Festival;1986"
      },
      {
        name: "OTHER",
        title: "Sydney International Film Festival;1989;"
      }
    ],
    genre: translate("long_68_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Rajko Grlić"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Dubravka Ugrešić, Rajko Grlić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milan Žmukić, Sulejman Kapić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Mira Furlan, Predrag Miki Manojlović, Bogdan Diklić, Vanja Drah, Dubravka Ostojić, Ksenija Pajić, Mladen Budiščak, Nina Erak"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Živko Zalar"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Dinjka Jeričević"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Maja Galasso"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Živka Toplak"
      },
      {
        role: translate("role_composer_plural"),
        type: "role_composer",
        members: "Bogdan Arsovski, Vlatko Stefanovski"
      }
    ],
    director: "Rajko Grlić",
    image: urlForMedia("movies/za_srecu_je_potrebno_troje/poster.jpg"),
    thumbnail: urlForMedia("movies/za_srecu_je_potrebno_troje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/za_srecu_je_potrebno_troje/cover.jpg"),
    image_description: urlForMedia(
      "movies/za_srecu_je_potrebno_troje/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/za_srecu_je_potrebno_troje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/za_srecu_je_potrebno_troje/cover.jpg")
      },
      {
        image: urlForMedia("movies/za_srecu_je_potrebno_troje/za_srecu_1.jpg")
      }
    ]
  },
  {
    id: "long_69",
    order: 69,
    slug: "lepota-poroka",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1986,
    title: translate("long_69_title"),
    description: translate("long_69_description"),
    duration: "114 min",
    country: translate("long_69_country"),
    awards: [
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A LEADING ROLE;Pula Film Festival;1986"
      },
      {
        name: "OTHER",
        title: "SPECIAL PRIZE;Film Screenplay Festival Vrnjačka Banja;1986;"
      }
    ],
    genre: translate("long_69_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živko Nikolić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Živko Nikolić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Mira Furlan, Milutin Karadžić, Petar Božović, Mira Banjac, Eva Ras, Borislav Stjepanović, Ines Kotman, Alen Nuri, Vesna Pećenac, Miki Krstović, Fedra Ćirković, Milo Miranović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Radoslav Vladić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Mirić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Divna Lovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Zoltan Vagner"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Živko Nikolić",
    image: urlForMedia("movies/lepota_poroka/poster.jpg"),
    thumbnail: urlForMedia("movies/lepota_poroka/thumbnail.jpg"),
    image_cover: urlForMedia("movies/lepota_poroka/cover.jpg"),
    image_description: urlForMedia("movies/lepota_poroka/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/lepota_poroka/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_5.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_1.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_2.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/cover.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_7.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_4.jpg")
      },
      {
        image: urlForMedia("movies/lepota_poroka/lepota_3.jpg")
      }
    ]
  },
  {
    id: "long_70",
    order: 70,
    slug: "san-o-ruzi",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1986,
    title: translate("long_70_title"),
    description: translate("long_70_description"),
    duration: "85 min",
    country: translate("long_70_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SCREENPLAY;Pula Film Festival;1986;"
      }
    ],
    genre: translate("long_70_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zoran Tadić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Pavao Pavličić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Rade Šerbedžija, Fabijan Šovagović, Iva Marjanović, Ljuba Zečević, Anja Šovagović, Zlatko Dulić, Iva Marjanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Goran Trbuljak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Ante Nola"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Lada Gamulin"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vesna Kreber"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Alfi Kabiljo"
      }
    ],
    director: "Zoran Tadić",
    image: urlForMedia("movies/san_o_ruzi/poster.jpg"),
    thumbnail: urlForMedia("movies/san_o_ruzi/thumbnail.jpg"),
    image_cover: urlForMedia("movies/san_o_ruzi/cover.jpg"),
    image_description: urlForMedia("movies/san_o_ruzi/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/san_o_ruzi/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/san_o_ruzi/cover.jpg")
      }
    ]
  },
  {
    id: "long_71",
    order: 71,
    slug: "kraljeva-zavrsnica",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1987,
    title: translate("long_71_title"),
    description: translate("long_71_description"),
    duration: "97 min",
    country: translate("long_71_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1987"
      },
      {
        name: "OTHER",
        title: "ZLATNI VJENAC STUDIJA  AWARD;Pula Film Festival;1987;"
      }
    ],
    genre: translate("long_71_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Živorad Tomić"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Živorad Tomić, Nebojša Pajkić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Boris Gabela"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ena Begovic, Irfan Mensur, Vladica Milosavljević, Milan Štrljić, Ivo Gregurević, Bogdan Diklić, Ljubo Jelčić, Vlatko Dulić, Mirjana Majurec"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Boris Turković"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Mario Ivezić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Doris Kristić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Zoltan Vagner"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vuk Kulenović"
      }
    ],
    director: "Živorad Tomić",
    image: urlForMedia("movies/kraljeva_zavrsnica/poster.jpg"),
    thumbnail: urlForMedia("movies/kraljeva_zavrsnica/thumbnail.jpg"),
    image_cover: urlForMedia("movies/kraljeva_zavrsnica/cover.jpg"),
    image_description: urlForMedia("movies/kraljeva_zavrsnica/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_4.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_7.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_3.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_1.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_6.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/cover.jpg")
      },
      {
        image: urlForMedia("movies/kraljeva_zavrsnica/kraljeva_5.jpg")
      }
    ]
  },
  {
    id: "long_72",
    order: 72,
    slug: "lager-nis",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1987,
    title: translate("long_72_title"),
    description: translate("long_72_description"),
    duration: "92 min",
    country: translate("long_72_country"),
    awards: [
      {
        name: "OTHER",
        title: "4. JUL AWARD;Film Festival Niš;1987;"
      }
    ],
    genre: translate("long_72_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Maja Volk"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Đorđe Milojević"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Svetislav Goncić, Milan Štrljić, Miodrag Krstović, Tanasije Uzunović, Ljubiša Samardžić, Bogdan Diklić, Miodrag Radovanović, Ljiljana Blagojević, Elizabeta Đorevska, Tihomir Arsić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Milivoje Milivojević"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Nikolić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Danka Petkovska"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Katarina Stojanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/lager_nis/poster.jpg"),
    thumbnail: urlForMedia("movies/lager_nis/thumbnail.jpg"),
    image_cover: urlForMedia("movies/lager_nis/cover.jpg"),
    image_description: urlForMedia("movies/lager_nis/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/lager_nis/lager_4..jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_8.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_3.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_2.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_9.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_1.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/cover.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_10.jpg")
      },
      {
        image: urlForMedia("movies/lager_nis/lager_7.jpg")
      }
    ]
  },
  {
    id: "long_73",
    order: 73,
    slug: "oficir-s-ruzom",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1987,
    title: translate("long_73_title"),
    description: translate("long_73_description"),
    duration: "106 min",
    country: translate("long_73_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SCREENPLAY;Pula Film Festival;1987"
      },
      {
        name: "OTHER",
        title: "POLET MAGAZINE AWARD;Pula Film Festival;1987;"
      }
    ],
    genre: translate("long_73_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dejan Šorak"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dejan Šorak"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Đorđe Milojević, Sulejman Kapić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ksenija Pajić, Žarko Laušević, Dragana Mrkić, Vicko Ruić, Boris Buzančić, Vida Jerman, Andrea Baković, Lena Politeo, Zvonimir Torjanc"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Goran Trbuljak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Stanislav Dobrina"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Jasna Novak"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Vesna Lažeta"
      }
    ],
    director: "Dejan Šorak",
    image: urlForMedia("movies/oficir_s_ruzom/poster.jpg"),
    thumbnail: urlForMedia("movies/oficir_s_ruzom/thumbnail.jpg"),
    image_cover: urlForMedia("movies/oficir_s_ruzom/cover.jpg"),
    image_description: urlForMedia("movies/oficir_s_ruzom/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_8.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_6.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_7.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_3.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/cover.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_2.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_5.jpg")
      },
      {
        image: urlForMedia("movies/oficir_s_ruzom/oficir_4.jpg")
      }
    ]
  },
  {
    id: "long_74",
    order: 74,
    slug: "svet-koji-nestaje",
    documentary_type: "DOCUMENTARY",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1987,
    title: translate("long_74_title"),
    description: translate("long_74_description"),
    duration: "98 min",
    country: translate("long_74_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN MEDAL;Belgrade Film Festival;1987"
      },
      {
        name: "OTHER",
        title: "GOLDEN CERTIFICATE;Manaki Brothers Film Festival;1988"
      },
      {
        name: "OTHER",
        title:
          "PREMIO PRATO EUROPA AMBIENTE;Environmental Film Festival Florence;1988;"
      }
    ],
    genre: translate("long_74_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Petar Lalović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Lalović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Đorđe Milojević"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Petar Lalović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Đokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Petar Kralj"
      }
    ],
    director: "Petar Lalović",
    image: urlForMedia("movies/svet_koji_nestaje/oldposter.jpg"),
    thumbnail: urlForMedia("movies/svet_koji_nestaje/thumbnail.jpg"),
    image_cover: urlForMedia("movies/svet_koji_nestaje/cover.jpg"),
    image_description: urlForMedia("movies/svet_koji_nestaje/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/svet_koji_nestaje/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/svet_1.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/svet_6.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/cover.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/svet_7.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/svet_5.jpg")
      },
      {
        image: urlForMedia("movies/svet_koji_nestaje/svet_4.jpg")
      }
    ]
  },
  {
    id: "long_75",
    order: 75,
    slug: "suncokreti",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1988,
    title: translate("long_75_title"),
    description: translate("long_75_description"),
    duration: "102 min",
    country: translate("long_75_country"),
    awards: [
      {
        name: "OTHER",
        title: "EKRAN MAGAZINE AWARD;Moscow International Film Festival;1988"
      },
      {
        name: "OTHER",
        title: "SILVER ELEPHANT AWARD;Delhi International Film Festival;1988"
      },
      {
        name: "OTHER",
        title: "Berlin International Film Festival;1989;"
      }
    ],
    genre: translate("long_75_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Jovan Rančić"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Jovan Rančić, Alenka Rančić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Đorđe Milojević"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Dragomir Felba, Dušica Žegarac, Petar Kralj, Lidija Pletl, Miodrag Krstović, Predrag Laković, Rahela Ferari, Alenka Rančić, Duško Janičijević, Tanja-Biljana Milenović, Bojan Blagojević, Neda Mirilović, Jelena Mitrović, Mirjana Srdjević, Jovan Babić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Miletić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Miodrag Hadžić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Snežana Kojić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Zoltan Vagner"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Arsen Dedić"
      }
    ],
    director: "Jovan Rančić",
    image: urlForMedia("movies/suncokreti/poster.jpg"),
    thumbnail: urlForMedia("movies/suncokreti/thumbnail.jpg"),
    image_cover: urlForMedia("movies/suncokreti/cover.jpg"),
    image_description: urlForMedia("movies/suncokreti/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/suncokreti/suncokreti_5.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/suncokreti_3.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/suncokreti_1.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/suncokreti_2.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/suncokreti_4.jpg")
      },
      {
        image: urlForMedia("movies/suncokreti/cover.jpg")
      }
    ]
  },
  {
    id: "long_76",
    order: 76,
    slug: "za-sada-bez-dobrog-naslova",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1988,
    title: translate("long_76_title"),
    description: translate("long_76_description"),
    duration: "89 min",
    country: translate("long_76_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST EDITING;Pula Film Festival;1988"
      },
      {
        name: "OTHER",
        title:
          "GRAND PRIX GOLDEN TULIP;Istanbul International Film Festival;1988"
      },
      {
        name: "OTHER",
        title: "Sydney International Film Festival;1989;"
      }
    ],
    genre: translate("long_76_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Meto Jovanovski, Mira Furlan, Sonja Jačevska, Boro Begović, Čedomir Orobabić, Vlastimir Stojiljković, Mira Banjac, Eva Ras, Mića Tomić, Abdurahman Šalja, Mladen Andrejević, Enver Petrovci, Nada Gesovska, Kiro Cortosev"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Aleksandar Denić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Saša Kuljača"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/za_sada_bez_dobrog_naslova/poster.jpg"),
    thumbnail: urlForMedia("movies/za_sada_bez_dobrog_naslova/thumbnail.jpg"),
    image_cover: urlForMedia("movies/za_sada_bez_dobrog_naslova/cover.jpg"),
    image_description: urlForMedia(
      "movies/za_sada_bez_dobrog_naslova/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/za_sada_bez_dobrog_naslova/za_sada_1.jpg")
      },
      {
        image: urlForMedia("movies/za_sada_bez_dobrog_naslova/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/za_sada_bez_dobrog_naslova/za_sada_3.jpg")
      },
      {
        image: urlForMedia("movies/za_sada_bez_dobrog_naslova/cover.jpg")
      },
      {
        image: urlForMedia("movies/za_sada_bez_dobrog_naslova/za_sada_2.jpg")
      }
    ]
  },
  {
    id: "long_77",
    order: 77,
    slug: "drugi-covek",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1988,
    title: translate("long_77_title"),
    description: translate("long_77_description"),
    duration: "94 min",
    country: translate("long_77_country"),
    genre: translate("long_77_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Milan Živković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Milan Živković"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Đorđe Milojević, Milan Živković"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Deril Heni, Suzana Vouk, Irfan Mensur, Branko Cvejić, Mariola Fotez, Dragan Maksimović, Vesna Malohodžić - Čermak, Mihajlo Bata Paskaljević"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Radoslav Vladić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Ljiljana Milošević"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Sonja Stefanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Milan Živković",
    image: urlForMedia("movies/drugi_covek/poster.jpg"),
    thumbnail: urlForMedia("movies/drugi_covek/thumbnail.jpg"),
    image_cover: urlForMedia("movies/drugi_covek/cover.jpg"),
    image_description: urlForMedia("movies/drugi_covek/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/drugi_covek/drugi_4.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_1.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_8.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_2.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_7.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/cover.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_3.jpg")
      },
      {
        image: urlForMedia("movies/drugi_covek/drugi_6.jpg")
      }
    ]
  },
  {
    id: "long_78",
    order: 78,
    slug: "sabirni-centar",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1989,
    title: translate("long_78_title"),
    description: translate("long_78_description"),
    awards: [
      {
        name: "OTHER",
        title: "GRAND GOLDEN ARENA FOR BEST FILM;Pula Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST SCREENPLAY;Pula Film Festival;1989"
      },
      {
        name: "OTHER",
        title:
          "GOLDEN ARENA FOR BEST ACTRESS IN A SUPPORTING ROLE;Pula Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "KODAK - PATHÉ AWARD;Pula Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "SILVER MIMOSA FOR BEST DIRECTOR;Film Festival Herceg Novi;1989"
      },
      {
        name: "OTHER",
        title: "CAR KONSTANTIN AWARD;Film Festival Niš;1989"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX OF THE JURY;Avoriaz Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "AUDIENCE AWARD;Avoriaz Fantastic Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;Valencia Festival of Mediterannean Cinema;1989;"
      }
    ],
    genre: translate("long_78_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Goran Marković"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Dušan Kovačević, Goran Marković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Aleksandar Stojanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Rade Marković, Bogdan Diklić, Dragan Nikolić, Olivera Marković, Danilo Bata Stojković, Aleksandar Berček, Radmila Živković, Mirjana Karanović, Dušan Kostovski, Anica Dobra, Branko Pleša , Goran Daničić, Taško Načić, Vladimir Marinković, Tanasije Uzunović, Nikola Angelovski"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Tomislav Pinter"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Veljko Despotović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Jasminka Ješić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Snežana Ivanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Goran Marković",
    image: urlForMedia("movies/sabirni_centar/poster.jpg"),
    thumbnail: urlForMedia("movies/sabirni_centar/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sabirni_centar/cover.jpg"),
    image_description: urlForMedia("movies/sabirni_centar/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sabirni_centar/sabirni15.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sabirni14.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sabirni13.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sabirni17.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sabirni16.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/cover.jpg")
      },
      {
        image: urlForMedia("movies/sabirni_centar/sabirni19.jpg")
      }
    ]
  },
  {
    id: "long_79",
    order: 79,
    slug: "boj-na-kososvu",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1989,
    title: translate("long_79_title"),
    description: translate("long_79_description"),
    duration: "104 min",
    country: translate("long_79_country"),
    genre: translate("long_79_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Šotra"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ljubomir Simović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rodoljub Đoković, Mladen Todić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Miloš Žutić, Gorica Popović, Vojislav Brajović, Žarko Laušević, Ljuba Tadić, Branislav Lečić, Katarina Gojković, Predrag Laković, Irfan Mensur, Radoslav Milenković, Neda Arnerić, Svetozar Cvetković, Dušan Janićijević"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Radoslav Vladić"
      },
      {
        role: translate("role_scenography_plural"),
        type: "role_scenography",
        members: "Aleksandar Milović, Milenko Jeremić"
      },
      {
        role: translate("role_costume_plural"),
        type: "role_costume",
        members: "Biljana Dragović, Ljiljana Dragović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ljiljana Vukobratović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Dušan Karuović"
      }
    ],
    director: "Zdravko Šotra",
    image: urlForMedia("movies/boj_na_kosovu/poster.jpg"),
    thumbnail: urlForMedia("movies/boj_na_kosovu/thumbnail.jpg"),
    image_cover: urlForMedia("movies/boj_na_kosovu/cover.jpg"),
    image_description: urlForMedia("movies/boj_na_kosovu/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/boj_na_kosovu/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_5.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_4.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/cover.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_7.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_1.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_8.jpg")
      },
      {
        image: urlForMedia("movies/boj_na_kosovu/boj_6.jpg")
      }
    ]
  },
  {
    id: "long_80",
    order: 80,
    slug: "zena-s-krajolikom",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1989,
    title: translate("long_80_title"),
    description: translate("long_80_description"),
    duration: "75 min",
    country: translate("long_80_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR BEST DIRECTOR;Pula Film Festival;1989"
      },
      {
        name: "OTHER",
        title: "SPECIAL JURY AWARD;Film Festival Herceg Novi;1989"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX OF THE JURY;Montreal World Film Festival;1990"
      },
      {
        name: "OTHER",
        title: "FIPRESCI AWARD;Montreal World Film Festival;1990"
      },
      {
        name: "OTHER",
        title: "PRIZEOF THE ECUMENICALJURY;Montreal World Film Festival;1990"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;Montreal World Film Festival;1990"
      },
      {
        name: "OTHER",
        title: "Torino Film Festival;1990;"
      }
    ],
    genre: translate("long_80_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ivica Matić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ivica Matić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Stojan Aranđelović, Božidarka Frajt, Uroš Kravljača, Zaim Muzaferija, Jadranka Matković, Špela Rozin, Miodrag Brezo, Ratko Petković, Adnan Palangić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Karpo Godina"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Ljiljana Pejčinović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Vanja Popović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Ruža Cvingl"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Jasna Spaić"
      }
    ],
    director: "Ivica Matić",
    image: urlForMedia("movies/zena_s_krajolikom/poster.jpg"),
    thumbnail: urlForMedia("movies/zena_s_krajolikom/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zena_s_krajolikom/zena_1.jpg"),
    image_description: urlForMedia("movies/zena_s_krajolikom/zena_2.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/zena_s_krajolikom/zena_1.jpg")
      },
      {
        image: urlForMedia("movies/zena_s_krajolikom/zena_2.jpg")
      }
    ]
  },
  {
    id: "long_81",
    order: 81,
    slug: "ovo-malo-duse",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1990,
    title: translate("long_81_title"),
    description: translate("long_81_description"),
    duration: "83 min",
    country: translate("long_81_country"),
    genre: translate("long_81_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ademir Kenović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Božić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Senad Zvizdić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Branko Đurić, Davor Janjić, Borislav Stjepanović, Zaim Muzaferija, Snježana Sinovčić, Saša Petrović, Sabrina Sadiković, Branko Bajić, Mladen Nelević, Božidar Bukavac, Stjepan Marović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Mustafa Mustafić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Kemal Hrustanović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Sanja Džeba"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Kristel Tanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Esad Arnautalić"
      }
    ],
    director: "Ademir Kenović",
    image: urlForMedia("movies/ovo_malo_duse/poster.jpg"),
    thumbnail: urlForMedia("movies/ovo_malo_duse/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ovo_malo_duse/1.jpg"),
    image_description: urlForMedia("movies/ovo_malo_duse/3.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ovo_malo_duse/1.jpg")
      },
      {
        image: urlForMedia("movies/ovo_malo_duse/3.jpg")
      },
      {
        image: urlForMedia("movies/ovo_malo_duse/2.jpg")
      },
      {
        image: urlForMedia("movies/ovo_malo_duse/4.jpg")
      }
    ]
  },
  {
    id: "long_82",
    order: 82,
    slug: "stanica-obicnih-vozova",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1990,
    title: translate("long_82_title"),
    description: translate("long_82_description"),
    duration: "83 min",
    country: translate("long_82_country"),
    genre: translate("long_82_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Nenad Dizdarević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Božić"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Rade Bojat, Slavenka Divjak, Neđo Perović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Ljiljana Medješi, Emir Hadžihafizbegović, Enver Petrovci, Jordančo Čevrevski, Nada Pani, Davor Janjić, Zaim Muzaferija, Vlado Kerošević, Božidar Bunjevac, Meto Jovanoski, Zdravko Biogradlija, Dragan Dankić, Hasija Borić, Hajrudin Hodžić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Mustafa Mustafić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Kemal Hrustanović"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Sanja Džeba"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Kristl Tanović"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Nenad Dizdarević",
    image: urlForMedia("movies/stanica_obicnih_vozova/poster.jpg"),
    thumbnail: urlForMedia("movies/stanica_obicnih_vozova/thumbnail.jpg"),
    image_cover: urlForMedia("movies/stanica_obicnih_vozova/cover.jpg"),
    image_description: urlForMedia(
      "movies/stanica_obicnih_vozova/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/stanica_obicnih_vozova/stanica_2.jpg")
      },
      {
        image: urlForMedia("movies/stanica_obicnih_vozova/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/stanica_obicnih_vozova/cover.jpg")
      },
      {
        image: urlForMedia("movies/stanica_obicnih_vozova/stanica_1.jpg")
      }
    ]
  },
  {
    id: "long_83",
    order: 83,
    slug: "plasticni-isus",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1990,
    title: translate("long_83_title"),
    description: translate("long_83_description"),
    duration: "83 min",
    country: translate("long_83_country"),
    awards: [
      {
        name: "OTHER",
        title: "FIPRESCI AWARD;Montreal World Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Istanbul International Film Festival;1991;"
      }
    ],
    genre: translate("long_83_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Lazar Stojanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Lazar Stojanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Sreten Jovanović"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members: "Tomislav Gotovac, Svetlana Gligorijević, Vukica Đilas"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Branko Perak"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Lazar Stojanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Lazar Stojanović"
      }
    ],
    director: "Lazar Stojanović",
    image: urlForMedia("movies/plasticni_isus/poster.jpg"),
    thumbnail: urlForMedia("movies/plasticni_isus/thumbnail.jpg"),
    image_cover: urlForMedia("movies/plasticni_isus/cover.jpg"),
    image_description: urlForMedia("movies/plasticni_isus/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/plasticni_isus/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/plasticni_isus/cover.jpg")
      }
    ]
  },
  {
    id: "long_84",
    order: 84,
    slug: "virdzina",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1991,
    title: translate("long_84_title"),
    description: translate("long_84_description"),
    duration: "141 min",
    country: translate("long_84_country"),
    awards: [
      {
        name: "FELIX",
        title: "FELIX AWARD;Berlin International Film Festival;1991"
      },
      {
        name: "CANNES",
        title: "GOLDEN PALM;Valencia Festival of Mediterranean Cinema;1992"
      },
      {
        name: "OTHER",
        title: "BEST SCREENPLAY;Film Screenplay Festival Vrnjačka Banja;1991"
      },
      {
        name: "OTHER",
        title: "CAR KONSTANTIN AWARD;Film Festival Niš;1991"
      },
      {
        name: "OTHER",
        title: "GRAND PRIX;La Baule European Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Vancouver International Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Istanbul International Film Festival;1992"
      },
      {
        name: "OTHER",
        title: "Jerusalem Film Festival;1992"
      },
      {
        name: "OTHER",
        title: "Goteborg International Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "San Sebastian International Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Montreal World Film Festival;1991"
      },
      {
        name: "OTHER",
        title: "Karlovy Vary International Film Festival;1992;"
      }
    ],
    genre: translate("long_84_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members:
          "Rajko Grlić, Mladen Koceić, Čedomir Kolar, Đorđe Milojević, Cloudie Ossard"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Marta Keler, Miodrag Krivokapić, Ina Gogalova, Igor  Bjelan, Slobodan  Milovanović, Slađana Babić"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Slobodan  Trninić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Nikola Pajić"
      },
      {
        role: translate("role_costume_plural"),
        type: "role_costume",
        members: "Sanda Jakubek, Mirjana Kerkez, Franjo Simek"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Zoran Simjanović"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/virdzina/poster.jpg"),
    thumbnail: urlForMedia("movies/virdzina/thumbnail.jpg"),
    image_cover: urlForMedia("movies/virdzina/cover.jpg"),
    image_description: urlForMedia("movies/virdzina/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/virdzina/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/virdzina/cover.jpg")
      },
      {
        image: urlForMedia("movies/virdzina/virdzina_1.jpg")
      }
    ]
  },
  {
    id: "long_85",
    order: 85,
    slug: "tri-karte-za-holivud",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1993,
    title: translate("long_85_title"),
    description: translate("long_85_description"),
    duration: "110 min",
    country: translate("long_85_country"),
    awards: [
      {
        name: "OTHER",
        title: "BEST DOP;European Film Festival Palić;1993"
      },
      {
        name: "OTHER",
        title: "SILVER MIMOZA AWARD;Film Festival Herceg Novi;1993"
      },
      {
        name: "OTHER",
        title: "AUDIENCE AWARD;Film FestivalHerceg Novi;1993"
      },
      {
        name: "OTHER",
        title: "BEST ACTOR;Film Festival Nis;1993"
      },
      {
        name: "OTHER",
        title: "BEST ACTRESS;Film Festival Nis;1993"
      },
      {
        name: "OTHER",
        title:
          "SILVER ARENA FOR BEST FILM;International Film Festival Novi Sad;1993"
      },
      {
        name: "OTHER",
        title: "Moscow Internatioanl Film Festival;1994;"
      }
    ],
    genre: translate("long_85_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Željko Mijanović"
      },
      {
        role: translate("role_producer_plural"),
        type: "role_producer",
        members: "Milenko Stanković, Momcilo Pešić, Slobodan Dedeić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Branislav Lečić, Bogdan Diklić, Ljubiša Samardžić, Neda Arnerić, Vesna Stanojević, Velimir Bata Živojinović, Branislav Jerinić, Dragan Nikolić, Slobodan Ćustić, Vesna Čipčić, Milutin Karadžić, Danilo Lazović, Petar Baničević, Dušan Janićijević, Josif Tatić, Stojan Aranđelović, Lidija Pletl, Milan Sretenović, Predrag Milinković"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Božidar Nikolić"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Ljiljana Dragović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Branka Čeperac"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vojislav Kostić"
      }
    ],
    director: "Božidar Nikolić",
    image: urlForMedia("movies/tri_karte_za_holivud/poster.jpg"),
    thumbnail: urlForMedia("movies/tri_karte_za_holivud/thumbnail.jpg"),
    image_cover: urlForMedia("movies/tri_karte_za_holivud/cover.jpg"),
    image_description: urlForMedia("movies/tri_karte_za_holivud/sinopsis.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/tri_karte_za_holivud/tri_karte_4.jpg")
      },
      {
        image: urlForMedia("movies/tri_karte_za_holivud/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/tri_karte_za_holivud/tri_karte_2.jpg")
      },
      {
        image: urlForMedia("movies/tri_karte_za_holivud/tri_karte_3.jpg")
      },
      {
        image: urlForMedia("movies/tri_karte_za_holivud/cover.jpg")
      },
      {
        image: urlForMedia("movies/tri_karte_za_holivud/tri_karte_1.jpg")
      }
    ]
  },
  {
    id: "long_86",
    order: 86,
    slug: "zoran-radmilovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1996,
    title: translate("long_86_title"),
    description: translate("long_86_description"),
    duration: "54 min",
    country: translate("long_86_country"),
    genre: translate("long_86_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dušan Joksimović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/zoran_radmilovic/poster.jpg"),
    thumbnail: urlForMedia("movies/zoran_radmilovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zoran_radmilovic/poster_old.jpg"),
    image_description: urlForMedia("movies/zoran_radmilovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/zoran_radmilovic/poster_old.jpg")
      }
    ]
  },
  {
    id: "long_87",
    order: 87,
    slug: "pavle-vuisic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1997,
    title: translate("long_87_title"),
    description: translate("long_87_description"),
    duration: "56 min",
    country: translate("long_87_country"),
    genre: translate("long_87_genre"),
    people: [
      {
        role: translate("role_director_plural"),
        type: "role_director",
        members: "Srđan Karanović, Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dušan Joksimović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Srđan Karanović, Zdravko Randić",
    image: urlForMedia("movies/pavle_vuisic/poster.jpg"),
    thumbnail: urlForMedia("movies/pavle_vuisic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/pavle_vuisic/thumbnail.jpg"),
    image_description: urlForMedia("movies/pavle_vuisic/postr.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/pavle_vuisic/postr.jpg")
      }
    ]
  },
  {
    id: "long_88",
    order: 88,
    slug: "mija-aleksic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_88_title"),
    description: translate("long_88_description"),
    duration: "57 min",
    country: translate("long_88_country"),
    genre: translate("long_88_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dušan Joksimović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/mija_aleksic/poster.jpg"),
    thumbnail: urlForMedia("movies/mija_aleksic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/mija_aleksic/posterr.jpg"),
    image_description: urlForMedia("movies/mija_aleksic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/mija_aleksic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_89",
    order: 89,
    slug: "milivoje-ivanovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1997,
    title: translate("long_89_title"),
    description: translate("long_89_description"),
    genre: translate("long_89_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Srđan Karanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Volk"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dušan Joksimović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      }
    ],
    director: "Srđan Karanović",
    image: urlForMedia("movies/milivoje_zivanovic/poster.jpg"),
    thumbnail: urlForMedia("movies/milivoje_zivanovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/milivoje_zivanovic/thumbnail.jpg"),
    image_description: urlForMedia("movies/milivoje_zivanovic/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/milivoje_zivanovic/postter.jpg")
      }
    ]
  },
  {
    id: "long_90",
    order: 90,
    slug: "danilo-bata-stojkovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_90_title"),
    description: translate("long_90_description"),
    genre: translate("long_90_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Panov"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko  Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/bata_stojkovic/poster.jpg"),
    thumbnail: urlForMedia("movies/bata_stojkovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/bata_stojkovic/thumbnail.jpg"),
    image_description: urlForMedia("movies/bata_stojkovic/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/bata_stojkovic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_91",
    order: 91,
    slug: "dusica-zegarac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_91_title"),
    description: translate("long_91_description"),
    genre: translate("long_91_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Panov"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/dusica_zegarac/poster.jpg"),
    thumbnail: urlForMedia("movies/dusica_zegarac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dusica_zegarac/posterr.jpg"),
    image_description: urlForMedia("movies/dusica_zegarac/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dusica_zegarac/posterr.jpg")
      }
    ]
  },
  {
    id: "long_92",
    order: 92,
    slug: "dragan-nikolic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_92_title"),
    description: translate("long_92_description"),
    genre: translate("long_92_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Milan Vlajičić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Sanjin Perišić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/dragan_nikolic/poster.jpg"),
    thumbnail: urlForMedia("movies/dragan_nikolic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dragan_nikolic/thumbnail.jpg"),
    image_description: urlForMedia("movies/dragan_nikolic/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dragan_nikolic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_93",
    order: 93,
    slug: "mihajlo-bata-paskaljevic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_93_title"),
    description: translate("long_93_description"),
    genre: translate("long_93_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Sanjin Perišić"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/bata_paskaljevic/poster.jpg"),
    thumbnail: urlForMedia("movies/bata_paskaljevic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/bata_paskaljevic/poster_old.jpg"),
    image_description: urlForMedia("movies/bata_paskaljevic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/bata_paskaljevic/poster_old.jpg")
      }
    ]
  },
  {
    id: "long_94",
    order: 94,
    slug: "aleksandar-bercek",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2002,
    title: translate("long_94_title"),
    description: translate("long_94_description"),
    genre: translate("long_94_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor_plural"),
        type: "role_editor",
        members: "Sanjin Perišić, Violeta Trpčevski"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/aleksandar_bercek/poster.jpg"),
    thumbnail: urlForMedia("movies/aleksandar_bercek/thumbnail.jpg"),
    image_cover: urlForMedia("movies/aleksandar_bercek/thumbnail.jpg"),
    image_description: urlForMedia("movies/aleksandar_bercek/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/aleksandar_bercek/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_95",
    order: 95,
    slug: "bora-todorovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2003,
    title: translate("long_95_title"),
    description: translate("long_95_description"),
    genre: translate("long_95_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/bora_todorovic/poster.jpg"),
    thumbnail: urlForMedia("movies/bora_todorovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/bora_todorovic/thumbnail.jpg"),
    image_description: urlForMedia("movies/bora_todorovic/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/bora_todorovic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_96",
    order: 96,
    slug: "milena-dravic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2004,
    title: translate("long_96_title"),
    description: translate("long_96_description"),
    genre: translate("long_96_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Manojlović"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/milena_dravic/poster.jpg"),
    thumbnail: urlForMedia("movies/milena_dravic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/milena_dravic/posteer.jpg"),
    image_description: urlForMedia("movies/milena_dravic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/milena_dravic/posteer.jpg")
      }
    ]
  },
  {
    id: "long_97",
    order: 97,
    slug: "ruzica-sokic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2004,
    title: translate("long_97_title"),
    description: translate("long_97_description"),
    genre: translate("long_97_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Predrag Popović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Manojlović"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/ruzica_sokic/poster.jpg"),
    thumbnail: urlForMedia("movies/ruzica_sokic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ruzica_sokic/thumbnail.jpg"),
    image_description: urlForMedia("movies/ruzica_sokic/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ruzica_sokic/postter.jpg")
      }
    ]
  },
  {
    id: "long_98",
    order: 98,
    slug: "predrag-miki-manojlovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2005,
    title: translate("long_98_title"),
    description: translate("long_98_description"),
    genre: translate("long_98_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Čarna Manojlović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Čarna Manojlović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Čarna Manojlović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Čarna Manojlović",
    image: urlForMedia("movies/miki_manojlovic/poster.jpg"),
    thumbnail: urlForMedia("movies/miki_manojlovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/miki_manojlovic/posterr.jpg"),
    image_description: urlForMedia("movies/miki_manojlovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/miki_manojlovic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_99",
    order: 99,
    slug: "ljuba-tadic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2005,
    title: translate("long_99_title"),
    description: translate("long_99_description"),
    genre: translate("long_99_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Ranko Munitić",
    image: urlForMedia("movies/ljuba_tadic/poster.jpg"),
    thumbnail: urlForMedia("movies/ljuba_tadic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ljuba_tadic/thumbnail.jpg"),
    image_description: urlForMedia("movies/ljuba_tadic/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ljuba_tadic/postter.jpg")
      }
    ]
  },
  {
    id: "long_100",
    order: 100,
    slug: "nikola-simic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2006,
    title: translate("long_100_title"),
    description: translate("long_100_description"),
    genre: translate("long_100_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/nikola_simic/poster.jpg"),
    thumbnail: urlForMedia("movies/nikola_simic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/nikola_simic/posterr.jpg"),
    image_description: urlForMedia("movies/nikola_simic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/nikola_simic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_101",
    order: 101,
    slug: "petar-kralj",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2007,
    title: translate("long_101_title"),
    description: translate("long_101_description"),
    genre: translate("long_101_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/petar_kralj/pposter.jpg"),
    thumbnail: urlForMedia("movies/petar_kralj/thumbnail.jpg"),
    image_cover: urlForMedia("movies/petar_kralj/thumbnail.jpg"),
    image_description: urlForMedia("movies/petar_kralj/posterold.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/petar_kralj/posterold.jpg")
      },
      {
        image: urlForMedia("movies/petar_kralj/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_102",
    order: 102,
    slug: "ita-rina-secate-li-se-ita-rine",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2007,
    title: translate("long_102_title"),
    description: translate("long_102_description"),
    genre: translate("long_102_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ilja Slani"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ilja Slani"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Ilja Slani",
    image: urlForMedia("movies/ita_rina/poster.jpg"),
    thumbnail: urlForMedia("movies/ita_rina/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ita_rina/posterr.jpg"),
    image_description: urlForMedia("movies/ita_rina/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ita_rina/posterr.jpg")
      }
    ]
  },
  {
    id: "long_103",
    order: 103,
    slug: "mira-stupica",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2009,
    title: translate("long_103_title"),
    description: translate("long_103_description"),
    genre: translate("long_103_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ranko Munitić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Ranko Munitić",
    image: urlForMedia("movies/mira_stupica/poster.jpg"),
    thumbnail: urlForMedia("movies/mira_stupica/thumbnail.jpg"),
    image_cover: urlForMedia("movies/mira_stupica/posterr.jpg"),
    image_description: urlForMedia("movies/mira_stupica/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/mira_stupica/posterr.jpg")
      }
    ]
  },
  {
    id: "long_104",
    order: 104,
    slug: "mira-banjac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2009,
    title: translate("long_104_title"),
    description: translate("long_104_description"),
    genre: translate("long_104_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Goran Dobrosavljević"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/mira_banjac/poster.jpg"),
    thumbnail: urlForMedia("movies/mira_banjac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/mira_banjac/posterr.jpg"),
    image_description: urlForMedia("movies/mira_banjac/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/mira_banjac/posterr.jpg")
      }
    ]
  },
  {
    id: "long_105",
    order: 105,
    slug: "bogdan-diklic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2010,
    title: translate("long_105_title"),
    description: translate("long_105_description"),
    genre: translate("long_105_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Kalezić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/bogdan_diklic/poster.jpg"),
    thumbnail: urlForMedia("movies/bogdan_diklic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/bogdan_diklic/thumbnail.jpg"),
    image_description: urlForMedia("movies/bogdan_diklic/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/bogdan_diklic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_106",
    order: 106,
    slug: "neda-arneric",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2011,
    title: translate("long_106_title"),
    description: translate("long_106_description"),
    genre: translate("long_106_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Aleksandar Kalezić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/neda_arneric/poster.jpg"),
    thumbnail: urlForMedia("movies/neda_arneric/thumbnail.jpg"),
    image_cover: urlForMedia("movies/neda_arneric/posterr.jpg"),
    image_description: urlForMedia("movies/neda_arneric/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/neda_arneric/posterr.jpg")
      }
    ]
  },
  {
    id: "long_107",
    order: 107,
    slug: "petar-bozovic-petar-secam-se",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2012,
    title: translate("long_107_title"),
    description: translate("long_107_description"),
    genre: translate("long_107_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Nebojša Radosavljević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Nebojša Radosavljević"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Aleksandar Kalezić, Miodrag  Jelić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      }
    ],
    director: "Nebojša Radosavljević",
    image: urlForMedia("movies/petar_bozovic/pposter.jpg"),
    thumbnail: urlForMedia("movies/petar_bozovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/petar_bozovic/pposterold.jpg"),
    image_description: urlForMedia("movies/petar_bozovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/petar_bozovic/pposterold.jpg")
      },
      {
        image: urlForMedia("movies/petar_bozovic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_108",
    order: 108,
    slug: "marko-nikolic-zmaj-i-dunav",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2013,
    title: translate("long_108_title"),
    description: translate("long_108_description"),
    genre: translate("long_108_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Sara Vulović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Sara Vulović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Matija Munjiza Petrović, Milan Kolarski"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Nikola Silić"
      }
    ],
    director: "Sara Vulović",
    image: urlForMedia("movies/marko_nikolic/poster.jpg"),
    thumbnail: urlForMedia("movies/marko_nikolic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/marko_nikolic/posterr.jpg"),
    image_description: urlForMedia("movies/marko_nikolic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/marko_nikolic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_109",
    order: 109,
    slug: "misa-janketic-jedna-prica",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2015,
    title: translate("long_109_title"),
    description: translate("long_109_description"),
    genre: translate("long_109_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Vladan Obradović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Miodrag Cvetković"
      }
    ],
    director: "Tatjana Peternek Aleksić",
    image: urlForMedia("movies/misa_janketic/poster.jpg"),
    thumbnail: urlForMedia("movies/misa_janketic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/misa_janketic/posteer.jpg"),
    image_description: urlForMedia("movies/misa_janketic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/misa_janketic/posteer.jpg")
      }
    ]
  },
  {
    id: "long_110",
    order: 110,
    slug: "jelisaveta-seka-sablic-danas-je-lepo-biti-ja",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2016,
    title: translate("long_110_title"),
    description: translate("long_110_description"),
    genre: translate("long_110_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Vladan Obradović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Maja Veljić"
      }
    ],
    director: "Tatjana Peternek Aleksić",
    image: urlForMedia("movies/seka_sablic/poster.jpg"),
    thumbnail: urlForMedia("movies/seka_sablic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/seka_sablic/posterr.jpg"),
    image_description: urlForMedia("movies/seka_sablic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/seka_sablic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_111",
    order: 111,
    slug: "lazar-ristovski-kako-sam-postao-mladic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2017,
    title: translate("long_111_title"),
    description: translate("long_111_description"),
    genre: translate("long_111_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Vladan Obradović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Maja Veljić"
      }
    ],
    director: "Tatjana Peternek Aleksić",
    image: urlForMedia("movies/lazar_ristovski/poster.jpg"),
    thumbnail: urlForMedia("movies/lazar_ristovski/thumbnail.jpg"),
    image_cover: urlForMedia("movies/lazar_ristovski/posterr.jpg"),
    image_description: urlForMedia("movies/lazar_ristovski/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/lazar_ristovski/posterr.jpg")
      }
    ]
  },
  {
    id: "long_112",
    order: 112,
    slug: "svetlana-ceca-bojkovic-gluma-je-kad-se-ne-vidi",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2018,
    title: translate("long_112_title"),
    description: translate("long_112_description"),
    genre: translate("long_112_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ana Maria Rossi"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ana Maria Rossi"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor_plural"),
        type: "role_editor",
        members: "i Milo Lekić, Sanjin Perišić"
      }
    ],
    director: "Ana Maria Rossi",
    image: urlForMedia("movies/svetlana_bojkovic/poster.jpg"),
    thumbnail: urlForMedia("movies/svetlana_bojkovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/svetlana_bojkovic/posterr.jpg"),
    image_description: urlForMedia("movies/svetlana_bojkovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/svetlana_bojkovic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_113",
    order: 113,
    slug: "rados-bajic-moja-iskrenost-u-glumi",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2019,
    title: translate("long_113_title"),
    description: translate("long_113_description"),
    genre: translate("long_113_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Tatjana Peternek Aleksić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Vladan Obradović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Maja Veljić"
      }
    ],
    director: "Tatjana Peternek Aleksić",
    image: urlForMedia("movies/rados_bajic/poster.jpg"),
    thumbnail: urlForMedia("movies/rados_bajic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/rados_bajic/posterr.jpg"),
    image_description: urlForMedia("movies/rados_bajic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/rados_bajic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_114",
    order: 114,
    slug: "gorica-popovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2020,
    title: translate("long_114_title"),
    description: translate("long_114_description"),
    genre: translate("long_114_genre"),
    people: [
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      }
    ],
    director: "",
    image: urlForMedia("movies/gorica_popovic/poster.jpg"),
    thumbnail: urlForMedia("movies/gorica_popovic/poster.jpg"),
    image_cover: urlForMedia("movies/gorica_popovic/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "long_115",
    order: 115,
    slug: "voja-brajovic-tesko-naoruzan-glumac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2021,
    title: translate("long_115_title"),
    description: translate("long_115_description"),
    genre: translate("long_115_genre"),
    people: [],
    director: "",
    image: urlForMedia("movies/voja_brajovic/poster.jpg"),
    thumbnail: urlForMedia("movies/voja_brajovic/poster.jpg"),
    image_cover: urlForMedia("movies/voja_brajovic/poster.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "long_116",
    order: 116,
    slug: "nikola-pasic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1990,
    title: translate("long_116_title"),
    description: translate("long_116_description"),
    genre: translate("long_116_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Vasa Kazimirović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Jovica Krstić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Đokić"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/nikola_pasic/poster.jpg"),
    thumbnail: urlForMedia("movies/nikola_pasic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/nikola_pasic/posterr.jpg"),
    image_description: urlForMedia("movies/nikola_pasic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/nikola_pasic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_117",
    order: 117,
    slug: "zivojin-misic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_117_title"),
    description: translate("long_117_description"),
    genre: translate("long_117_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Vasa Kazimirović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelica Dokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/zivojin_misic/oldposter.jpg"),
    thumbnail: urlForMedia("movies/zivojin_misic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/zivojin_misic/oldposter.jpg"),
    image_description: urlForMedia("movies/zivojin_misic/thumbnail.jpg"),
    locked: false,
    slideshow: []
  },
  {
    id: "long_118",
    order: 118,
    slug: "petar-bojovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_118_title"),
    description: translate("long_118_description"),
    genre: translate("long_118_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Stevan Jovičić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Dušan Krivokapić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/petar_bojovic/poster.jpg"),
    thumbnail: urlForMedia("movies/petar_bojovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/petar_bojovic/posterr.jpg"),
    image_description: urlForMedia("movies/petar_bojovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/petar_bojovic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_119",
    order: 119,
    slug: "jovan-jovanovic-zmaj",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_119_title"),
    description: translate("long_119_description"),
    genre: translate("long_119_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Branko Milošević"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Pera Zubac"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Dušan Čekić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Siniša Bokan"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Predrag Vranešević"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Ratko Radivojević"
      }
    ],
    director: "Branko Milošević",
    image: urlForMedia("movies/j.j._zmaj/poster.jpg"),
    thumbnail: urlForMedia("movies/j.j._zmaj/poster.jpg"),
    image_cover: urlForMedia("movies/j.j._zmaj/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/j.j._zmaj/poster.jpg")
      }
    ]
  },
  {
    id: "long_120",
    order: 120,
    slug: "danilo-kis",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_120_title"),
    description: translate("long_120_description"),
    genre: translate("long_120_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Filip David"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Filip David"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Mirjana Miočinović"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Filip David",
    image: urlForMedia("movies/danilo_kis/poster.jpg"),
    thumbnail: urlForMedia("movies/danilo_kis/thumbnail.jpg"),
    image_cover: urlForMedia("movies/danilo_kis/thumbnail.jpg"),
    image_description: urlForMedia("movies/danilo_kis/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/danilo_kis/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_121",
    order: 121,
    slug: "joakim-vujic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_121_title"),
    description: translate("long_121_description"),
    genre: translate("long_121_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Volk"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Jovica Krstić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelica Đokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/joakim_vujic/poster.jpg"),
    thumbnail: urlForMedia("movies/joakim_vujic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/joakim_vujic/posterr.jpg"),
    image_description: urlForMedia("movies/joakim_vujic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/joakim_vujic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_122",
    order: 122,
    slug: "stevan-mokranjac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1997,
    title: translate("long_122_title"),
    description: translate("long_122_description"),
    genre: translate("long_122_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Volk"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Jovica Krstić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko  Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/stevan_mokranjac/poster.jpg"),
    thumbnail: urlForMedia("movies/stevan_mokranjac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/stevan_mokranjac/pposterold.jpg"),
    image_description: urlForMedia("movies/stevan_mokranjac/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/stevan_mokranjac/pposterold.jpg")
      }
    ]
  },
  {
    id: "long_123",
    order: 123,
    slug: "ljuba-popovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1995,
    title: translate("long_123_title"),
    description: translate("long_123_description"),
    genre: translate("long_123_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Ilja Slani"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Ilja Slani"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Bata Grbić"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Igor Kušić"
      }
    ],
    director: "Ilja Slani",
    image: urlForMedia("movies/ljuba_popovic/poster.jpg"),
    thumbnail: urlForMedia("movies/ljuba_popovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ljuba_popovic/posteer.jpg"),
    image_description: urlForMedia("movies/ljuba_popovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ljuba_popovic/posteer.jpg")
      }
    ]
  },
  {
    id: "long_124",
    order: 124,
    slug: "paja-jovanovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1997,
    title: translate("long_124_title"),
    description: translate("long_124_description"),
    genre: translate("long_124_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/paja_jovanovic/poster.jpg"),
    thumbnail: urlForMedia("movies/paja_jovanovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/paja_jovanovic/posterr.jpg"),
    image_description: urlForMedia("movies/paja_jovanovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/paja_jovanovic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_125",
    order: 125,
    slug: "sava-sumanovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_125_title"),
    description: translate("long_125_description"),
    genre: translate("long_125_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko  Obračević"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/sava_sumanovic/poster.jpg"),
    thumbnail: urlForMedia("movies/sava_sumanovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sava_sumanovic/posterr.jpg"),
    image_description: urlForMedia("movies/sava_sumanovic/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sava_sumanovic/posterr.jpg")
      }
    ]
  },
  {
    id: "long_126",
    order: 126,
    slug: "ilija-basicevic-bosilj",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_126_title"),
    description: translate("long_126_description"),
    genre: translate("long_126_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Žika Mitrović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Žika Mitrović",
    image: urlForMedia("movies/ilija_bosilj/poster.jpg"),
    thumbnail: urlForMedia("movies/ilija_bosilj/thumbnail.jpg"),
    image_cover: urlForMedia("movies/ilija_bosilj/posterr.jpg"),
    image_description: urlForMedia("movies/ilija_bosilj/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/ilija_bosilj/posterr.jpg")
      }
    ]
  },
  {
    id: "long_127",
    order: 127,
    slug: "uros-predic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_127_title"),
    description: translate("long_127_description"),
    genre: translate("long_127_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Milo Lekić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/uros_predic/poster.jpg"),
    thumbnail: urlForMedia("movies/uros_predic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/uros_predic/thumbnail.jpg"),
    image_description: urlForMedia("movies/uros_predic/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/uros_predic/postter.jpg")
      }
    ]
  },
  {
    id: "long_128",
    order: 128,
    slug: "sava-stojkov",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_128_title"),
    description: translate("long_128_description"),
    genre: translate("long_128_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/sava_stojkov/poster.jpg"),
    thumbnail: urlForMedia("movies/sava_stojkov/thumbnail.jpg"),
    image_cover: urlForMedia("movies/sava_stojkov/posteer.jpg"),
    image_description: urlForMedia("movies/sava_stojkov/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/sava_stojkov/posteer.jpg")
      }
    ]
  },
  {
    id: "long_129",
    order: 129,
    slug: "alzbeta-cizikova",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_129_title"),
    description: translate("long_129_description"),
    genre: translate("long_129_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/alzbeta_cizikova/poster.jpg"),
    thumbnail: urlForMedia("movies/alzbeta_cizikova/thumbnail.jpg"),
    image_cover: urlForMedia("movies/alzbeta_cizikova/thumbnail.jpg"),
    image_description: urlForMedia("movies/alzbeta_cizikova/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/alzbeta_cizikova/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_130",
    order: 130,
    slug: "jan-bacur",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_130_title"),
    description: translate("long_130_description"),
    genre: translate("long_130_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/jan_bacur/poster.jpg"),
    thumbnail: urlForMedia("movies/jan_bacur/thumbnail.jpg"),
    image_cover: urlForMedia("movies/jan_bacur/pposterold.jpg"),
    image_description: urlForMedia("movies/jan_bacur/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/jan_bacur/pposterold.jpg")
      }
    ]
  },
  {
    id: "long_131",
    order: 131,
    slug: "martin-jonas",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_131_title"),
    description: translate("long_131_description"),
    genre: translate("long_131_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Violeta Trpčevski"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/martin_jonas/poster.jpg"),
    thumbnail: urlForMedia("movies/martin_jonas/thumbnail.jpg"),
    image_cover: urlForMedia("movies/martin_jonas/pposterold.jpg"),
    image_description: urlForMedia("movies/martin_jonas/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/martin_jonas/pposterold.jpg")
      }
    ]
  },
  {
    id: "long_132",
    order: 132,
    slug: "gvozdeni-put",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1993,
    title: translate("long_132_title"),
    description: translate("long_132_description"),
    genre: translate("long_132_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Bora Radović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko  Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/gvozdeni_put/poster.jpg"),
    thumbnail: urlForMedia("movies/gvozdeni_put/thumbnail.jpg"),
    image_cover: urlForMedia("movies/gvozdeni_put/thumbnail.jpg"),
    image_description: urlForMedia("movies/gvozdeni_put/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/gvozdeni_put/postter.jpg")
      }
    ]
  },
  {
    id: "long_133",
    order: 133,
    slug: "koreni-srpske-medicine",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_133_title"),
    description: translate("long_133_description"),
    genre: translate("long_133_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Ratko Radivojević"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/koreni_srpske_mediine/poster.jpg"),
    thumbnail: urlForMedia("movies/koreni_srpske_mediine/thumbnail.jpg"),
    image_cover: urlForMedia("movies/koreni_srpske_mediine/posterr.jpg"),
    image_description: urlForMedia(
      "movies/koreni_srpske_mediine/thumbnail.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/koreni_srpske_mediine/posterr.jpg")
      }
    ]
  },
  {
    id: "long_134",
    order: 134,
    slug: "vek-filma-u-srbiji",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1996,
    title: translate("long_134_title"),
    description: translate("long_134_description"),
    genre: translate("long_134_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Petar Volk"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Radiša Vićentijević"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Đokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/vek_filma_u_srbiji/poster.jpg"),
    thumbnail: urlForMedia("movies/vek_filma_u_srbiji/thumbnail.jpg"),
    image_cover: urlForMedia("movies/vek_filma_u_srbiji/posteer.jpg"),
    image_description: urlForMedia("movies/vek_filma_u_srbiji/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/vek_filma_u_srbiji/posteer.jpg")
      }
    ]
  },
  {
    id: "long_135",
    order: 135,
    slug: "sto-godina-fudbala-u-srbiji",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1996,
    title: translate("long_135_title"),
    description: translate("long_135_description"),
    genre: translate("long_135_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Miomir Stamenković"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Radomir Putnik"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Korač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Jelena Đokić"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Petar Baničrvić"
      }
    ],
    director: "Miomir Stamenković",
    image: urlForMedia("movies/vek_fudbala_u_srbiji/poster.jpg"),
    thumbnail: urlForMedia("movies/vek_fudbala_u_srbiji/thumbnail.jpg"),
    image_cover: urlForMedia("movies/vek_fudbala_u_srbiji/posterr.jpg"),
    image_description: urlForMedia("movies/vek_fudbala_u_srbiji/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/vek_fudbala_u_srbiji/posterr.jpg")
      }
    ]
  },
  {
    id: "long_136",
    order: 136,
    slug: "mosa-marjanovic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_136_title"),
    description: translate("long_136_description"),
    genre: translate("long_136_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/mosa_marjanovic/poster.jpg"),
    thumbnail: urlForMedia("movies/mosa_marjanovic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/mosa_marjanovic/thumbnail.jpg"),
    image_description: urlForMedia("movies/mosa_marjanovic/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/mosa_marjanovic/postter.jpg")
      }
    ]
  },
  {
    id: "long_137",
    order: 137,
    slug: "stjepan-bobek",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_137_title"),
    description: translate("long_137_description"),
    genre: translate("long_137_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/stjepan_bobek/poster.jpg"),
    thumbnail: urlForMedia("movies/stjepan_bobek/thumbnail.jpg"),
    image_cover: urlForMedia("movies/stjepan_bobek/thumbnail.jpg"),
    image_description: urlForMedia("movies/stjepan_bobek/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/stjepan_bobek/postter.jpg")
      }
    ]
  },
  {
    id: "long_138",
    order: 138,
    slug: "rajko-mitic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 1999,
    title: translate("long_138_title"),
    description: translate("long_138_description"),
    genre: translate("long_138_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Rade Kovač"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Andolšek"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/rajko_mitic/poster.jpg"),
    thumbnail: urlForMedia("movies/rajko_mitic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/rajko_mitic/thumbnail.jpg"),
    image_description: urlForMedia("movies/rajko_mitic/postr.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/rajko_mitic/postr.jpg")
      }
    ]
  },
  {
    id: "long_139",
    order: 139,
    slug: "radivoj-korac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_139_title"),
    description: translate("long_139_description"),
    genre: translate("long_139_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director_plural"),
        type: "role_photo_director",
        members: "Zoran  Jovanović,"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Panov"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Žarko  Obračević"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/radivoje_korac/poster.jpg"),
    thumbnail: urlForMedia("movies/radivoje_korac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/radivoje_korac/thumbnail.jpg"),
    image_description: urlForMedia("movies/radivoje_korac/postter.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/radivoje_korac/postter.jpg")
      }
    ]
  },
  {
    id: "long_140",
    order: 140,
    slug: "dragan-dzajic",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2001,
    title: translate("long_140_title"),
    description: translate("long_140_description"),
    genre: translate("long_140_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran  Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Panov"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/dragan_dzajic/poster.jpg"),
    thumbnail: urlForMedia("movies/dragan_dzajic/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dragan_dzajic/thumbnail.jpg"),
    image_description: urlForMedia("movies/dragan_dzajic/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dragan_dzajic/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_141",
    order: 141,
    slug: "dragoslav-sekularac",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_141_title"),
    description: translate("long_141_description"),
    genre: translate("long_141_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Zoran Jovanović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Aleksandar Panov"
      },
      {
        role: translate("role_narator"),
        type: "role_narator",
        members: "Dušan Marković"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/dragoslav_sekularac/poster.jpg"),
    thumbnail: urlForMedia("movies/dragoslav_sekularac/thumbnail.jpg"),
    image_cover: urlForMedia("movies/dragoslav_sekularac/thumbnail.jpg"),
    image_description: urlForMedia("movies/dragoslav_sekularac/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/dragoslav_sekularac/poster-old.jpg")
      }
    ]
  },
  {
    id: "long_142",
    order: 142,
    slug: "55-godina-k-k-crvene-zvezde",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_142_title"),
    description: translate("long_142_description"),
    genre: translate("long_142_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Rade Šoškić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/55_godina_crvene_zvezde/poster.jpg"),
    thumbnail: urlForMedia("movies/55_godina_crvene_zvezde/thumbnail.jpg"),
    image_cover: urlForMedia("movies/55_godina_crvene_zvezde/thumbnail.jpg"),
    image_description: urlForMedia("movies/55_godina_crvene_zvezde/poster.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/55_godina_crvene_zvezde/posster.jpg")
      }
    ]
  },
  {
    id: "long_143",
    order: 143,
    slug: "12-zlatnih-medalja",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_143_title"),
    description: translate("long_143_description"),
    genre: translate("long_143_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Dragana Vićanović"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Vlada Stanković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      }
    ],
    director: "Dragana Vićanović",
    image: urlForMedia("movies/12_zlatnih_medalja/poster.jpg"),
    thumbnail: urlForMedia("movies/12_zlatnih_medalja/thumbnail.jpg"),
    image_cover: urlForMedia("movies/12_zlatnih_medalja/posterr.jpg"),
    image_description: urlForMedia("movies/12_zlatnih_medalja/thumbnail.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/12_zlatnih_medalja/posterr.jpg")
      }
    ]
  },
  {
    id: "long_144",
    order: 144,
    slug: "vaterpolo-biser-jugoslovenskog-sporta",
    documentary_type: "PERSONALITIES",
    status: "IN_THEATERS",
    category: "DOCUMENTARY",
    year: 2000,
    title: translate("long_144_title"),
    description: translate("long_144_description"),
    genre: translate("long_144_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_screenwriter"),
        type: "role_screenwriter",
        members: "Zdravko Randić"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Centar Film"
      }
    ],
    director: "Zdravko Randić",
    image: urlForMedia("movies/vaterpolo/poster.jpg"),
    thumbnail: urlForMedia("movies/vaterpolo/thumbnail.jpg"),
    image_cover: urlForMedia("movies/vaterpolo/thumbnail.jpg"),
    image_description: urlForMedia("movies/vaterpolo/posteyr.jpg"),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/vaterpolo/posteyr.jpg")
      }
    ]
  },
  {
    id: "long_145",
    order: 145,
    slug: "o-pokojniku-sve-najlepse",
    status: "IN_THEATERS",
    category: "FEATURED",
    year: 1984,
    title: translate("long_145_title"),
    description: translate("long_145_description"),
    duration: "95 min",
    country: translate("long_145_country"),
    awards: [
      {
        name: "OTHER",
        title: "GOLDEN ARENA FOR COSTUME DESIGN;Pula Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "BEST DIALOGUE;Film Screenplay Festival Vrnjacka Banja;1984"
      },
      {
        name: "OTHER",
        title: "UNESCO PRIZE;Venice International Film Festival;1984"
      },
      {
        name: "OTHER",
        title: "Delhi International Film Festival;"
      }
    ],
    genre: translate("long_145_genre"),
    people: [
      {
        role: translate("role_director"),
        type: "role_director",
        members: "Predrag Antonijević"
      },
      {
        role: translate("role_screenwriter_plural"),
        type: "role_screenwriter",
        members: "Predrag Antonijević, Dušan Perković"
      },
      {
        role: translate("role_producer"),
        type: "role_producer",
        members: "Milan Žmukić"
      },
      {
        role: translate("role_cast_plural"),
        type: "role_cast",
        members:
          "Zvonimir Lepetić, Radmila Zivković, Bora Todorović, Bogdan Diklić, Petar Kralj, Pavle Vuisić, Dragoljub Milosavljević, Sanja Veinović, Tihomir Arsić, Boro Stjepanović"
      },
      {
        role: translate("role_photo_director"),
        type: "role_photo_director",
        members: "Tomislav Pinter"
      },
      {
        role: translate("role_scenography"),
        type: "role_scenography",
        members: "Milenko Jeremić"
      },
      {
        role: translate("role_costume"),
        type: "role_costume",
        members: "Nadežda Perović"
      },
      {
        role: translate("role_editor"),
        type: "role_editor",
        members: "Boža Stanković"
      },
      {
        role: translate("role_composer"),
        type: "role_composer",
        members: "Vojislav Kostić"
      }
    ],
    director: "Predrag Antonijević",
    image: urlForMedia("movies/o_pokojniku_sve_najlepse/poster.jpg"),
    thumbnail: urlForMedia("movies/o_pokojniku_sve_najlepse/thumbnail.jpg"),
    image_cover: urlForMedia("movies/o_pokojniku_sve_najlepse/cover.jpg"),
    image_description: urlForMedia(
      "movies/o_pokojniku_sve_najlepse/sinopsis.jpg"
    ),
    locked: false,
    slideshow: [
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/pokojnik_2.jpg")
      },
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/pokojnik_4.jpg")
      },
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/sinopsis.jpg")
      },
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/pokojnik_1.jpg")
      },
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/cover.jpg")
      },
      {
        image: urlForMedia("movies/o_pokojniku_sve_najlepse/pokojnik_3.jpg")
      }
    ]
  }
];
