import React, { Fragment } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import HomePage from "./pages/HomePage";
import { theme } from "./theme";
import Footer from "./components/navigation/Footer";
import { LanguageProvider } from "./components/LanguageContext";

import { Router, Redirect, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Distribution from "./pages/Distribution";
import Publishing from "./pages/Publishing";
import Movies from "./pages/Movies";
import Directors from "./pages/Directors";
import About from "./pages/About";
import NewsAndPress from "./pages/NewsAndPress";
import Movie from "./pages/Movie";
import Page from "./components/containers/Page";
import Search from "./components/Search";
import NewsArticle from "./pages/NewsArticle";
import Contact from "./pages/Contact";
import { Navbar } from "./components/navbar/Navbar";

const ResetCss = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
     v2.0 | 20110126
     License: none (public domain)
  */
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const GlobalCss = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    // background: #C7A366;
    
    font-family: 'Contax', sans-serif;
    input,
    button {
      font-family: 'Contax', sans-serif;
    }
    
    line-height: 1.5;

    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    width: 100vw;
    max-width: 2000px;
    margin: auto;
    background: #fafbf7;
    overflow-x: hidden;
  }
  
  * {
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }
  
  #root {
    position: relative;
    // background: #C7A366;
    width: 100%;
    min-width: 100%;
    max-width: 100vh;
    display: block;
  }
  
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
  
`;

const NotFound = () => <Redirect to={"/"} noThrow />;

const FadeTransitionRouter = props => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={200}>
          <Router location={location}>{props.children}</Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

const routes = [
  {
    pageKey: "homepage",
    path: "/",
    Component: HomePage
  },
  {
    pageKey: "distribution",
    path: "/distribution",
    Component: Distribution
  },
  {
    pageKey: "movie",
    path: "/distribution/:id",
    Component: Movie
  },
  {
    pageKey: "publishing",
    path: "/publishing",
    Component: Publishing
  },
  {
    pageKey: "directors",
    path: "/directors",
    Component: Directors
  },
  {
    pageKey: "production",
    path: "/production",
    Component: Movies
  },
  {
    pageKey: "movie",
    path: "/production/:id",
    Component: Movie
  },
  {
    pageKey: "about",
    path: "/about",
    Component: About
  },
  {
    pageKey: "news",
    path: "/news",
    Component: NewsAndPress
  },
  {
    pageKey: "newsArticle",
    path: "/news/:id",
    Component: NewsArticle
  },
  {
    pageKey: "contact",
    path: "/contact",
    Component: Contact
  }
];

const App = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <Search />
      <Navbar />
      <FadeTransitionRouter>
        {routes.map(({ pageKey, path, Component }) => (
          <Page
            path={path}
            key={pageKey}
            pageKey={pageKey}
            Component={Component}
          />
        ))}
        <NotFound path="/" />
      </FadeTransitionRouter>
      <Footer id={"footer"} />
      <ResetCss />
      <GlobalCss />
    </Fragment>
  </ThemeProvider>
);

export default LanguageProvider(App);
