import React, { useState, useCallback, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import SkeletonImage from "../SkeletonImage";
import Slideshow, { Arrow } from "../Slideshow";
import get from "lodash/get";
import { useMediaQuery } from "react-responsive/src";
import responsive, { breakpoint, Desktop } from "../../utils/responsive";

const Content = styled.div`
  margin-top: 7.5em;

  padding-top: 2.875rem;

  ${responsive.sm.andSmaller`
    margin-top: 2rem;
    padding-top: 0;
  `}

  background-color: #19150e;
  .slick-list {
    margin: 2.875em 1em;
  }
`;

const Preview = styled.div`
  position: relative;
  justify-content: center;
  background-color: #19150e;
  margin: 0;
  width: 100%;
  max-height: 100vh;
  img {
    margin: auto;
    max-width: 100%;
  }

  ::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(100% / 1.5);
  }

  ::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }

  ${responsive.md.andSmaller`
    font-size: 0.7em;
  `};
`;

const ItemWrapper = styled.div`
  padding: 0 0.65625rem;
  cursor: pointer;
  :hover {
    transform: scale(1.02);
    transition: all 0.1s ease;
  }
  width: ${props => props.width || "16.875rem"};
  > * {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  }
`;

const Item = ({ image, title, thumbnail, onClick, isMobile, width }) => (
  <ItemWrapper width={width}>
    <SkeletonImage
      onClick={onClick}
      src={thumbnail || image}
      alt={title}
      height={"9.8125rem"}
      width={isMobile ? undefined : "16.875rem"}
    />
  </ItemWrapper>
);

const Absolute = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  z-index: 5;
  ${props =>
    props.left &&
    css`
      left: 0;
      padding-left: 2rem;
      right: auto;
      ${responsive.sm.andSmaller`
        padding-left: 1rem;
  `}
    `}
  ${props =>
    props.right &&
    css`
      left: auto;
      right: 0;
      padding-right: 2rem;
      ${responsive.sm.andSmaller`
        padding-right: 1rem;
      `}
    `}
  filter: brightness(120%);
  :hover {
    filter: brightness(100%);
  }
  :active {
    filter: brightness(90%);
  }
  transition: all 0.1s ease;

  ${responsive.sm.andSmaller`
    img {
      width: 1.1rem;
      min-width: 1.1rem !important;
    }
  `}
`;

export default ({ items }) => {
  const previewRef = useRef(null);
  const [currentIdx, setCurrentIdx] = useState(0);

  const onSelect = idx => {
    if (items[idx]) {
      setCurrentIdx(idx);

      if (previewRef && previewRef.current) {
        previewRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const current = useMemo(() => items[currentIdx], [items, currentIdx]);

  const next = useCallback(
    () => setCurrentIdx((currentIdx + 1) % items.length),
    [items, currentIdx, setCurrentIdx]
  );
  const prev = useCallback(
    () => setCurrentIdx(currentIdx - 1 < 0 ? items.length - 1 : currentIdx - 1),
    [items, currentIdx, setCurrentIdx]
  );

  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  const showNavigation = items.length > 3;

  return (
    <Content isMobile={isMobile}>
      <Preview ref={previewRef}>
        {showNavigation && (
          <Absolute left onClick={prev}>
            <Arrow dir={"prev"} />
          </Absolute>
        )}
        <SkeletonImage
          src={get(current, "image")}
          alt={get(current, "title")}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            objectFit: "contain"
          }}
        />
        {showNavigation && (
          <Absolute right onClick={next}>
            <Arrow dir={"next"} />
          </Absolute>
        )}
      </Preview>
      <Desktop>
        <Slideshow
          items={items}
          ItemComponent={Item}
          itemWidth={292}
          onClick={onSelect}
          style={{ padding: 0, margin: "0 0.5rem" }}
          mode={"centered"}
        />
      </Desktop>
    </Content>
  );
};
