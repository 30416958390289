import React from "react";
import styled, { css } from "styled-components";
import { H4 } from "./typography";
import emblemIcon from "./../assets/internal/emblem.svg";
import responsive from "../utils/responsive";
import cfSince from "./../assets/internal/56_logo.png";
import cfSinceEng from "./../assets/internal/56_logo_eng.png";
import { LANGUAGE, LanguageContext } from "./LanguageContext";

const Title = styled(H4)`
  text-align: start;
  text-transform: ${props => (props.noUpper ? "none" : "uppercase")};
  font-weight: 700;

  margin-bottom: 0;

  min-width: ${props => props.minWidthLg || "15rem"};
  width: max-content;
  border-top: 2px solid ${props => props.theme.text.default.string()};
  padding-left: 10rem;
  text-align: right;

  line-height: 3.7rem;

  // center line
  margin: 4.4375rem 0 1.8rem 0;

  display: flex;
  flex-direction: column;

  ${responsive.md.andSmaller`
    padding-left: 0;
    border-width: 1px;
    font-weight: 400;
    min-width: ${props => props.minWidth || "10rem"};
    line-height: 2rem;
    margin: 3.4375rem 0 1.8rem 0;
  `}

  ${props =>
    props.withSubtitle &&
    css`
      text-align: left;
      ${responsive.md.andSmaller`
      padding-left: 3rem;
    `}
    `}
`;

const Decoration = styled.img`
  position: absolute;
  right: 0;
  z-index: 0;
  opacity: 0.3;
  transform: translateY(-50%) translateX(50%);
  height: 14rem;
`;

const CFSince = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  /*width: 100%;*/
  transform: translateX(3%) translateY(-50%);
  max-height: calc(100vw * 0.05);

  ${responsive.md.andSmaller`
    width: 7em;
    left: auto;
    right: 1em;
    transform: none;
    margin-right: 1em;
    max-height: calc(100vw * 0.07);
  `}
`;

export default ({
  title,
  subtitle,
  noUpper,
  emblem,
  cfLogo,
  minWidth,
  minWidthLg
}) => (
  <Title
    noUpper={noUpper}
    withSubtitle={!!subtitle}
    minWidth={minWidth}
    minWidthLg={minWidthLg}
  >
    {title}
    <span style={{ textTransform: "none", textAlign: "left", lineHeight: 1 }}>
      {subtitle && `\n${subtitle}`}
    </span>
    {emblem && <Decoration src={emblemIcon} style={{ position: "absolute" }} />}
    <LanguageContext.Consumer>
      {({ language }) =>
        cfLogo && (
          <CFSince src={language === LANGUAGE.SR ? cfSince : cfSinceEng} />
        )
      }
    </LanguageContext.Consumer>
  </Title>
);
