import isFinite from "lodash/isFinite";

export const isPlural = arr => arr && arr.split(",").length > 1;

export const makeYearRange = (start, end) => {
  if (isFinite(start) && isFinite(end)) {
    return `${start} - ${end}`;
  } else if (isFinite(start)) {
    return start;
  }
};
