import React, { Fragment, useState, useMemo } from "react";
import styled, { css } from "styled-components";
import Paper from "../components/containers/Paper";
import PosterItem from "../components/items/PosterItem";
import { SHOW_STATUS, SHOW_STATUSES, SHOW_TYPES } from "../enums";
import { HorizontalContainer, VerticalContainer } from "../components/Layout";
import Button, { ButtonLink } from "../components/Button";
import ItemGrid from "../components/containers/ItemGrid";
import DistributionArchiveItemLarge from "../components/items/DistributionArchiveItemLarge";
import carret from "../assets/internal/carret.svg";
import Icon from "../components/Icon";
import responsive, { Desktop, Mobile } from "../utils/responsive";
import DistributionArchiveItemMobile from "../components/items/DistributionArchiveItemMobile";
import find from "lodash/find";

export const Group = styled.div`
  display: flex;
  align-items: center;
  *:not(:first-child) {
    border-left: none;
  }

  ${props =>
    props.separator &&
    css`
      * {
        padding: 0.5em 1em;
        font-weight: 800;
      }
      *:not(:first-child) {
        border-left: 1px solid ${props => props.theme.text.default.string()};
      }
    `};

  button {
    width: 9rem;
  }

  ${responsive.sm.andSmaller`
    a {
      font-size: 0.625rem;
    }
    button {
      font-size: 0.625rem;
      width: 6.5rem;
    }
  `}
`;

const ArchiveContainer = styled.div`
  background: white;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  border-top: 3px solid ${props => props.theme.text.default.string()};
  padding-bottom: 1.875rem;
  max-width: 70rem;
  margin: auto;
  > div {
    > :first-child {
      border-top: none;
    }
  }
`;

const FilterContainer = styled(VerticalContainer)`
  padding: 0;
  margin-top: 6rem;
  ${responsive.sm.andSmaller`
    margin-top: 3rem;
  `}
`;

const FilterGroup = styled(HorizontalContainer)`
  padding: 0 9.125rem;

  ${responsive.md.andSmaller`
    padding: 0 2.625rem;
  `}

  ${responsive.sm.andSmaller`
    padding: 0 2rem;
  `}
`;

export const optionalFilterBy = (items, key, value) =>
  value ? items.filter(item => item[key] === value) : items;

const Filter = ({ items, translate, children }) => {
  const [currentStatus, setStatus] = useState();
  const [currentType, setType] = useState();
  const filtered = optionalFilterBy(
    optionalFilterBy(items, "status", currentStatus),
    "type",
    currentType
  );
  const showStatusFilter = useMemo(
    () => find(items, item => item["status"] === SHOW_STATUS.COMING_SOON),
    [items]
  );
  return (
    <FilterContainer>
      <FilterGroup>
        <Group separator={false}>
          {showStatusFilter &&
            SHOW_STATUSES.map(status => (
              <Button
                flavor={status === currentStatus ? "primary" : "secondary"}
                onClick={() =>
                  status === currentStatus
                    ? setStatus(undefined)
                    : setStatus(status)
                }
                key={status}
              >
                {translate(`SHOW_STATUS.${status}`)}
              </Button>
            ))}
        </Group>
        <Group separator={true}>
          {SHOW_TYPES.map(type => (
            <ButtonLink
              color={type === currentType ? "navColor" : "secondaryColor"}
              onClick={() =>
                type === currentType ? setType(undefined) : setType(type)
              }
              key={type}
            >
              {translate(`SHOW_TYPE.${type}`)}
            </ButtonLink>
          ))}
        </Group>
      </FilterGroup>
      {children(filtered)}
    </FilterContainer>
  );
};

const InteractiveWrapper = styled.span`
  display: flex;
  width: 100%;
  :hover {
    opacity: 0.6;
  }

  transition: all 0.1s ease;
`;

const MoreButton = props => (
  <InteractiveWrapper>
    <Icon {...props} style={{ margin: "auto" }}>
      {carret}
    </Icon>
  </InteractiveWrapper>
);

export default ({ production, archive, translate, isDesktop, isMobile }) => (
  <Fragment>
    <Paper noMargin>
      <Filter items={production.items} translate={translate}>
        {filtered => (
          <ItemGrid
            style={{ marginTop: isDesktop ? "5.25rem" : "3rem" }}
            initialRows={2}
            items={filtered}
            Component={PosterItem}
            linkProvider={production.linkProvider}
            showAll={true}
            itemWidth={isMobile ? 40 : 243}
          />
        )}
      </Filter>
    </Paper>
    <Paper title={archive.title}>
      <Desktop>
        <ArchiveContainer>
          <ItemGrid
            style={{ gridRowGap: 0 }}
            initialRows={4}
            perRowForce={1}
            items={archive.items}
            Component={DistributionArchiveItemLarge}
            linkProvider={archive.linkProvider}
            MoreButtonComponent={MoreButton}
          />
        </ArchiveContainer>
      </Desktop>
      <Mobile>
        <ItemGrid
          initialRows={2}
          perRowForce={1}
          itemWidth={348}
          items={archive.items}
          Component={DistributionArchiveItemMobile}
          linkProvider={archive.linkProvider}
        />
      </Mobile>
    </Paper>
  </Fragment>
);
