import React from "react";
import ContentService from "../content/ContentService";
import english from "../assets/internal/en_icon.svg";
import serbian from "../assets/internal/sr_icon.svg";

export const LANGUAGE = {
  EN: "EN",
  SR: "SR"
};

export const LANGUAGE_LABELS = {
  [LANGUAGE.SR]: "Srpski",
  [LANGUAGE.EN]: "English"
};

export const LANGUAGE_MARK = {
  [LANGUAGE.SR]: "SR",
  [LANGUAGE.EN]: "EN"
};

export const LANGUAGE_ICONS = {
  [LANGUAGE.SR]: serbian,
  [LANGUAGE.EN]: english
};

export const languageOptions = [
  {
    lang: LANGUAGE.SR,
    label: LANGUAGE_LABELS[LANGUAGE.SR],
    icon: LANGUAGE_ICONS[LANGUAGE.SR]
  },
  {
    lang: LANGUAGE.EN,
    label: LANGUAGE_LABELS[LANGUAGE.EN],
    icon: LANGUAGE_ICONS[LANGUAGE.EN]
  }
];

export const LanguageContext = React.createContext({
  language: LANGUAGE.SR,
  changeLanguage: () => {}
});

export const LanguageProvider = Component => {
  return class LanguageProvider extends React.Component {
    constructor(props) {
      super(props);
      this.state = { language: this.loadLanguage() };
    }

    changeLanguage = lang => {
      this.persistLanguage(lang);
      this.setState({ language: lang });
    };

    loadLanguage = () => {
      const lang = localStorage.getItem("lang");
      if (lang in LANGUAGE) {
        return lang;
      } else {
        return LANGUAGE.SR;
      }
    };

    persistLanguage = lang => {
      localStorage.setItem("lang", lang);
    };

    render() {
      return (
        <LanguageContext.Provider
          value={{
            language: this.state.language,
            changeLanguage: this.changeLanguage,
            contentService: ContentService(this.state.language)
          }}
        >
          <Component {...this.props} />
        </LanguageContext.Provider>
      );
    }
  };
};

export const withLanguage = Component => props => (
  <LanguageContext.Consumer>
    {language => <Component {...props} {...language} />}
  </LanguageContext.Consumer>
);
