import styled, { css } from "styled-components";
import responsive from "../utils/responsive";
import { PlainLink } from "./navigation/NavLink";

const Button = styled.button`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-weight: 500;

  min-height: 3em;
  min-width: 4.8125em;
  height: auto;

  letter-spacing: 0.078125px;

  justify-content: center;
  line-height: normal;

  outline: none;
  border: none;

  text-transform: uppercase;
  user-select: none;

  background-color: ${props =>
    props.theme.button[props.flavor || "primary"].background.string()};
  color: ${props =>
    props.theme.button[props.flavor || "primary"].color.string()};
  border: 1px solid
    ${props =>
      props.theme.button[props.flavor || "primary"].borderColor.string()};

  font-size: ${props => props.theme.textSize.s};

  :hover {
    filter: brightness(95%);
    cursor: pointer;
  }

  :active {
    filter: brightness(90%);
  }

  :disabled {
    filter: brightness(80%);
  }

  transition: all 0.1s ease;


  ${responsive.sm.andSmaller`
      padding: 1em 1em;
      font-size: ${props => props.theme.textSize.xs};
      font-weight: 400;
  `}

  ${props =>
    props.lg &&
    css`
      padding: 1.5em 4em;
      font-size: ${props => props.theme.textSize.m};
      font-weight: 600;

      ${responsive.sm.andSmaller`
      padding: 1em 3em;
        font-size: ${props => props.theme.textSize.s};
      `}
    `}

  ${props =>
    props.md &&
    css`
      padding: 1em 3em;
      font-size: ${props => props.theme.textSize.m};
      font-weight: 500;

      ${responsive.sm.andSmaller`
        padding: 0.5em 2em;
          font-size: ${props => props.theme.textSize.xs};
        `}
    `}
    
`;

export const ButtonLink = styled(Button)``.withComponent(PlainLink);

export default Button;
