import React, { useMemo, useState } from "react";
import Paper from "./containers/Paper";
import Abstract from "./Abstract";
import styled from "styled-components";
import ArchiveItem from "./items/ArchiveItem";
import { MoreButton } from "./containers/ItemGrid";
import floatImage from "../assets/images/ko-to-tamo-scenario-3.png";
import Float from "./Float";
import responsive, { Desktop } from "../utils/responsive";

const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  > * + * {
    // padding: 4rem 0.85rem;
    border-top: 2px solid ${props => props.theme.text.default.string()};
    ${responsive.sm.andSmaller`
      border-width: 1px;
    `}
  }
`;

const ArchiveSection = ({ archive, contentService, isMobile }) => {
  const offset = isMobile ? 3 : 5;
  const [rows, setRows] = useState(offset);
  const paged = useMemo(() => archive.items.slice(0, rows), [
    archive.items,
    rows
  ]);

  const showMore = () => {
    setRows(rows + offset);
  };

  const hasMore = useMemo(() => paged.length < archive.items.length, [
    archive.items,
    paged
  ]);

  return (
    <Paper title={archive.title} id={"short"} minWidth={"14em"}>
      <Desktop>
        <Float src={floatImage} right />
      </Desktop>
      {archive.abstract && <Abstract>{archive.abstract}</Abstract>}
      <Items isMobile={true}>
        {paged.map((item, i) => (
          <ArchiveItem {...item} isMobile={isMobile} key={i} />
        ))}
      </Items>
      {hasMore && (
        <MoreButton onClick={showMore}>
          {contentService.translate("actions.show_more")}
        </MoreButton>
      )}
    </Paper>
  );
};

export default ArchiveSection;
