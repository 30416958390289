import React from "react";
import styled, { css } from "styled-components";
import { Italic, Label, Paragraph } from "./typography";
import responsive, { Desktop } from "../utils/responsive";
import { CenteredContainer } from "./Layout";
import quotes from "../assets/internal/quotes.svg";
import Icon from "./Icon";

const Container = styled(CenteredContainer)`
  position: relative;
  background-color: ${props => props.theme.background.default.string()};
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;

  white-space: pre-line;
  height: 21rem;
  
  ${responsive.sm.andSmaller`
    // max-height: 12.625rem;
    min-height: 10rem;
    height: auto;
  `}

  p {
    max-width: 40rem;
    font-size: 1.1rem;  
    line-height: ${props => props.theme.lineHeight.xl};
    color: ${props => props.theme.background.contrast.string()};
    margin-bottom: 2em;
    text-align: justify;

    ${responsive.sm.andSmaller`
      font-size: ${props => props.theme.textSize.xs};
      line-height: ${props => props.theme.lineHeight.xs};
      max-width: 15.625rem;
    `};
  }

  label {
    width: 100%;
    font-size: ${props => props.theme.textSize[props.size || "ml"]};
    ${responsive.sm.andSmaller`
      font-size: ${props => props.theme.textSize.xs};
    `};

    text-align: center;
    :before {
      display: block;
      margin: auto;
      content: "";
      height: 1px;
      width: 7em;
      background: ${props => props.theme.text.default.string()};
      margin-bottom: 1em;
    }
  }

 
  ${responsive.sm.andSmaller`
    padding: 1rem 2rem;
  `};
  
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20%;
    margin: auto;
    opacity: 0.15;
    height 50%;
  }
`;
const angleTop = "12deg";
const angleBottom = "18deg";

const Line = styled.div`
  border-top: 2px solid ${props => props.theme.text.default.string()};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 24%;
  opacity: 0.15;
  
  ${props =>
    props.topLeft &&
    css`
      transform-origin: 0% 0%;
      transform: rotateZ(${angleTop});
    `}
  
  ${props =>
    props.topRight &&
    css`
      transform-origin: 100% 0%;
      transform: rotateZ(-${angleTop});
      left: auto;
    `}
  
  ${props =>
    props.bottomLeft &&
    css`
      transform-origin: 0% 0%;
      transform: rotateZ(-${angleBottom});
      top: auto;
    `}
  
  ${props =>
    props.bottomRight &&
    css`
      transform-origin: 100% 0%;
      transform: rotateZ(${angleBottom});
      left: auto;
      top: auto;
    `}
`;

const Quote = ({ text, author, link, style, className }) => (
  <Container style={style} className={className}>
    <Icon>{quotes}</Icon>
    <Paragraph style={{ fontStyle: "italic" }}>{text}</Paragraph>
    <div
      style={{
        display: "flex",
        justifyContent: link ? "space-between" : "center",
        alignItems: "end",
        width: "100%",
        flexWrap: "wrap"
      }}
    >
      {author && (
        <Label>
          <Italic style={{ fontStyle: "normal" }}>{author}</Italic>
        </Label>
      )}
    </div>
    <Desktop>
      <Line topLeft />
      <Line topRight />
      <Line bottomLeft />
      <Line bottomRight />
    </Desktop>
  </Container>
);

export default Quote;
