import TranslationService from "./TranslationService";
import pages from "./structure/pages";
import search from "./structure/search";

const ContentService = language => {
  const translationService = new TranslationService(language);

  return {
    page: (key, args) => {
      const page = pages(translationService.translate)[key];
      if (typeof page === "function") {
        return page(args);
      } else {
        return page;
      }
    },
    search: search(translationService.translate),
    navigation: [
      { to: "/about", label: translationService.translate("navigation.about") },
      {
        to: "/production",
        label: translationService.translate("navigation.movies")
      },
      {
        to: "/distribution",
        label: translationService.translate("navigation.distribution")
      },
      {
        to: "/directors",
        label: translationService.translate("navigation.directors")
      },
      {
        to: "/publishing",
        label: translationService.translate("navigation.publishing")
      },
      {
        to: "/news",
        label: translationService.translate("navigation.news"),
        disabled: true
      },
      {
        to: "/contact",
        label: translationService.translate("navigation.contact")
      }
    ],
    translate: translationService.translate
  };
};

export default ContentService;
