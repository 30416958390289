import React from "react";
import styled from "styled-components";
import { H3 } from "../typography";
import Button from "../Button";
import { CenteredContainer } from "../Layout";
import responsive, { Desktop } from "../../utils/responsive";

const Container = styled(CenteredContainer)`
  position: relative;
  height: 22rem;
  display: flex;

  ${responsive.md.andSmaller`
    height: 11.625rem;
  `}
  
  background-color: ${props =>
    props.light
      ? props.theme.background.contrast.alpha(0.7).string()
      : props.theme.background.contrast.string()};
  
  padding: 2em 1em;
  h3 {
    margin-bottom: 0;
    z-index: 2;
  }
  z-index: 1;
`;
const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
  filter: grayscale(1);
`;

const Separator = styled.span`
  max-width: 4em;
  width: 30%;
  height: 1px;
  background: ${props => props.theme.text.default.string()};
  margin-top: 2em;
  z-index: 2;
`;

export const DirectorItem = ({ name, image, action }) => (
  <Container light={image}>
    <H3>{name}</H3>
    <Desktop>
      <Separator />
    </Desktop>
    {action && (
      <Button flavor={"default"} md>
        MORE INFO
      </Button>
    )}
    <Cover src={image ? encodeURI(image) : undefined} />
  </Container>
);
