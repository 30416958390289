import { urlForMedia } from "../../utils/path";
export default translate => [
  {
    image: urlForMedia("about/gallery/about_gallery_8.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_4.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_6.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_2.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_7.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_9.jpg")
  },
  {
    image: urlForMedia("about/gallery/about_gallery_1.jpg")
  }
];
