import styled from "styled-components";
import responsive from "../../utils/responsive";

export const Container = styled.div`
  width: ${props => props.width};
  min-height: ${props => props.height};
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
`;

export const Caption = styled.label`
  font-size: ${props =>
    props.theme.textSize[props.size || "m"] || props.theme.textSize.m};
  white-space: normal;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
  color: ${props => props.theme.text.default.string()};
  text-align: center;
  ${responsive.sm.andSmaller`
    font-size: 0.625rem;
  `}
`;
