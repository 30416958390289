import React from "react";
import styled from "styled-components";
import { LanguageContext } from "../LanguageContext";
import { defaultTo } from "lodash";
import { useMediaQuery } from "react-responsive/src";
import responsive, { breakpoint } from "../../utils/responsive";
import { Caption, Container } from "./shared";
import SkeletonImage from "../SkeletonImage";
import BookmarkLink from "../navigation/BookmarkLink";
import useFocusElement from "../../utils/useFocusElement";

const Status = styled.div`
  background: ${props => props.theme.background.primary.string()};
  color: rgba(255, 255, 255, 0.9);
  height: 3em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  margin: auto;
  ${responsive.xs.andSmaller`
    font-size: 0.8rem;
  `}
`;

export default ({
  width,
  height,
  image,
  title,
  status,
  banner,
  linkProvider,
  id,
  slug
}) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  const w = defaultTo(width, isMobile ? "6.5rem" : "15.1875rem");
  const h = defaultTo(height, isMobile ? "9.1875rem" : "21.375rem");
  const posterHeight = defaultTo(h, isMobile ? "9.1875rem" : "21.375rem");

  const ref = useFocusElement(slug);

  return (
    <LanguageContext.Consumer>
      {({ contentService: { translate } }) => (
        <Container
          id={slug || id}
          width={defaultTo(w, isMobile ? "6.5rem" : "15.1875rem")}
          frame
          style={{ padding: 0 }}
          ref={ref}
        >
          <div style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)" }}>
            <BookmarkLink
              bookmark={slug || id}
              to={linkProvider ? linkProvider(slug || id) : "/"}
              style={{ margin: "0 auto", zIndex: 1 }}
            >
              <SkeletonImage
                src={image}
                alt={title}
                height={banner ? posterHeight : `calc(${posterHeight} + 3em)`}
              />
            </BookmarkLink>
            {banner && (
              <Status
                style={{
                  width: `calc(${w})`,
                  top: `calc(${h} - 3em )`
                }}
              >
                {banner}
              </Status>
            )}
          </div>
          <Caption style={{ marginTop: "1em" }}>{title}</Caption>
        </Container>
      )}
    </LanguageContext.Consumer>
  );
};
