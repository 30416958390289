import { LANGUAGE } from "../components/LanguageContext";
import get from "lodash/get";

class TranslationService {
  translations = {
    [LANGUAGE.EN]: {
      ...require("./i18n/en/messages.json"),
      ...require("./i18n/en/publishing.json"),
      ...require("./i18n/en/short_movies.json"),
      ...require("./i18n/en/about_page.json"),
      ...require("./i18n/en/long_movies.json"),
      ...require("./i18n/en/news_press.json"),
      ...require("./i18n/en/distribution.json"),
      ...require("./i18n/en/production.json")
    },
    [LANGUAGE.SR]: {
      ...require("./i18n/sr/messages.json"),
      ...require("./i18n/sr/publishing.json"),
      ...require("./i18n/sr/long_movies.json"),
      ...require("./i18n/sr/about_page.json"),
      ...require("./i18n/sr/short_movies.json"),
      ...require("./i18n/sr/news_press.json"),
      ...require("./i18n/sr/distribution.json"),
      ...require("./i18n/sr/production.json")
    }
  };

  constructor(lang) {
    if (lang in LANGUAGE) {
      this.lang = lang;
    } else {
      this.lang = LANGUAGE.EN;
    }

    this.messages = this.translations[this.lang];
  }

  translate = key => {
    const translation = get(this.messages, key);
    return translation !== null && translation !== undefined
      ? translation
      : key;
  };
}

export default TranslationService;
