import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import responsive from "../utils/responsive";
import SkeletonImage from "./SkeletonImage";

const Container = styled.div`
  background: #25231f;
  position: relative;
  
  height: 100vh;
  width: 100%;
  max-height: 67.5rem;
  z-index: 1;
  overflow: hidden;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  ${responsive.lg.andSmaller`
    height: 36.5rem;
    min-height: 0;
  `}

  ${responsive.md.andSmaller`
    height: 25.5rem;
    min-height: 0;
  `}

  ${responsive.xs.andSmaller`
    height: 14.5rem;
    min-height: 0;
  `}

`;

const Cover = styled(SkeletonImage)`
  z-index: 10;
  opacity: 1;

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
      transition: opacity 0.1s ease;
    `};
`;

const Video = styled.video`
  display: block;
  width: 100%;
  height: 100vh;
  max-height: 67.5rem;
  
  object-fit: cover;
  object-position: center bottom;

  ${responsive.lg.andSmaller`
    height: 36.5rem;
    min-height: 0;
  `}
  
  ${responsive.md.andSmaller`
    height: 25.5rem;
    min-height: 0;
  `}
  
  ${responsive.xs.andSmaller`
    height: 14.5rem;
    min-height: 0;
  `}


  z-index: 9;

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
    `};
`;

const FadeInVideo = ({ video, cover, poster }) => {
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef();

  const onCanPlay = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
      setLoaded(true);
    }
  };

  useEffect(() => {
    const current = videoRef ? videoRef.current : undefined;
    if (current) {
      current.addEventListener("canplay", onCanPlay, true);
    }
    return () => {
      if (current) {
        current.removeEventListener("canplay", onCanPlay);
      }
    };
  }, [setLoaded, videoRef]);

  return (
    <Container>
      <Cover
        src={cover}
        fullscreen={true}
        color={"#25231F"}
        hidden={loaded}
        animate={false}
      />
      <Video
        loop
        muted
        preload="auto"
        hidden={!loaded}
        ref={videoRef}
        poster={poster}
        src={video}
      />
    </Container>
  );
};

export default FadeInVideo;
