import React, { Fragment } from "react";
import styled from "styled-components";
import Icon, { FacebookIcon, InstagramIcon, YouTubeIcon } from "../Icon";
import { Paragraph } from "../typography";
import NavLink from "./NavLink";
import responsive, { breakpoint } from "../../utils/responsive";
import { HorizontalContainer, VerticalContainer } from "../Layout";
import { withLanguage } from "../LanguageContext";
import chunk from "lodash/chunk";
import { theme } from "../../theme";
import Link from "./NavLink";
import logo_small from "../../assets/internal/logo.png";
import { useMediaQuery } from "react-responsive/src";
import config from "../../config";
import { urlForMedia } from "../../utils/path";

const Container = styled.div`
  background-image: url(${urlForMedia("footer.jpg")});
  background-blend-mode: multiply;
  background-size: cover;
  background-position: 5% center;
  
  // background-color: ${props => props.theme.text.vibrant.string()};
  background-color: rgb(83, 81, 77);

  color: ${props => props.theme.text.vibrant.string()};
  border-top: 6px solid ${props => props.theme.text.vibrant.string()};
  margin-top: auto;
  margin-bottom: 0;

  font-weight: 800;
  p {
    line-height: ${props => props.theme.lineHeight.md};
    margin: ${props => props.theme.lineHeight.m} auto;
    grid-area: contact;
  }

  padding: 14em 5em;
  display: grid;
  grid-template-areas: "logo menu contact social";
  grid-template-columns: 1fr 2fr 1fr 1fr;
  align-content: flex-start;
  justify-items: center;

  ${responsive.lg.andSmaller`
    padding: 2.625rem 1em;
  `};

  ${responsive.sm.andSmaller`
    background-position: top left;
    padding: 2.625rem 1em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "logo logo logo"
                         "menu menu contact" 
                         "social social social";
    
    a {
      font-size: 0.75rem;
    }                        
  `};
`;

const Social = styled.div`
  display: flex;
  grid-area: social;
  font-size: 1.3em;
  * + * {
    margin-left: 1em;
  }
  padding: 0;
  padding-top: 2em;
  align-items: center;
  ${responsive.sm.andSmaller`
    padding-top: 0;
    svg {
      height: 1.125rem;
    }
  `}
`;

const Menu = styled(HorizontalContainer)`
  justify-content: space-evenly;
  grid-area: menu;
  flex: 1 0 auto;
  * {
    color: ${props => props.theme.text.vibrant.string()};
  }
  margin: ${props => props.theme.lineHeight.m} auto;
  width: 100%;

  text-transform: uppercase;
  line-height: 1.8;
  ${responsive.sm.andSmaller`
    line-height: 0;
    height: 100%;
    max-height: 4.5rem;
    margin: auto;
  `}
`;

const Separator = styled.div`
  background: ${props => props.theme.text.vibrant.string()};
  height: 100%;
  width: 1px;
  min-height: 7.5rem;
  ${responsive.sm.andSmaller`
    min-height: 3.75rem;
    height: calc(100% + 1rem);
    transform: translateY(-0.5rem);
    margin: 0 0.5em;
  `}
`;

const Footer = ({ contentService: { navigation, translate } }) => {
  const columns = chunk(navigation, Math.ceil(navigation.length / 2));
  const isMobile = useMediaQuery({ maxWidth: breakpoint.md });
  return (
    <Container>
      <Link
        to={"/"}
        style={{
          gridArea: "logo",
          marginTop: isMobile ? 0 : theme.lineHeight.m
        }}
      >
        <Icon style={{ width: isMobile ? "2.6rem" : "7.75rem" }}>
          {logo_small}
        </Icon>
      </Link>
      <Menu>
        {columns.map((routes, i) => (
          <Fragment key={i}>
            <VerticalContainer style={{ padding: 0 }}>
              <Fragment>
                {routes.map(({ to, label, disabled }) => (
                  <NavLink
                    key={to}
                    to={to}
                    disabled={disabled}
                    style={disabled ? { pointerEvents: "none" } : undefined}
                  >
                    {label}
                  </NavLink>
                ))}
                {i === 1 && <span>&nbsp;</span>}
              </Fragment>
            </VerticalContainer>
            <Separator />
          </Fragment>
        ))}
      </Menu>
      <Paragraph style={{ lineHeight: isMobile ? 2 : 1.8 }}>
        Centar Film <br />
        {translate("contact.address")} <br />
        info@centarfilm.com <br /> +381 11 3231 606
      </Paragraph>
      <Social>
        <FacebookIcon href={config.facebookPage} />
        <YouTubeIcon href={config.youtubeChannel} />
        <InstagramIcon href={config.instagramProfile} />
      </Social>
    </Container>
  );
};

export default withLanguage(Footer);
