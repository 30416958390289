import React from "react";
import styled, { css } from "styled-components";
import SkeletonImage from "./../SkeletonImage";
import { Award } from "../Awards";
import { LanguageContext } from "../LanguageContext";
import { compact, find, get } from "lodash";
import { Caption, Main, Value } from "./DistributionArchiveItemLarge";

const Container = styled.div`
  display: flex;

  flex-direction: column;
  padding: 1.875rem 1.25rem;

  background-color: white;
  border-top: 3px solid ${props => props.theme.text.default.string()};
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
`;

const Awards = styled.div`
  border-top: 1px solid ${props => props.theme.text.default.string()};
  padding: 1em 0;
  margin-top: 1.375rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));

  flex-wrap: wrap;
  img {
    height: 1.2rem;
  }
  span {
    font-size: 0.6rem !important;
  }
`;

const Description = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  margin-left: 1.25em;
  font-size: 0.625em;

  justify-content: space-between;
  > * + * {
    padding-top: 1em;
  }
`;

const Item = styled.div`
  display: inline-flex;
  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `}
`;

const DistributionArchiveItemMobile = ({
  image,
  title,
  year,
  director,
  people,
  genre,
  awards,
  style
}) => (
  <LanguageContext.Consumer>
    {({ contentService: { translate } }) => (
      <Container style={{ ...style, paddingBottom: awards ? 0 : "1.875rem" }}>
        <Main>
          <SkeletonImage
            src={image}
            alt={title}
            width={"7.375em"}
            height={"10.3125em"}
          />
          <Description>
            <Caption>{compact([title, year]).join(" | ")}</Caption>
            <Item column>
              <Caption>{translate("general.genre")}:</Caption>
              <Value>&nbsp;{genre}</Value>
            </Item>
            <Item column>
              <Caption>{translate("general.director")}:</Caption>
              <Value>&nbsp;{director}</Value>
            </Item>
            <Item column>
              <Caption>{translate("general.cast")}:</Caption>
              <Value>
                {get(
                  find(people, _ => _.type === "role_cast"),
                  "members"
                )}
              </Value>
            </Item>
          </Description>
        </Main>
        {awards && (
          <Awards>
            {awards.map((award, i) => (
              <Award key={i} award={award} isMobile={true} />
            ))}
          </Awards>
        )}
      </Container>
    )}
  </LanguageContext.Consumer>
);

export default DistributionArchiveItemMobile;
