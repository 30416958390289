import React, { useState } from "react";
import styled, { css } from "styled-components";
import SkeletonImage from "../components/SkeletonImage";
import responsive, { breakpoint } from "../utils/responsive";
import Paper from "../components/containers/Paper";
import config from "./../config";
import { Frame } from "../components/items/PosterItem";
import { PlainLink } from "../components/navigation/NavLink";
import Button from "../components/Button";
import border from "../assets/internal/border.svg";
import get from "lodash/get";
import { useMediaQuery } from "react-responsive/src";

const ContactCard = styled.table`
  table-layout: fixed;
  line-height: 1.375rem;
  white-space: pre-line;
  min-width: 30%;
  font-size: 1.225rem;
  color: ${props => props.theme.text.default.string()};
  th,
  td {
    padding: 1.4em 2em;
  }

  margin: auto 0;
  margin-left: 1.3em;

  ${responsive.md.andSmaller`
    width: 100%;
    margin-bottom: 2em;
    th, td {
      padding: 0.7rem 3.25rem;
    }
    font-size: 0.8rem;
  `}

  ${responsive.xs.andSmaller`
    margin-left: 1em;
    th, td {
      padding: 0.1rem 3.8rem;
    }
  `}
`;

const Info = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
  padding-top: 0;
  // margin-top: -2.5em;

  ${responsive.md.andSmaller`
    flex-wrap: wrap;
  `}
  
  ${responsive.xl.andSmaller`
    justify-content: space-between;
  `}

  ${responsive.xs.andSmaller`
    margin-top: -2.2em;
    padding-top: 0;
  `}
`;

const gmapImage = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7label:S%7C44.8087117,20.4710692&zoom=15&size=800x385&maptype=roadmap&key=${config.googleMapsKey}`;
const gmapImageM = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7label:S%7C44.8087117,20.4710692&zoom=15&size=485x300&maptype=roadmap&key=${config.googleMapsKey}`;
const gmapUrl = `https://www.google.com/maps/place/CENTAR+FILM+d.o.o./@44.8087117,20.4710692,17z/data=!3m1!4b1!4m5!3m4!1s0x475a7aa3bc16116d:0xbad773c4e9a7cc1!8m2!3d44.8087117!4d20.4732579`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 3em;

  .span {
    grid-column: 1 / span 2;
  }

  > button {
    grid-column: auto;
    width: 15em;
    height: 4em;
    margin-top: 2em;
  }

  margin-top: 6.875rem;

  ${responsive.md.andSmaller`
    margin-top: 0;
    padding: 0 2em;
    grid-gap: 0.5em;
    > div {
      grid-column: 1 / span 2;
      grid-gar: 2em;
    }
    
    > button {
      width: unset;
      grid-column: 1 / span 2;
    }
  `}
`;

const Wrapper = styled.div``;

const Label = styled.label`
  padding-left: 0.2em;
  font-size: 1.125rem;
  font-weight: 800;
  color: ${props => props.theme.text.vibrant.string()};
  ${responsive.md.andSmaller`
    font-size: 0.8em;
  `}
`;

const withLabel = Component => ({ label, className, ...props }) => (
  <Wrapper className={className}>
    <Label>{label}</Label>
    <Component {...props} />
  </Wrapper>
);

const inputBase = css`
  background: none;
  border: none;
  border: 5px solid white;
  border-image: url(${border}) 1;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  padding: 0.2em 1em;
  color: ${props => props.theme.text.emphasized.string()};
  font-size: ${props => props.theme.textSize.l};
  line-height: ${props => props.theme.lineHeight.l};
  box-sizing: border-box;

  :focus {
    border: 5px solid transparent;
    border-image: url(${border}) 4;
    outline: none;
  }

  ::placeholder {
    color: ${props => props.theme.text.vibrant.alpha(0.5).string()};
  }

  :invalid {
    box-shadow: none;
  }

  ${responsive.md.andSmaller`
    :focus {
      border-image: url(${border}) 1;
    }
  `}
`;

const TextInputField = withLabel(styled.input`
  ${inputBase}
  height: 3.5em;
  ${responsive.md.andSmaller`
    font-size: 0.9em;
  `}
`);

const TextAreaField = withLabel(styled.textarea`
  ${inputBase}
  resize: none;
  padding-top: 1em;
  ${responsive.md.andSmaller`
    font-size: 1.1em;
  `}
`);

const encode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

const Map = ({ style, isMobile }) => (
  <Frame
    style={{
      width: "min-content",
      overflow: "hidden",
      marginLeft: "auto",
      ...style
    }}
  >
    <PlainLink href={gmapUrl} target="_blank">
      {isMobile ? (
        <SkeletonImage src={gmapImageM} alt={"map"} width={"300px"} />
      ) : (
        <SkeletonImage
          src={gmapImage}
          alt={"map"}
          width={"800px"}
          height={"385px"}
        />
      )}
    </PlainLink>
  </Frame>
);

export default ({ translate }) => {
  const [sent, setSent] = useState(false);
  const [value, setValue] = useState();
  const isMobile = useMediaQuery({ maxWidth: breakpoint.sm });

  const handleChange = e =>
    setValue({ ...value, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...value })
      });
      setSent(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper title={translate("contact.name")} noUpper fillMobile>
      <Info>
        <ContactCard>
          <tbody>
            <tr>
              <td>{translate("contact.address_line_1")}</td>
            </tr>
            <tr>
              <td>{translate("contact.address_line_2")}</td>
            </tr>
            <tr>
              <td>info@centarfilm.com</td>
            </tr>
            <tr>
              <td>+381 11 3231 606</td>
            </tr>
          </tbody>
        </ContactCard>
        <Map
          style={
            isMobile ? { margin: "auto", marginBottom: "2.875rem" } : undefined
          }
          isMobile={isMobile}
        />
      </Info>
      <Form onSubmit={onSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <TextInputField
          label={translate("contact.form.name")}
          placeholder={translate("contact.form.name")}
          name="name"
          type="text"
          value={get(value, "name", "")}
          onChange={handleChange}
          required
        />
        <TextInputField
          label={translate("contact.form.email")}
          placeholder={translate("contact.form.email")}
          name="email"
          type="email"
          value={get(value, "email", "")}
          onChange={handleChange}
          required
        />
        <TextAreaField
          rows={isMobile ? "5" : "10"}
          className={"span"}
          label={translate("contact.form.message")}
          placeholder={`${translate("contact.form.message")} ...`}
          name="message"
          value={get(value, "message", "")}
          onChange={handleChange}
          required
        />
        <Button type="submit" disabled={sent}>
          {translate(
            sent ? "contact.form.submit_success" : "contact.form.submit"
          )}
        </Button>
      </Form>
    </Paper>
  );
};
