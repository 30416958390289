import React, { Fragment } from "react";
import Stats from "../components/Stats";
import Paper from "../components/containers/Paper";
import Poster from "../components/Poster";
import Featured from "../components/Featured";
import Slideshow from "../components/Slideshow";
import withProps from "../utils/withProps";
import DistributionItem from "../components/items/DistributionItem";
import ItemGrid from "../components/containers/ItemGrid";
import { Desktop } from "../utils/responsive";
import { LANGUAGE, LanguageContext } from "../components/LanguageContext";
import cfSince from "../assets/internal/56_logo.png";
import cfSinceEng from "../assets/internal/56_logo_eng.png";

export default ({
  distribution,
  featured,
  stats,
  slideshow,
  slideshowLinkProvider,
  news,
  poster,
  isMobile
}) => {
  return (
    <Fragment>
      <Paper title={featured.section} emblem={!isMobile} noMargin>
        <Featured {...featured} />
      </Paper>
      <Paper title={distribution.title} noMargin>
        <ItemGrid
          Component={withProps({ linkProvider: distribution.linkProvider })(
            DistributionItem
          )}
          items={distribution.items}
          maxRows={1}
          initialRows={1}
          itemWidth={isMobile ? 40 : 243}
        />
      </Paper>
      <LanguageContext.Consumer>
        {({ language }) => (
          <Poster
            {...poster}
            titleImage={language === LANGUAGE.SR ? cfSince : cfSinceEng}
            expand={true}
            clip={isMobile}
          />
        )}
      </LanguageContext.Consumer>
      <Stats items={stats} />
      <Desktop>
        <Slideshow
          items={slideshow}
          linkProvider={slideshowLinkProvider}
          itemWidth={360}
          itemPadding={32}
        />
      </Desktop>
      {/*<NewsItem {...news.item} linkProvider={news.linkProvider} blend={true} />*/}
    </Fragment>
  );
};
